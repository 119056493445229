import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowDown, faBarcode, faClock, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastList } from '@devesharp/react/web/index';
import Style from './LinkPaymentsDetailPage.style';
import { useLinkPaymentsDetailPage } from '~/pages/LinkPaymentsDetail/LinkPaymentsDetailPage.controller';
import { PaymentOperationType, PaymentStatus } from '~/components';
import { PaymentType } from '~/components/PaymentType';
import { formaHour, formatBarCode, formatDate, formatPrice } from '~/services';

export const LinkPaymentsDetailPage: React.FunctionComponent<any> = () => {
   const ctrl = useLinkPaymentsDetailPage();

   if (!ctrl.resource) {
      return null;
   }

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Detalhes do link de pagamento</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Container>
            <div className="text-right pb-2">
               <CopyToClipboard
                  text={'https://app.xpag.com.br' + ctrl.resource.url}
                  onCopy={() =>
                     ToastList.show({
                        message: 'Link copiado com sucesso',
                        type: 'black',
                     })
                  }
               >
                  <span className="btn-url">
                     Copiar link &nbsp;
                     <FontAwesomeIcon icon={faCopy} />
                  </span>
               </CopyToClipboard>
               &nbsp;
               <a href={ctrl.resource.url} className="btn-comprovante" target="_blank">
                  Abrir Link <FontAwesomeIcon icon={faBarcode} />
               </a>
            </div>

            <div className="row">
               <div className="col-3">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`}>
                        <div className="d-flex">
                           <div>Valor</div>
                           <div className="flex-fill text-right">
                              {ctrl.resource?.transaction &&
                              ctrl.resource.transaction.original_amount !== ctrl.resource.transaction.amount ? (
                                 <span>
                                    <small className="text-decoration-line-through">
                                       {formatPrice(ctrl.resource.transaction.original_amount * 100, 2)}
                                    </small>{' '}
                                    {formatPrice(ctrl.resource.transaction.amount * 100, 2)}
                                 </span>
                              ) : (
                                 <>
                                    <span>{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="p-4">
                        <h5>Informações da transação</h5>
                        <p />

                        <p>
                           Status da venda
                           <br />
                           <PaymentStatus transaction={ctrl.resource} />
                        </p>

                        <p>
                           Tipo da venda <br />
                           <b>Link de pagamento</b>
                        </p>

                        <p>
                           Expira em <br />
                           <b>{formatDate(ctrl.resource.expiration_date)}</b>
                        </p>

                        {ctrl.resource?.transaction?.payment_type == 'credit' && (
                           <>
                              <p>
                                 Parcelado em <br />
                                 <b>{ctrl.resource.transaction?.installment_plan?.number_installments || 1} vezes</b>
                              </p>
                           </>
                        )}
                        <p>
                           ID do link
                           <br />
                           <b>{ctrl.resource.id}</b>
                        </p>
                        <p>
                           Descrição
                           <br />
                           <b>{ctrl.resource.description}</b>
                        </p>
                        <p>
                           Descrição
                           <br />
                           <b>{ctrl.resource.description}</b>
                        </p>
                        {ctrl.resource.user_name && (
                           <p>
                              Criado por
                              <br />
                              <b>{ctrl.resource.user_name}</b>
                           </p>
                        )}
                     </div>
                  </Card>
                  <Card className="p-4">
                     <h5>Informações do Pagador</h5>
                     <p />

                     <>
                        <p>
                           Nome <br />
                           <b>
                              {!!ctrl.resource.customer?.id && (
                                 <Link className="link_ref" to={`/customers/edit/${ctrl.resource.customer.id}`}>
                                    {ctrl.resource.customer?.full_name}
                                 </Link>
                              )}
                           </b>
                        </p>

                        {!!ctrl.resource.customer?.group_id && (
                           <p>
                              Grupo <br />
                              <b>
                                 <Link className="link_ref" to={`/groups/edit/${ctrl.resource.customer.group_id}`}>
                                    {ctrl.resource.customer?.group_name}
                                 </Link>
                              </b>
                           </p>
                        )}
                        <p>
                           Email <br />
                           <b>{ctrl.resource.customer?.email}</b>
                        </p>
                        <p>
                           Endereço <br />
                           {ctrl.resource.customer?.address_address && (
                              <b>
                                 {ctrl.resource.customer?.address_address} {ctrl.resource.customer?.address_number}{' '}
                                 {ctrl.resource.customer?.address_complement} -{' '}
                                 {ctrl.resource.customer?.address_neighborhood} - {ctrl.resource.customer?.address_city}{' '}
                                 - {ctrl.resource.customer?.address_state} - CEP{' '}
                                 {ctrl.resource.customer?.address_postal_code}
                              </b>
                           )}
                        </p>
                     </>

                     {ctrl.resource.payment_type == 'credit' && (
                        <>
                           <p>
                              Portador do cartão
                              <br />
                              <b>{ctrl.resource.payment_method.holder_name}</b>
                           </p>
                           <p>
                              Número do cartão <br />
                              <b>
                                 {ctrl.resource.payment_method.first4_digits} **** ****{' '}
                                 {ctrl.resource.payment_method.last4_digits}
                              </b>
                           </p>
                           <p>
                              Validade <br />
                              <b>
                                 {ctrl.resource.payment_method.expiration_month}/
                                 {ctrl.resource.payment_method.expiration_year}
                              </b>
                           </p>
                        </>
                     )}
                  </Card>
               </div>

               <div className="col">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`} />

                     <div className="p-4">
                        <h5>HISTÓRICO DA VENDA</h5>
                        <p />

                        <Table striped bordered hover className="listing">
                           <thead>
                              <tr>
                                 <th>Data</th>
                                 <th>Status</th>
                                 <th className="text-right">Valor</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr className="border-top">
                                 <td>
                                    <td>
                                       <b>{formatDate(ctrl.resource.created_at)}</b>
                                       <br />
                                       <small className="hour">
                                          <FontAwesomeIcon icon={faClock} /> {formaHour(ctrl.resource.created_at)}
                                       </small>
                                    </td>
                                 </td>
                                 <td>
                                    <PaymentOperationType status="created" />
                                 </td>
                                 <td className="text-right">{formatPrice(ctrl.resource.amount * 100, 2)} </td>
                              </tr>
                              {ctrl.resource?.transaction?.history.map((history: any) => (
                                 <tr>
                                    <td>
                                       <b>{formatDate(history.created_at)}</b>
                                       <br />
                                       <small className="hour">
                                          <FontAwesomeIcon icon={faClock} /> {formaHour(history.created_at)}
                                       </small>
                                    </td>
                                    <td>
                                       <PaymentOperationType history={history} status={history.operation_type} />
                                    </td>
                                    <td className="text-right">
                                       <span>{formatPrice(history.amount * 100, 0)}</span>
                                    </td>
                                 </tr>
                              ))}

                              <tr className="border-top">
                                 <td>
                                    <b>Status atual</b>
                                 </td>
                                 <td>
                                    <PaymentStatus transaction={ctrl.resource} />
                                 </td>
                                 <td className="text-right">
                                    {ctrl.resource?.transaction &&
                                    ctrl.resource.transaction.original_amount !== ctrl.resource.transaction.amount ? (
                                       <span>
                                          <span className="text-decoration-line-through">
                                             {formatPrice(ctrl.resource.transaction.original_amount * 100, 2)}{' '}
                                          </span>
                                          <span className="price">
                                             {formatPrice(ctrl.resource.transaction.amount * 100, 2)}
                                          </span>
                                       </span>
                                    ) : (
                                       <>
                                          <span className="price">{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                       </>
                                    )}
                                 </td>
                              </tr>
                           </tbody>
                        </Table>
                     </div>
                  </Card>
               </div>
            </div>
         </Container>
      </Style>
   );
};
