import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   EstablishmentCreateResolve,
   EstablishmentGetResolve,
   EstablishmentSearchResolve,
   EstablishmentUpdateResolve,
   GroupsSearchResolve,
   MMCSearchResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { accountType, bankCodes, states } from '~/services/data';
import { CPFValidation, OnlyNumbers, searchCEP } from '~/services';
import history from '~/config/history';
import { sellerSelectState } from '~/recoil/seller-select.state';

const yupValidateSchema = Yup.object().shape({
   type: Yup.string().nullable().required('Este campo é obrigatório'),
   first_name: Yup.string().required('Este campo é obrigatório'),
   last_name: Yup.string().required('Este campo é obrigatório'),
   email: Yup.string().required('Este campo é obrigatório'),
   phone_number: Yup.string().required('Este campo é obrigatório'),
   taxpayer_id: Yup.string().required('Este campo é obrigatório'),
   birthdate: Yup.string().required('Este campo é obrigatório'),
   statement_descriptor: Yup.string().required('Este campo é obrigatório'),
   mcc: Yup.string().required('Este campo é obrigatório'),
   address_line1: Yup.string().required('Este campo é obrigatório'),
   address_line2: Yup.string().required('Este campo é obrigatório'),
   address_neighborhood: Yup.string().required('Este campo é obrigatório'),
   address_city: Yup.string().required('Este campo é obrigatório'),
   address_state: Yup.string().required('Este campo é obrigatório'),
   address_postal_code: Yup.string().required('Este campo é obrigatório'),
   bank_type: Yup.string().nullable().required('Este campo é obrigatório'),
   bank_code: Yup.number().nullable().required('Este campo é obrigatório'),
   bank_routing_number: Yup.number().typeError('Este campo é obrigatório').required('Este campo é obrigatório'),
   bank_account_number: Yup.number().typeError('Este campo é obrigatório').required('Este campo é obrigatório'),
   user_login: Yup.string().required('Este campo é obrigatório'),
   user_password: Yup.string().required('Este campo é obrigatório'),
});

const yupValidateBusnissSchema = Yup.object().shape({
   type: Yup.string().nullable().required('Este campo é obrigatório'),
   owner_first_name: Yup.string().required('Este campo é obrigatório'),
   owner_last_name: Yup.string().required('Este campo é obrigatório'),
   owner_email: Yup.string().required('Este campo é obrigatório'),
   owner_phone_number: Yup.string().required('Este campo é obrigatório'),
   owner_taxpayer_id: Yup.string().required('Este campo é obrigatório'),
   owner_birthdate: Yup.string().required('Este campo é obrigatório'),
   business_name: Yup.string().required('Este campo é obrigatório'),
   business_phone: Yup.string().required('Este campo é obrigatório'),
   business_email: Yup.string().required('Este campo é obrigatório'),
   ein: Yup.string().required('Este campo é obrigatório'),
   statement_descriptor: Yup.string().required('Este campo é obrigatório'),
   business_address_line1: Yup.string().required('Este campo é obrigatório'),
   business_address_line2: Yup.string().required('Este campo é obrigatório'),
   business_address_neighborhood: Yup.string().required('Este campo é obrigatório'),
   business_address_city: Yup.string().required('Este campo é obrigatório'),
   business_address_state: Yup.string().nullable().required('Este campo é obrigatório'),
   business_address_postal_code: Yup.string().required('Este campo é obrigatório'),
   owner_address_line1: Yup.string().required('Este campo é obrigatório'),
   owner_address_line2: Yup.string().required('Este campo é obrigatório'),
   owner_address_neighborhood: Yup.string().required('Este campo é obrigatório'),
   owner_address_city: Yup.string().required('Este campo é obrigatório'),
   owner_address_state: Yup.string().nullable().required('Este campo é obrigatório'),
   owner_address_postal_code: Yup.string().required('Este campo é obrigatório'),

   bank_type: Yup.string().nullable().required('Este campo é obrigatório'),
   bank_code: Yup.number().nullable().required('Este campo é obrigatório'),
   bank_routing_number: Yup.number().typeError('Este campo é obrigatório').required('Este campo é obrigatório'),
   bank_account_number: Yup.number().typeError('Este campo é obrigatório').required('Este campo é obrigatório'),
   user_login: Yup.string().required('Este campo é obrigatório'),
   user_password: Yup.string().required('Este campo é obrigatório'),
});

export function useEstablishmentCreatePage(): any {
   const { id } = useParams<any>();

   document.title = 'Novo estabelecimento - XPAG Tecnologia';
   const [isLoadingCep, setIsLoadingCep] = useState(false);
   const ref = useRef('');
   const initialData = {
      type: 'business',
      // owner_first_name: 'Osbaldo',
      // owner_last_name: 'Durgan',
      // owner_email: 'immanuel00@gmail.com',
      // owner_phone_number: '+1.820.829.5807',
      // owner_taxpayer_id: '32493573',
      // owner_birthdate: '1981-05-17',
      // description: 'Rerum adipisci facere laudantium molestias amet autem corporis.',
      // business_name: 'Satterfield-Quitzon',
      // business_phone: '+1-951-759-8063',
      // business_email: 'xadams@nolan.info',
      // business_website: 'http:\\/\\/www.stanton.com\\/consequatur-doloremque-fugiat-quis-iste-vero.html',
      // business_description:
      //    'Dicta consequatur consequatur ullam quaerat quidem doloribus consectetur. Quidem perferendis sed ut ut ex occaecati.',
      // business_facebook: 'http:\\/\\/www.wiza.net\\/',
      // business_twitter: 'http:\\/\\/schumm.com\\/',
      // ein: '17722384',
      // statement_descriptor: 'At et ad sint reiciendis.',
      // revenue: '311903',
      // business_address_line1: '8911 Dell Way',
      // business_address_line2: '123',
      // business_address_line3: '',
      // business_address_neighborhood: 'burgh',
      // business_address_city: 'West Johnathanview',
      // business_address_state: 'IL',
      // business_address_postal_code: '40757-2550',
      // business_address_country_code: 'NA',
      // business_opening_date: '2022-11-15',
      // owner_address_line1: '6679 Ruthe Freeway',
      // owner_address_line2: '234',
      // owner_address_line3: '',
      // owner_address_neighborhood: 'shire',
      // owner_address_city: 'West Germainefurt',
      // owner_address_state: 'OR',
      // owner_address_postal_code: '87430-4034',
      // owner_address_country_code: 'IT',
      // type: 'individual',
      // first_name: 'Lina',
      // last_name: 'Abbott',
      // email: 'dglover@johns.net',
      // phone_number: '(80) 23419-3743',
      // taxpayer_id: '41056294841',
      // birthdate: '2015-06-06',
      // statement_descriptor: 'Asperiores aperiam quos cumque cumque voluptas.',
      // revenue: 882719,
      // address_line1: 'Bruen Course',
      // address_line2: '1573',
      // address_line3: '',
      // address_neighborhood: 'mouth',
      // address_city: 'West Easter',
      // address_state: 'SP',
      // address_postal_code: '99122-6977',
      // address_country_code: 'AL',
      //
      //

      // mcc: 5499,
      // user_login: 'user_login',
      // user_password: 'user_password',
      bank_type: 'checking',
      // bank_code: '001',
      // bank_routing_number: '123123',
      // bank_account_number: '123123',
   };

   const [personType, setPersonType] = useState('business');

   const {
      starting,
      saving,
      formRef,
      submit,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      yupValidateSchema: personType == 'individual' ? yupValidateSchema : yupValidateBusnissSchema,
      resolves: {
         groups: GroupsSearchResolve,
         MCC: MMCSearchResolve,
      },
      resolveGet: EstablishmentGetResolve,
      resolveCreate: EstablishmentCreateResolve,
      resolveUpdate: (params) => EstablishmentUpdateResolve(id, params),
      onFailed(e, m) {
         ToastList.show({
            message: m.data.error ?? 'Erro ao adicionar estabelecimento',
            type: 'error',
         });
      },
      onSuccess(creating) {
         if (creating) {
            ToastList.show({
               message: 'Estabelecimento adicionado com sucesso',
               type: 'success',
            });
            history.push('/establishment');
         } else {
            ToastList.show({
               message: 'Estabelecimento editado com sucesso',
               type: 'success',
            });
            history.push('/establishment');
         }
      },
   });

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);

   const [resource, setResource] = useState<any>();
   const [groups, setGroups] = useState<any[]>([]);
   const [MCCs, setMCCs] = useState<any[]>([]);

   function changePersonType() {
      console.log(formRef.current.getFieldValue('type'));
      setPersonType(formRef.current.getFieldValue('type'));
      // setTimeout(() => {
      //    formRef.current.setData(settings);
      // }, 10);
   }

   // console.log(groups)
   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }

      if (resources.MCC) {
         setMCCs(resources.MCC);
      }

      if (resources.resource) {
         const resource2 = { ...resources.resource };
         // if (resource2.group_id) {
         //    resource2.group_id = '1';
         // }

         setResource(resource2);
         setPersonType(resource2.person_type);
         formRef.current.setData(resource2);
      }
   });

   useEffect(() => {
      // console.log(resource);
      // console.log(resource?.group_id?.label);
      // console.log('sdsd');
      // formRef.current.setFieldValue('group_id', '1');
   }, [groups, resource]);

   const formInfoData = {
      person_type: [
         {
            value: 'individual',
            name: 'Pessoa física',
         },
         {
            value: 'business',
            name: 'Pessoa júrica',
         },
      ],
      MCC: [
         {
            id: '',
            name: 'Selecione um tipo de negócio',
         },
         ...MCCs.map((i) => ({ value: i.id, name: i.name })).sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
         ),
      ],
      bankCodes: bankCodes.map((i) => ({ value: i.id, name: `${i.id} - ${i.name}` })),
      accountType,
      states: states.map((state) => ({ name: state.name, value: state.id })),
   };

   function onChangeCep(): any {
      const cep = OnlyNumbers(formRef.current.getFieldValue('address_postal_code'));
      if (cep.length == 8 && ref.current !== cep) {
         setIsLoadingCep(true);
         formRef.current.setFieldError('address_postal_code', '');
         searchCEP(cep)
            .then((e: any) => {
               setIsLoadingCep(false);
               const address = (formRef.current.getData() as any).address ?? {};
               formRef.current.setData({
                  ...formRef.current.getData(),
                  address_address: e.data.street,
                  address_city: e.data.city,
                  address_neighborhood: e.data.neighborhood,
                  address_state: e.data.state,
               });
            })
            .catch(() => {
               setIsLoadingCep(false);
               formRef.current.setFieldError('address_postal_code', 'CEP não encontrado');
            });
      }
      ref.current = cep;
   }

   // function searchCep() {
   //    searchCEP.then((e) => {
   //       console.log('e');
   //    });
   // }
   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function openCreateGroup() {
      history.push('/groups/create');
   }

   function onChangePersonType() {
      setPersonType(formRef.current.getFieldValue('person_type'));
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      openCreateGroup,
      onChangePersonType,
      id,
      editing: !!id,
      personType,
      changePersonType,
      formRef,
      reloadPage,
      starting,
      saving,
      isLoadingCep,
      onChangeCep,
      errorLoadData,
      formInfoData,
      sellerSelect,
      initialData,
      submit,
   };
}
