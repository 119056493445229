import React, { useEffect, useRef, useState } from 'react';
import { Tabs, useView, useDidUpdateEffect, useDidUpdateDeepCompareLayoutEffect } from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { PaymentLinksGetResolve } from '~/api';

export function useLinkPaymentsDetailPage(): any {
   const { id } = useParams() as any;

   document.title = 'Link de pagamentos - XPAG Tecnologia';

   const { starting, errorLoadData, reloadPage, registerResolveParams, registerOnInit } = useView({
      resolves: {
         resource: PaymentLinksGetResolve,
      },
   });

   const [resource, setResource] = useState();
   const formRef = useRef<FormHandles>();

   registerResolveParams('resource', () => id);

   registerOnInit((res: any) => {
      setResource(res.resource);
   });

   console.log(resource);

   // useDidUpdateEffect(() => {
   //    // setPage(currentPage);
   // }, [currentPage]);

   return {
      starting,
      errorLoadData,
      resource,
   };
}
