import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faAngleDown,
   faArrowDown,
   faArrowLeft,
   faBackspace,
   faBackward,
   faBan,
   faBarcode,
   faClock,
   faDownload,
   faRetweet,
   faSync,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@devesharp/react/web';
import QueryString from 'querystring';
import { Link } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import Style from './PaymentsDetailPage.style';
import { usePaymentsDetailPage } from '~/pages/PaymentsDetail/PaymentsDetailPage.controller';
import { PaymentOperationType, PaymentStatus } from '~/components';
import { PaymentType } from '~/components/PaymentType';
import { CNPJAndCPFMask, formaHour, formatBarCode, formatDate, formatPrice } from '~/services';
import { useUser } from '~/hooks';
import { StatusStyle } from '~/pages/SubscriptionsListing/SubscriptionsListingPage.style';

export const PaymentsDetailPage: React.FunctionComponent<any> = () => {
   const ctrl = usePaymentsDetailPage();
   const user = useUser();

   if (!ctrl.resource) {
      return null;
   }

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-2">
                  <a href="#" className="text-primary" onClick={ctrl.goBack}>
                     <FontAwesomeIcon icon={faArrowLeft} />
                     &nbsp; Voltar&nbsp;&nbsp;
                  </a>
               </div>

               <h3 className="m-0 mb-1">Detalhes da venda</h3>
            </div>
         </div>

         <Container>
            <div className="text-right pb-2">
               {ctrl.resource.payment_type == 'boleto' && (
                  <button className="btn btn-sync" onClick={ctrl.onSync}>
                     Sincronizar <FontAwesomeIcon icon={faSync} />
                  </button>
               )}

               {ctrl.resource.payment_type == 'boleto' && (
                  <a href={ctrl.resource.url} className="btn btn-comprovante" target="_blank" rel="noreferrer">
                     Abrir Boleto <FontAwesomeIcon icon={faBarcode} />
                  </a>
               )}

               {ctrl.resource.payment_type == 'credit' && ctrl.resource.status == 'succeeded' && (
                  <Link to={`/payments/cancel/${ctrl.resource.id}`} className="btn btn-danger rounded-pill mr-2">
                     <FontAwesomeIcon icon={faBan} /> Estornar Venda
                  </Link>
               )}

               {ctrl.resource.payment_type == 'credit' &&
                  (ctrl.resource.payment_method.status == 'paid' ||
                     ctrl.resource.status == 'succeeded' ||
                     ctrl.resource.payment_method.status == 'over_paid' ||
                     ctrl.resource.payment_method.status == 'under_paid') && (
                     <a
                        href={`https://api.dev.xpag.com.br/comprovante/${ctrl.resource.id}`}
                        className="btn btn-comprovante"
                        target="_blank"
                        rel="noreferrer"
                     >
                        Comprovante <FontAwesomeIcon icon={faArrowDown} />
                     </a>
                  )}
            </div>

            <div className="row">
               <div className="col-3">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`}>
                        <div className="d-flex">
                           <div>Valor</div>
                           <div className="flex-fill text-right">
                              {ctrl.resource.original_amount === ctrl.resource.amount ? (
                                 <span>{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                              ) : (
                                 <>
                                    <span>
                                       <small className="text-decoration-line-through">
                                          {formatPrice(ctrl.resource.original_amount * 100, 2)}
                                       </small>{' '}
                                       {formatPrice(ctrl.resource.amount * 100, 2)}
                                    </span>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="p-4">
                        <h5>Informações da transação</h5>
                        <p />

                        <p>
                           Status da venda
                           <br />
                           <PaymentStatus transaction={ctrl.resource} />
                        </p>

                        {ctrl.resource.cancel_reason && (
                           <p>
                              Motivo do cancelamento
                              <br />
                              <span className="text-danger">
                                 <FontAwesomeIcon icon={faExclamationTriangle} />
                              </span>{' '}
                              {ctrl.resource.cancel_reason}
                           </p>
                        )}

                        <p>
                           Tipo da venda <br />
                           <b>
                              <PaymentType transaction={ctrl.resource} />
                           </b>
                        </p>
                        {ctrl.resource.payment_type == 'boleto' && (
                           <>
                              <p>
                                 Vencimento <br />
                                 <b>{formatDate(ctrl.resource.expiration_date)}</b>
                              </p>

                              <p>
                                 Código de Barras <br />
                                 <b>{formatBarCode(ctrl.resource.payment_method.barcode)}</b>
                              </p>
                           </>
                        )}
                        {ctrl.resource.original_amount != ctrl.resource.amount && (
                           <>
                              <p>
                                 Diferença do pagamento <br />
                                 <b
                                    className={
                                       ctrl.resource.amount - ctrl.resource.original_amount <= 0
                                          ? 'text-danger'
                                          : 'text-success'
                                    }
                                 >
                                    {formatPrice((ctrl.resource.amount - ctrl.resource.original_amount) * 100, 2)}
                                 </b>
                              </p>
                           </>
                        )}
                        {ctrl.resource.payment_type == 'credit' &&
                           ctrl.resource.installment_plan?.number_installments > 1 && (
                              <>
                                 <p>
                                    Parcelado em <br />
                                    <b>{ctrl.resource.installment_plan?.number_installments || 1} vezes</b>
                                 </p>
                              </>
                           )}
                        <p>
                           ID da transação
                           <br />
                           <b>{ctrl.resource.id}</b>
                        </p>
                        {!!ctrl.resource.description && (
                           <p>
                              Descrição
                              <br />
                              <b>{ctrl.resource.description}</b>
                           </p>
                        )}
                        {ctrl.resource.user_name && (
                           <p>
                              Criado por
                              <br />
                              <b>{ctrl.resource.user_name}</b>
                           </p>
                        )}

                        {!!ctrl.resource.seller_name && (
                           <p>
                              Estabelecimento
                              <br />
                              <b>{ctrl.resource.seller_name}</b>
                           </p>
                        )}

                        {ctrl.resource?.plan_id && (
                           <>
                              <p>
                                 Plano
                                 <br />
                                 <b>
                                    {ctrl.resource?.plan_name} -{' '}
                                    <StatusAssinatura status={ctrl.resource?.subscription_status} />
                                 </b>
                                 {ctrl.resource?.subscription_status != 'canceled' && (
                                    <div>
                                       <Button
                                          variant="danger"
                                          onClick={() => ctrl.cancelSubscription(ctrl.resource?.subscription_id)}
                                       >
                                          Cancelar Assinatura
                                       </Button>
                                    </div>
                                 )}
                              </p>
                           </>
                        )}
                     </div>
                  </Card>
                  <Card className="p-4">
                     <h5>Informações do Pagador</h5>
                     <p />

                     {ctrl.resource.payment_type == 'commission' && (
                        <>
                           <p>
                              Nome <br />
                              <b>{ctrl.resource.seller_name}</b>
                           </p>
                           <p>
                              CNPJ/CPF <br />
                              <b>{CNPJAndCPFMask(user.document)}</b>
                           </p>
                        </>
                     )}

                     {ctrl.resource.payment_type == 'boleto' && (
                        <>
                           <p>
                              Nome <br />
                              <b>
                                 {!!ctrl.resource.customer?.id && (
                                    <Link className="link_ref" to={`/customers/edit/${ctrl.resource.customer.id}`}>
                                       {ctrl.resource.customer?.full_name}
                                    </Link>
                                 )}
                              </b>
                           </p>

                           {!!ctrl.resource.customer?.group_id && (
                              <p>
                                 Grupo <br />
                                 <b>
                                    <Link className="link_ref" to={`/groups/edit/${ctrl.resource.customer.group_id}`}>
                                       {ctrl.resource.customer?.group_name}
                                    </Link>
                                 </b>
                              </p>
                           )}
                           <p>
                              Email <br />
                              <b>{ctrl.resource.customer?.email}</b>
                           </p>
                           <p>
                              Endereço <br />
                              {ctrl.resource.customer?.address_address && (
                                 <b>
                                    {ctrl.resource.customer?.address_address} {ctrl.resource.customer?.address_number}{' '}
                                    {ctrl.resource.customer?.address_complement} -{' '}
                                    {ctrl.resource.customer?.address_neighborhood} -{' '}
                                    {ctrl.resource.customer?.address_city} - {ctrl.resource.customer?.address_state} -
                                    CEP {ctrl.resource.customer?.address_postal_code}
                                 </b>
                              )}
                           </p>
                        </>
                     )}
                     {ctrl.resource.payment_type == 'credit' && (
                        <>
                           <p>
                              Portador do cartão
                              <br />
                              <b>{ctrl.resource.payment_method?.holder_name}</b>
                           </p>
                           <p>
                              Número do cartão <br />
                              <b>
                                 {ctrl.resource.payment_method?.first4_digits} **** ****{' '}
                                 {ctrl.resource.payment_method?.last4_digits}
                              </b>
                           </p>
                           <p>
                              Validade <br />
                              <b>
                                 {ctrl.resource.payment_method?.expiration_month}/
                                 {ctrl.resource.payment_method?.expiration_year}
                              </b>
                           </p>
                        </>
                     )}
                  </Card>
               </div>

               <div className="col">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`} />

                     <div className="p-4">
                        <h5>HISTÓRICO DA VENDA</h5>
                        <p />

                        <Table striped bordered hover className="listing">
                           <thead>
                              <tr>
                                 <th>Data</th>
                                 <th>Status</th>
                                 <th className="text-right">Valor</th>
                              </tr>
                           </thead>
                           <tbody>
                              {ctrl.resource.history.map((history: any) => (
                                 <tr>
                                    <td>
                                       <b>{formatDate(history.created_at)}</b>
                                       <br />
                                       <small className="hour">
                                          <FontAwesomeIcon icon={faClock} /> {formaHour(history.created_at, true)}
                                       </small>
                                    </td>
                                    <td>
                                       <PaymentOperationType history={history} status={history.operation_type} />
                                    </td>
                                    <td className="text-right">
                                       <span>{formatPrice(history.amount, 0)}</span>
                                    </td>
                                 </tr>
                              ))}

                              {ctrl.resource.payment_type == 'commission' && (
                                 <tr>
                                    <td>
                                       <b>{formatDate(ctrl.resource.created_at)}</b>
                                       <br />
                                       <small className="hour">
                                          <FontAwesomeIcon icon={faClock} /> {formaHour(ctrl.resource.created_at)}
                                       </small>
                                    </td>
                                    <td>
                                       <PaymentOperationType status={ctrl.resource.status} />
                                    </td>
                                    <td className="text-right">
                                       <span>{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                    </td>
                                 </tr>
                              )}

                              <tr className="border-top">
                                 <td>
                                    <b>
                                       {ctrl.resource.payment_type == 'commission' && 'Total'}
                                       {ctrl.resource.payment_type != 'commission' && 'Status atual'}
                                    </b>
                                 </td>
                                 <td>
                                    <PaymentStatus transaction={ctrl.resource} />
                                 </td>
                                 <td className="text-right">
                                    {ctrl.resource.original_amount === ctrl.resource.amount ? (
                                       <span className="price">{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                    ) : (
                                       <>
                                          <span>
                                             <span className="text-decoration-line-through">
                                                {formatPrice(ctrl.resource.original_amount * 100, 2)}{' '}
                                             </span>
                                             <span className="price">{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                          </span>
                                       </>
                                    )}
                                 </td>
                              </tr>
                           </tbody>
                        </Table>
                     </div>
                  </Card>

                  {ctrl.resource.receivables.length > 0 && (
                     <Card>
                        <div className="title-price title-black" />

                        <div className="p-4">
                           <h5>Faturamento futuro</h5>
                           <p />

                           <Table striped bordered hover className="listing">
                              <thead>
                                 <tr>
                                    <th>Data/Previsão</th>
                                    <th>Status</th>
                                    <th className="text-right">Valor sem taxas</th>
                                    <th className="text-right">Taxa</th>
                                    <th className="text-right">Valor Final</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {ctrl.resource.receivables?.map((receivable: any) => (
                                    <tr>
                                       <td>
                                          <b>{formatDate(receivable.expected_on)}</b>
                                       </td>
                                       <td>
                                          <PaymentStatus status={receivable.status} />
                                       </td>
                                       <td className="text-right">
                                          <span>{formatPrice(receivable.gross_amount, 0)}</span>
                                       </td>

                                       <td className="text-right">
                                          <span>{formatPrice(receivable.gross_amount - receivable.amount, 0)}</span>
                                       </td>

                                       <td className="text-right">
                                          <span>{formatPrice(receivable.amount, 0)}</span>
                                       </td>
                                    </tr>
                                 ))}

                                 <tr className="border-top">
                                    <td>
                                       <b>
                                          {ctrl.resource.payment_type == 'commission' && 'Total'}
                                          {ctrl.resource.payment_type != 'commission' && 'Status atual'}
                                       </b>
                                    </td>
                                    <td>
                                       <PaymentStatus transaction={ctrl.resource} />
                                    </td>
                                    <td className="text-right">
                                       {ctrl.resource.original_amount === ctrl.resource.amount ? (
                                          <span className="">{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                       ) : (
                                          <>
                                             <span>
                                                <span className="text-decoration-line-through">
                                                   {formatPrice(ctrl.resource.original_amount * 100, 2)}{' '}
                                                </span>
                                                <span className="">{formatPrice(ctrl.resource.amount * 100, 2)}</span>
                                             </span>
                                          </>
                                       )}
                                    </td>
                                    <td className="text-right">
                                       <span className="">{formatPrice(ctrl.resource.fees * 100, 2)}</span>
                                    </td>
                                    <td className="text-right">
                                       {ctrl.resource.original_amount === ctrl.resource.amount ? (
                                          <span className="price">
                                             {formatPrice(ctrl.resource.amount * 100 - ctrl.resource.fees * 100, 2)}
                                          </span>
                                       ) : (
                                          <>
                                             <span>
                                                <span className="text-decoration-line-through">
                                                   {formatPrice(ctrl.resource.original_amount * 100, 2)}{' '}
                                                </span>
                                                <span className="price">
                                                   {formatPrice(
                                                      ctrl.resource.amount * 100 - ctrl.resource.fees * 100,
                                                      2,
                                                   )}
                                                </span>
                                             </span>
                                          </>
                                       )}
                                    </td>
                                 </tr>
                              </tbody>
                           </Table>
                        </div>
                     </Card>
                  )}

                  <br />
               </div>
            </div>
         </Container>
      </Style>
   );
};

function StatusAssinatura({ status }: any) {
   let statusName = '';
   switch (status) {
      case 'expired':
         statusName = 'Expirado';
         break;
      case 'active':
         statusName = 'Ativo';
         break;
      case 'inactive':
         statusName = 'Inativo';
         break;
      case 'canceled':
         statusName = 'Cancelado';
         break;
   }

   return (
      <StatusStyle>
         <span className={`badge ${status}`}>{statusName}</span>
      </StatusStyle>
   );
}
