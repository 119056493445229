import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Style, { ProgressColor } from './MyAccountPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { useGroupsCreatePage } from '~/pages/GroupsCreate/GroupsCreatePage.controller';
import { useMyAccountPage } from '~/pages/MyAccount/MyAccountPage.controller';

export const MyAccountPage: React.FunctionComponent<any> = () => {
   const ctrl = useMyAccountPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Minha conta</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Dados do contato</div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="login" title="Login" />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="name" title="Nome fantasia" />
                        </div>

                        <div className="col-md-6">
                           <Input name="document" title="CPF" CPF />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="email" title="Email" />
                        </div>

                        <div className="col-md-6">
                           <Input name="phone" title="Celular" phone />
                        </div>
                     </div>
                  </div>
                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">Endereço</div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="address.postal_code" title="CEP" CEP onInput={ctrl.onChangeCep} />
                        </div>

                        <div className="col-md-6" />
                     </div>

                     <div className="row field-box">
                        <div className="col-md-9">
                           <Input name="address.street" title="Endereço" />
                        </div>

                        <div className="col-md-3">
                           <Input name="address.number" title="Número" number />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="address.complement" title="Complemento" />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="address.neighborhood" title="Bairro" />
                        </div>

                        <div className="col-md-5">
                           <Input name="address.city" title="Cidade" />
                        </div>

                        <div className="col-md-3">
                           <Select name="address.state" title="Estado" items={ctrl.formInfoData.states} />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Atualizar dados</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
