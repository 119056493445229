import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faCalendarCheck,
   faBarcode,
   faClock,
   faPaperPlane,
   faLongArrowAltUp,
   faLongArrowAltDown,
   faArrowUp,
   faArrowDown,
   faAngleDown,
   faSort,
   faSortDown,
   faSortUp,
   faLink,
   faEdit,
   faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   FlexContainer,
   FlexItem,
   CloseBtn,
   Modal,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
   Checkbox,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import { PhoneMask } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import Style, { ProgressColor } from './UsersListingPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useUsersListingPage } from '~/pages/UsersListing/UsersListingPage.controller';

export const UsersListingPage: React.FunctionComponent<any> = () => {
   const ctrl = useUsersListingPage();

   console.log(ctrl.selected);

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-3">
                  <div className="row align-items-center">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de clientes</h3>
                     </div>
                     <div className="col text-right">
                        <Button type="submit" size="lg" onClick={() => history.push('/users/create')}>
                           Novo Usuário
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {ctrl.loading && <LoadingPage />};
         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     <div className="flex-grow-1 px-2">
                        <Input name="name" title="Nome" />
                     </div>
                     {/* <div className="flex-grow-1 px-2"> */}
                     {/*   <Datepicker name="created_at_gte" title="Criado a partir" /> */}
                     {/* </div> */}
                     {/* <div className="flex-grow-1 px-2"> */}
                     {/*   <Datepicker name="created_at_lte" title="Criado até" /> */}
                     {/* </div> */}
                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="text-dark">
                           Mostrando <b>{ctrl.pageCountStart}</b>-<b>{ctrl.pageCountEnd}</b> de{' '}
                           <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div />
               </div>

               <div className="text-right pb-4">
                  <Modal
                     show={ctrl.groupModalShow}
                     onHide={() => {
                        console.log('sdsd');
                     }}
                  >
                     <Modal.Body className="p-4 text-left w-100">
                        <div className="close">
                           <CloseBtn onClick={() => ctrl.setGroupModalShow(false)} />
                        </div>
                        <h4>Mudar Grupo</h4>
                        <div className="pt-4 pb-4">
                           <Form ref={ctrl.formRefGroup} onSubmit={() => {}}>
                              <Select name="group_id" title="Grupos" items={ctrl.filtersData.groupsChange} />
                           </Form>
                        </div>

                        <div className="row stext-center">
                           <div className="col">
                              <Button
                                 type="submit"
                                 size="lg"
                                 onClick={() => ctrl.setGroupModalShow(false)}
                                 variant="light"
                              >
                                 Cancelar
                              </Button>
                           </div>
                           <div className="col">
                              <Button
                                 type="submit"
                                 size="lg"
                                 onClick={() => ctrl.changeCustomerGroup()}
                                 variant="secondary"
                              >
                                 Mudar
                              </Button>
                           </div>
                        </div>
                     </Modal.Body>
                  </Modal>
                  {!!ctrl.selected.size && (
                     <Button type="submit" onClick={() => ctrl.setGroupModalShow(true)} variant="secondary">
                        Mudar grupo de clientes
                     </Button>
                  )}
                  &nbsp;
                  {/* <Button rounded outline variant="success" onClick={ctrl.generateExcel}>
                     <FontAwesomeIcon icon={faFileExcel} /> <b>Gerar Excel</b>
                  </Button> */}
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th style={{ width: '0%' }} />
                           {ctrl.userRole == 'MASTER' && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Usuário'}
                              </th>
                           )}
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Status"
                                    sort="blocked"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>

                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Nome"
                                    sort="name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>

                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Cargo"
                                    sort="role"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Login"
                                    sort="login"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="CPF/CPNJ"
                                    sort="document"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Estabelecimentos PF/PJ"
                                    sort="sellers_name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((customer: any) => (
                           <ItemListing
                              key={customer.id}
                              customer={customer}
                              selected={ctrl.checkSelected(customer.id)}
                              setSelected={ctrl.onSelect}
                              sendSMS={ctrl.sendOne}
                              // showEstabelecimento={ctrl.userRole == 'MASTER'}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   customer,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   showEstabelecimento,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr>
            <td />
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openUsers(): void {
      history.push(`/users/edit/${customer.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td>
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     value={selected}
                     onChange={(ev) => {
                        setSelected(customer.id);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td>
               <b className={`status ${customer.blocked && 'blocked'}`}>{customer.blocked ? 'Bloqueado' : 'Ativo'}</b>
            </td>
            <td onClick={openUsers}>{customer.name}</td>
            <td onClick={openUsers}>{customer.role}</td>
            <td>{customer.login}</td>
            <td className="text-center text-nowrap" onClick={openUsers}>
               <b>{CNPJAndCPFMask(customer.CPF)}</b>
            </td>
            <td onClick={openUsers}>
               <b>{customer.sellers?.map((p: any) => p.name)?.join(', ')}</b>
            </td>
            <td className="text-right text-nowrap">
               <Link to={`/users/edit/${customer.id}`} className="btn-icon">
                  <FontAwesomeIcon icon={faEdit} />
               </Link>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);

const ExpiredOn = React.memo(function ExpiredOn({ transaction }: any): any {
   if (!transaction.expired_on) return <b>Não expira</b>;

   const diff = DateFNS.differenceInDays(DateFNS.format(convertStringToDate(transaction.expired_on)), new Date());

   if (diff <= 0) {
      return <b className="text-danger">{formatDate(transaction.expired_on)}</b>;
   }

   return <b className="text-success">{formatDate(transaction.expired_on)}</b>;
}, _.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);
