import { createGlobalStyle } from 'styled-components';

const css = createGlobalStyle;

export default css<any>`
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
   }

   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: 0;
   }

   .card {
      background-color: rgb(255, 255, 255);
      border: none !important;
      box-shadow: rgb(235, 235, 235) 0px 3px 7px !important;
   }

   html,
   body,
   #root {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      height: 100%;
      color: #222;
      background: #f5f7fb;
      font-weight: 500;
   }

   body {
      -webkit-font-smoothing: antialiased;
      overflow-y: scroll;
   }

   b {
      font-weight: 600;
   }

   .title-page {
      //background: #29235c;
      //background: ${(props) => props.theme.secondary};
      padding: 0px 0px;
      margin-bottom: 0px;

      h3 {
         font-size: 20px;
         font-weight: bold;
         color: ${(props) => props.theme.secondary} !important;
      }
   }

   @media (min-width: 1280px) {
      .container {
         max-width: 1380px;
      }
   }

   .card {
      position: relative;
      margin: 0.5rem 0 1rem 0;
      background-color: #fff;

      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
   }

   .listing {
      border: none;
      color: #666;

      thead {
         border: none;
      }

      th,
      td {
         border: none !important;
         vertical-align: middle;
      }

      th {
         background: transparent;
         border: none;
         color: #222;
         font-size: 12px;
         white-space: nowrap;
         padding-bottom: 15px;
         font-weight: 600;
         border-bottom: 1px solid #eee !important;
      }

      td {
         padding: 20px 10px;
      }

      tbody tr {
         background: #f1f1f1;
      }

      tbody tr:nth-of-type(odd) {
         background: white;
      }

      tr:hover {
         background: #dfdfdf !important;
      }
   }

   .checkbox-btl {
      position: relative;
      display: inline-block;

      input {
         position: absolute;
         width: 100%;
         height: 100%;
         opacity: 0;
         top: 0;
         left: 0;
         z-index: 10;
         cursor: pointer;
      }

      .squad {
         width: 16px;
         height: 16px;
         border: 2px solid #ababab;
         border-radius: 4px;
         position: relative;
         user-select: none;
      }

      .squad:after {
         content: '';
         position: absolute;
         display: none;
         user-select: none;
      }

      input:checked ~ .squad {
         border-color: ${(props) => props.theme.primary};
         background: ${(props) => props.theme.primary};

         &:after {
            display: block;
            left: 4px;
            top: 1px;
            width: 4px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            z-index: 100;
         }
      }
   }

   .btn-icon {
      background: #edf1f6;
      display: inline-flex;
      color: #656c71;
      padding: 8px;
      border-radius: 100px;
      width: auto;
      cursor: pointer;
      transition: 0.3s all;
      display: inline-block;
      line-height: 0px;
      vertical-align: middle;

      &:hover {
         background: ${(props) => props.theme?.primary};
         color: white;
         transition: 0.3s all;
      }
   }

   .table {
      margin-bottom: 0px;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   .h1,
   .h2,
   .h3,
   .h4,
   .h5,
   .h6 {
      color: #29235c;
      font-weight: bold;
   }

   .text-light {
      color: #d2d2d2 !important;
   }

   .text-decoration-line-through {
      text-decoration: line-through;
   }

   .cursor-pointer {
      cursor: pointer;
   }

   .text-nowrap {
      white-space: nowrap;
   }

   .container-form {
      width: 725px;
   }

   .input-title {
      font-size: 1em !important;
      line-height: 1 !important;
      color: rgba(0, 0, 0, 0.45) !important;
      text-transform: none !important;
      font-weight: 500 !important;
   }

   .form-title {
      display: block;
      margin-bottom: 0.375em;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0;
      color: rgba(0, 0, 0, 0.8);
   }

   .form-padding {
      padding: 3em;
   }

   select,
   textarea,
   .input-border {
      border-radius: 0px !important;
      border: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
      min-height: 40px !important;

      input {
         padding: 5px 0px !important;
         font-size: 15px !important;
         height: auto;
      }
   }

   textarea {
      padding: 5px 0px !important;
      min-height: 80px;
   }

   select {
      padding: 5px 0px !important;
      font-size: 14px !important;
   }

   .select-box-autocomplete {
      .input-border {
         min-height: 40px !important;
      }

      .line {
         display: none;
      }
   }

   .field-box {
      margin-bottom: 1.8em;
   }

   .error-input {
      color: #ea3439;
   }

   .btn-lg,
   .btn-group-lg > .btn {
      font-size: 1.1rem;
   }

   .checkbox-container {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      position: relative;
      display: inline-block;
      padding-right: 10px;

      .title {
         padding-left: 0px;
      }

      input {
         position: absolute;
         opacity: 0;
         cursor: pointer;
      }

      .text {
         display: inline-block;
         vertical-align: middle;
         font-weight: 500;
         color: #555;
         font-size: 14px;
      }

      .checkbox {
         width: 20px;
         height: 20px;
         border: 2px solid #bbb;
         position: relative;
         transition: 0.1s all;
         vertical-align: middle;
         display: inline-block;
         margin-right: 5px;
         border-radius: 100px;
         //border-radius: 100%;

         &:after {
            content: '';
            display: block;
            opacity: 0;
            transition: 0.1s all;
            position: relative;
            left: 5px;
            top: 2px;
            width: 6px;
            height: 10px;
            border: solid ${(props) => props.theme.primary};
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
         }

         &.quad {
            border-radius: 4px;
         }
      }

      input:checked ~ .checkbox {
         border-color: ${(props) => props.theme.primary};
         transition: 0.1s all;

         &:after {
            opacity: 1;
         }

         ~ .text {
            color: ${(props) => props.theme.primary};
         }
      }

      &:hover {
         color: ${(props) => props.theme.primary};
      }

      &.checkbox-quad {
         .checkbox {
            border-radius: 4px;
         }
      }

      &.checkbox-small {
         .checkbox {
            width: 14px;
            height: 14px;
            vertical-align: middle;
         }

         input:checked ~ .checkbox {
            &:after {
               left: 3px;
               top: 0px;
               width: 4px;
               height: 8px;
            }
         }
      }

      &.clean {
         input:checked ~ .checkbox {
            //border: 5px solid #bbe3ff;
         }

         input:checked ~ .checkbox {
            &:after {
               //display: none;
            }
         }
      }
   }

   .select-box-autocomplete .suffix.down-icon {
      display: none !important;
   }

   .modal-container {
      align-items: center;
      justify-content: center;
   }

   .checkbox-container input:checked ~ .checkbox:after {
      opacity: 0;
   }

   .checkbox-container input:checked ~ [data-testid='input-radio-circle'].checkbox:after {
      opacity: 1;
   }

   .checkbox-btl .squad {
      z-index: 1;
   }

   @media (max-width: 1024px) {
      .container {
         max-width: 100%;
      }
   }

   .link_ref {
      padding: 2px;
      border-bottom: 1px dashed #35a8e0;
   }

   .modal-content {
      max-width: 400px;
   }

   .pagination .page {
      white-space: nowrap !important;
   }

   .version {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      opacity: 0.7;
   }

   .foreground {
      background-color: red;
   }

   .flex-1 {
      flex: 1;
   }
`;
