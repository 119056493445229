import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   AuthMeAPI, AuthMeUpdateAPI,
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import {CPFValidation, OnlyNumbers, searchCEP} from '~/services';
import history from '~/config/history';

const yupValidateSchema = Yup.object().shape({
   name: Yup.string().required('Esse campo é obrigatório!'),
});

export function useMyAccountPage(): any {
   const { id } = useParams<any>();

   document.title = 'Minha conta - XPAG Tecnologia';
   
   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      // yupValidateSchema,
      resolves: {
         resource: AuthMeAPI,
      },
      resolveCreate: AuthMeUpdateAPI,
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         newForm.group_id = formData?.group_id?.value ?? null;

         return newForm;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao atualizar dados',
            type: 'error',
         });
      },
      onSuccess() {
         ToastList.show({
            message: 'Dados atualizados',
            type: 'success',
         });
      },
   });

   registerOnInit((resources: any) => {
      if (resources.resource) {
         const resource = { ...resources.resource };
         if (resource.group_id) {
            resource.group_id = { label: resource.group_id, value: resource.group_name };
         }
         formRef.current.setData(resource);
      }
   });

   function onChangeCep(): any {
      const cep = OnlyNumbers(formRef.current.getFieldValue('address.postal_code'));
      if (cep.length == 8) {
         searchCEP(cep).then((e: any) => {
            const address = (formRef.current.getData() as any).address ?? {};
            formRef.current.setData({
               ...formRef.current.getData(),
               address: {
                  ...address,
                  street: e.data.street,
                  city: e.data.city,
                  neighborhood: e.data.neighborhood,
                  state: e.data.state,
               },
            });
         });
      }
   }
   
   const formInfoData = {
      states,
   };

   return {
      onSubmit: onSubmitForm,
      id,
      editing: !!id,
      formRef,
      formInfoData,
      reloadPage,
      starting,
      saving,
      onChangeCep,
      errorLoadData,
   };
}
