import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Http } from '@devesharp/react/web/index';
import { useRecoilState } from 'recoil';
import { AuthAPI } from '~/api';
import { actionSetAuth } from '~/redux/modules/auth';
import { actionUpdateUser } from '~/redux/modules/user';
import {
   sellerPageSelectState,
   sellerSelectState,
   sellerShowInfoState,
   uniqueSellerUserState,
} from '~/recoil/seller-select.state';

export function useLoginPage(): any {
   const [logging, setLogging] = useState(false);
   const [dashInit, setDashInit] = useState(false);
   const [error, setError] = useState('');

   document.title = 'Login - XPAG Tecnologia';

   const store = useSelector<any>((store: any) => store);
   const dispatch = useDispatch();

   const [, setSellerPageSelect] = useRecoilState(sellerPageSelectState);
   const [, setSellerSelect] = useRecoilState(sellerSelectState);
   const [, setShowInfoSeller] = useRecoilState(sellerShowInfoState);
   const [, setUniqueSeller] = useRecoilState(uniqueSellerUserState);

   useEffect(() => {
      setSellerPageSelect(null);
      setSellerSelect(null);
      setShowInfoSeller(false);
      setUniqueSeller(true);
      Http.defaults.headers.Authorization = '';
   }, []);

   function onSubmit(data: { login: string; password: string }): void {
      setLogging(true);

      setTimeout(() => {
         AuthAPI(data).subscribe(
            (response: any) => {
               const { info, user } = response.data;
               Http.defaults.headers.Authorization = `Bearer ${user.access_token}`;
               dispatch(actionSetAuth(true, user.access_token));
               dispatch(actionUpdateUser(user));
               setDashInit(true);
            },
            (err: any) => {
               setError(err?.detail?.description || err?.data?.error);
               setLogging(false);
            },
         );
      }, 1000);
   }

   return {
      dashInit,
      logging,
      error,
      onSubmit,
   };
}
