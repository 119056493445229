import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   AuthMeAPI,
   AuthMeUpdateAPI,
   BankAccountsSearchResolve,
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   ExternalTransactionResolve,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';
import { useUser } from '~/hooks';

const yupValidateSchema = Yup.object().shape({
   amount: Yup.string().required('Esse campo é obrigatório!'),
   description: Yup.string().required('Esse campo é obrigatório!'),
   bank_account_id: Yup.string().required('Esse campo é obrigatório!'),
});

export function useTransfersPage(): any {
   const { id } = useParams<any>();
   document.title = 'Mesma titularidade - XPAG Tecnologia';

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      yupValidateSchema,
      resolves: {
         resource: AuthMeAPI,
      },
      resolveCreate: ExternalTransactionResolve,
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         newForm.group_id = formData?.group_id?.value ?? null;

         return newForm;
      },
      onFailed(c, resp) {
         ToastList.show({
            message: resp.data.error,
            type: 'error',
         });
      },
      onSuccess() {
         ToastList.show({
            message: 'Transferencia realizada com sucesso',
            type: 'success',
         });
      },
   });

   const user = useUser();

   const [resource, setResource] = useState<any>({});
   registerOnInit((resources: any) => {
      if (resources.resource) {
         setResource({ ...resources.resource });
      }
   });

   const formInfoData = {
      states,
   };

   function banksResolve(full_text: string): any {
      return BankAccountsSearchResolve({ limit: 5, full_text, document: user.document }).pipe(
         map((response) => response.results),
      );
   }

   function getValueBankValue(value: any): any {
      value.type = value.type == 'checking' ? 'Conta Corrente' : 'Poupança';

      formRef.current.setData({
         ...formRef.current.getData(),
         bank_account_id: value.id,
         bank_account: value,
      });
   }

   return {
      onSubmit: onSubmitForm,
      resource,
      formRef,
      formInfoData,
      reloadPage,
      starting,
      saving,
      getValueBankValue,
      banksResolve,
      errorLoadData,
   };
}
