import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete, Textarea } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Style, { ProgressColor } from './TransfersPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { useGroupsCreatePage } from '~/pages/GroupsCreate/GroupsCreatePage.controller';
import { useMyAccountPage } from '~/pages/MyAccount/MyAccountPage.controller';
import { useTransfersPage } from '~/pages/Transfers/TransfersPage.controller';
import { formatPrice } from '~/services';
import { SellerSelectInput } from '~/components/SellerSelectInput';

export const TransfersPage: React.FunctionComponent<any> = () => {
   const ctrl = useTransfersPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Realizar Saque</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <b>Valor disponivel para saque:</b>
               <h4>{formatPrice(ctrl.resource.current_balance, 0)}</h4>

               <br />

               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Informação da transferência</div>

                     <br />

                     <SellerSelectInput />

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input
                              name="amount"
                              title="Quanto deseja sacar?"
                              number
                              decimals={2}
                              prefix="R$"
                              textRight
                           />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <SelectAutocomplete
                              title="Escolher conta bancaria"
                              fetch={ctrl.banksResolve}
                              name="customer_id"
                              getValue={ctrl.getValueBankValue}
                              prefix={
                                 <div className="pr-2">
                                    <FontAwesomeIcon icon={faSearch} />
                                 </div>
                              }
                              renderInput={(item: any) => (
                                 <div>
                                    <b>{item.holder_name}</b>
                                    <br />
                                    {item.bank_name}
                                    <br />
                                    Agência {item.routing_number} | Conta {item.account_number}
                                 </div>
                              )}
                           />
                        </div>
                     </div>

                     <br />
                     <div className="form-title">Conta Bancária</div>
                     <br />

                     <div className="d-none">
                        <Input name="bank_account_id" title="Nome" />
                     </div>

                     <div className="row field-box">
                        <div className="col-md-5">
                           <Input name="bank_account.holder_name" disabled title="Nome" />
                        </div>

                        <div className="col-md-6">
                           <Input name="bank_account.type" disabled title="Tipo de Conta" />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input
                              name="bank_account.routing_number"
                              disabled
                              title="Agência da Conta"
                              withoutDotNumber
                           />
                        </div>

                        <div className="col-md-3">
                           <Input
                              name="bank_account.account_number"
                              disabled
                              title="Conta Bancária"
                              number
                              withoutDotNumber
                           />
                        </div>
                        <div className="col-md-1" />
                        <div className="col-md-4">
                           <Input name="bank_account.bank_name" disabled title="Banco" />
                        </div>
                     </div>

                     <br />
                     <div className="form-title">Informações adicionais</div>
                     <br />

                     <div className="row field-box">
                        <div className="col">
                           <Textarea name="description" title="Descrição" />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Realizar Saque</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
