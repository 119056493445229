import React, { useEffect, useMemo, useRef, useState } from 'react';
import { convertStringToDate, useViewForm } from '@devesharp/react/web';
import * as Yup from 'yup';
import * as lodash from 'lodash';
import { Observable } from 'rxjs';
import Datefns from 'date-fns';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useRecoilState } from 'recoil';
import { CustomersUpdateResolve, GroupsSearchResolve } from '~/api';
import { states } from '~/services/data';
import { OnlyNumbers } from '~/services';
import history from '~/config/history';
import { sellerSelectState, sellerShowInfoState } from '~/recoil/seller-select.state';

/**
 * Validação do Cartão
 */
const cardValidation = {
   type: Yup.string(),
   card_number: Yup.string().when('type', {
      is: (val: string) => val == 'card' || val == 'recurrence',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('card-length', 'Digite o número correto do cartão!', (val: any) => {
            return OnlyNumbers(val).length === 16;
         }),
   }),
   holder_name: Yup.string().when('type', {
      is: (val: string) => val == 'card' || val == 'recurrence',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   expiration_month: Yup.string().when('type', {
      is: (val: string) => val == 'card' || val == 'recurrence',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   expiration_year: Yup.string().when('type', {
      is: (val: string) => val == 'card' || val == 'recurrence',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   security_code: Yup.string().when('type', {
      is: (val: string) => val == 'card' || val == 'recurrence',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('cvv-length', 'Digite o código de segurança completo.', (val: any) => {
            return OnlyNumbers(val).length === 3;
         }),
   }),
};

const recurrenceValidation = {
   type: Yup.string(),
   plan_name: Yup.string().when('type', {
      is: (val: string) => val == 'recurrence',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   due_date: Yup.string()
      .nullable()
      .when('type', {
         is: (val: string) => val == 'recurrence',
         then: Yup.string()
            .nullable()
            .test('invalid-date', 'Data inválida', function (val: any): any {
               return val == null || val == '' || !!convertStringToDate(val);
            })
            .test('invalid-date', 'Data deve ser apartir de hoje', function (val: any): any {
               console.log(val, Datefns.differenceInDays(convertStringToDate(val), new Date()));
               return Datefns.differenceInDays(convertStringToDate(val), new Date()) >= 0;
            }),
      }),
   frequency: Yup.string()
      .nullable()
      .when('type', {
         is: (val: string) => val == 'recurrence',
         then: Yup.string().nullable().required('Esse campo é obrigatório!'),
      }),
   duration: Yup.string()
      .nullable()
      .when('type', {
         is: (val: string) => val == 'recurrence',
         then: Yup.string().nullable().required('Esse campo é obrigatório!'),
      }),
};

const boletoValidation = {
   expiration_date: Yup.string().when('type', {
      is: (val: string) => val == 'boleto' || val == 'booklets_boleto',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('invalid-date', 'Data deve ser apartir de hoje', function (val: any): any {
            return Datefns.differenceInDays(convertStringToDate(val), new Date()) >= 0;
         }),
   }),
   interest_percent: Yup.string().when('type', {
      is: (val: string) => val == 'boleto' || val == 'booklets_boleto',
      then: Yup.string().test('limit-percent', 'O limite do juros diários é 3,3%', (val: any): any => {
         const percent = Number(OnlyNumbers(val));
         return percent <= 3.3 * 100;
      }),
      // .test('limit-percent-min', 'A multa deve ser 0 ou maior que 0,30%', (val: any): any => {
      //    const percent = Number(OnlyNumbers(val));
      //    return percent >= 0.3 * 100 || !percent;
      // }),
   }),
   fine_percent: Yup.string().when('type', {
      is: (val: string) => val == 'boleto' || val == 'booklets_boleto',
      then: Yup.string().test('limit-percent', 'O limite da multa é 2%', (val: any): any => {
         const percent = Number(OnlyNumbers(val));
         return percent <= 2 * 100;
      }),
   }),
   discount_value: Yup.string().when('type', {
      is: (val: string) => val == 'boleto' || val == 'booklets_boleto',
      then: Yup.string().test('discount', 'O desconto não pode ser maior/igual ao valor do boleto', function (
         val: any,
      ): any {
         const amountDiscount = Number(OnlyNumbers(val));
         const amount = Number(OnlyNumbers((this as any).parent.amount));

         return amountDiscount === 0 || amount > amountDiscount;
      }),
      // .test('required-discount', 'Esse campo é obrigatório!', function (val: any): any {
      //    if ((this as any).parent.discount_date !== '') {
      //       return val !== '';
      //    }
      //
      //    return true;
      // }),
   }),
   discount_date: Yup.string().when('type', {
      is: (val: string) => val == 'boleto' || val == 'booklets_boleto',
      then: Yup.string()
         .test('invalid-date', 'Data inválida', function (val: any): any {
            return val == null || val == '' || !!convertStringToDate(val);
         })
         .test('limit-percent', 'A data do desconto precisa ser anterior a data de vencimento', function (
            val: any,
         ): any {
            return (
               val == null ||
               val == '' ||
               Datefns.differenceInDays(
                  convertStringToDate((this as any).parent.expiration_date),
                  convertStringToDate(val),
               ) > 0
            );
         })
         .test('required', 'Esse campo é obrigatório!', function (val: any): any {
            const discont_value_empty =
               (this as any).parent.discount_value == '' ||
               (this as any).parent.discount_value == null ||
               Number(OnlyNumbers((this as any).parent.discount_value)) == 0;

            return !(!discont_value_empty && !val);

            if (!discont_value_empty && !val) {
               return false;
            }

            return true;
         }),
   }),
};

const booketLetsValidation = {
   type: Yup.string(),
   name: Yup.string().when('type', {
      is: 'booklets_boleto',
      then: Yup.string().required('Informe o nome do carnê!'),
   }),
   installments: Yup.string().when('type', {
      is: 'booklets_boleto',
      then: Yup.string()
         .required('Informe o número de parcelas!')
         .test('installments-percent', 'Número de parcelas maior que 1', (val: any): any => {
            const number = Number(OnlyNumbers(val));
            return number >= 2;
         }),
   }),
};

const yupValidateSchema = Yup.object().shape({
   amount: Yup.string().required('Esse campo é obrigatório!'),
   description: Yup.string().when('type', {
      is: (v: any) => v != 'recurrence',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   type: Yup.string().required('Esse campo é obrigatório!'),
   card: Yup.object().shape(cardValidation).nullable(),
   recurrence: Yup.object().shape(recurrenceValidation).nullable(),
   booklet: Yup.object().shape(booketLetsValidation).nullable(),
   ...boletoValidation,
});

export function usePaymentsCreateStep1({
   onSubmit,
   initialType,
   onChangeType: onChangeTypeParent,
   settings,
}: any): any {
   const { id } = useParams<any>();

   const [groups, setGroups] = useState<any[]>([]);
   const [typePayment, setTypePayment] = useState(initialType);
   const [booklet, setBooklet] = useState(false);
   const [amount, setAmount] = useState(0);
   const [installments, setInstallments] = useState(1);

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   const [sellerShowInfo, setShowInfoSeller] = useRecoilState(sellerShowInfoState);

   useEffect(() => {
      onChangeTypeParent(typePayment);
      setBooklet(typePayment == 'booklets_boleto');
   }, [typePayment]);

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      resolveCreate: (data) =>
         new Observable((obs) => {
            const newData = { ...data };

            if (newData.discount_value && Number(newData.discount_value) == 0) {
               newData.discount_value = null;
            }

            if (newData.interest_percent && Number(newData.interest_percent) == 0) {
               newData.interest_percent = null;
            }

            if (newData.fine_percent && Number(newData.fine_percent) == 0) {
               newData.fine_percent = null;
            }

            if (newData.discount_value && Number(newData.discount_value) > 0) {
               newData.discounts = [
                  {
                     type: 'MONEY',
                     end_date: newData.discount_date,
                     value: newData.discount_value / 100,
                  },
               ];
            }

            if (newData.interest_percent) {
               newData.interest = {
                  type: 'DAILY_PERCENTAGE',
                  value: parseInt(newData.interest_percent, 10) / 100,
               };
            }

            if (newData.fine_percent) {
               newData.late_fee = {
                  type: 'PERCENTAGE',
                  value: parseInt(newData.fine_percent, 10) / 100,
               };
            }

            onSubmit(newData);

            obs.next({});
            obs.complete();
         }),
      resolveUpdate: (params) => CustomersUpdateResolve(id, params),
      handleFormData: (formData: any) => {
         const newForm = lodash.cloneDeep(formData);
         if (newForm.card) {
            newForm.card.type = newForm.type;
         }

         if (newForm.booklet) {
            newForm.booklet.type = newForm.type;
         }

         if (newForm.recurrence) {
            newForm.recurrence.type = newForm.type;
         }

         newForm.bookletEnabled = booklet;

         newForm.discount_date = formData?.discount_date?.dateYYYYMMDD;
         newForm.expiration_date = formData?.expiration_date?.dateYYYYMMDD;

         if (newForm?.recurrence?.due_date) {
            newForm.recurrence.due_date = formData.recurrence.due_date?.dateYYYYMMDD;
         }

         return newForm;
      },
      yupValidateSchema,
      onSuccess(creating) {
         console.log('onSuccess');
         // if (creating) {
         //    ToastList.show({
         //       message: 'Cliente adicionado com sucesso',
         //       type: 'success',
         //    });
         //    history.push('/customers');
         // } else {
         //    ToastList.show({
         //       message: 'Cliente editado com sucesso',
         //       type: 'success',
         //    });
         //    history.push('/customers');
         // }
      },
   });

   const initialData = {
      type: typePayment,
      bookletEnabled: booklet ? 'booklet' : 'unique',
      recurrence: {
         frequency: 'monthly',
         duration: 'indefinite',
      },
   };

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }
   });

   const [recurrenceFrequency, setRecurrenceFrequency] = useState(`monthly`);

   const formInfoData = useMemo(
      () => ({
         groups: [
            {
               id: '',
               name: 'Sem grupo',
            },
            ...groups.map((group) => ({
               id: group.id,
               name: group.name,
            })),
         ],
         installmentsCard: [
            {
               id: '1',
               name: '1x',
            },
            {
               id: '2',
               name: '2x',
            },
            {
               id: '3',
               name: '3x',
            },
            {
               id: '4',
               name: '4x',
            },
            {
               id: '5',
               name: '5x',
            },
            {
               id: '6',
               name: '6x',
            },
            {
               id: '7',
               name: '7x',
            },
            {
               id: '8',
               name: '8x',
            },
            {
               id: '9',
               name: '9x',
            },
            {
               id: '10',
               name: '10x',
            },
            {
               id: '11',
               name: '11x',
            },
            {
               id: '12',
               name: '12x',
            },
         ],
         expiration_month: [
            {
               id: 1,
               name: '1',
            },
            {
               id: 2,
               name: '2',
            },
            {
               id: 3,
               name: '3',
            },
            {
               id: 4,
               name: '4',
            },
            {
               id: 5,
               name: '5',
            },
            {
               id: 6,
               name: '6',
            },
            {
               id: 7,
               name: '7',
            },
            {
               id: 8,
               name: '8',
            },
            {
               id: 9,
               name: '9',
            },
            {
               id: 10,
               name: '10',
            },
            {
               id: 11,
               name: '11',
            },
            {
               id: 12,
               name: '12',
            },
         ],
         expiration_year: [
            {
               id: Datefns.getYear(new Date()),
               name: Datefns.getYear(new Date()),
            },
            {
               id: Datefns.getYear(new Date()) + 1,
               name: Datefns.getYear(new Date()) + 1,
            },
            {
               id: Datefns.getYear(new Date()) + 2,
               name: Datefns.getYear(new Date()) + 2,
            },
            {
               id: Datefns.getYear(new Date()) + 3,
               name: Datefns.getYear(new Date()) + 3,
            },
            {
               id: Datefns.getYear(new Date()) + 4,
               name: Datefns.getYear(new Date()) + 4,
            },
            {
               id: Datefns.getYear(new Date()) + 5,
               name: Datefns.getYear(new Date()) + 5,
            },
            {
               id: Datefns.getYear(new Date()) + 6,
               name: Datefns.getYear(new Date()) + 6,
            },
            {
               id: Datefns.getYear(new Date()) + 7,
               name: Datefns.getYear(new Date()) + 7,
            },
            {
               id: Datefns.getYear(new Date()) + 8,
               name: Datefns.getYear(new Date()) + 8,
            },
            {
               id: Datefns.getYear(new Date()) + 9,
               name: Datefns.getYear(new Date()) + 9,
            },
            {
               id: Datefns.getYear(new Date()) + 10,
               name: Datefns.getYear(new Date()) + 10,
            },
            {
               id: Datefns.getYear(new Date()) + 11,
               name: Datefns.getYear(new Date()) + 11,
            },
            {
               id: Datefns.getYear(new Date()) + 12,
               name: Datefns.getYear(new Date()) + 12,
            },
            {
               id: Datefns.getYear(new Date()) + 13,
               name: Datefns.getYear(new Date()) + 13,
            },
         ],
         installments: [
            {
               id: 30,
               name: '1 mês',
            },
            {
               id: 15,
               name: '15 dias',
            },
            {
               id: 40,
               name: '40 dias',
            },
            {
               id: 90,
               name: '90 dias',
            },
         ],
         states,
         frequency: [
            {
               value: 'daily',
               name: 'Diário',
            },
            {
               value: 'weekly',
               name: 'Semanal',
            },
            {
               value: 'monthly',
               name: 'Mensal',
            },
            {
               value: 'annually',
               name: 'Anual',
            },
         ],
         duration: [
            {
               id: 'indefinite',
               name: 'Indefinido',
            },
            ...(() => {
               if (recurrenceFrequency === 'daily') {
                  return Array.from({ length: 30 }, (_, i) => ({
                     id: i + 1,
                     name: `${i + 1} dia${i > 0 ? 's' : ''}`,
                  }));
               }
               if (recurrenceFrequency === 'weekly') {
                  return Array.from({ length: 4 }, (_, i) => ({
                     id: i + 1,
                     name: `${i + 1} semana${i > 0 ? 's' : ''}`,
                  }));
               }
               if (recurrenceFrequency === 'monthly') {
                  return Array.from({ length: 12 }, (_, i) => ({
                     id: i + 1,
                     name: `${i + 1} mês${i > 0 ? 'es' : ''}`,
                  }));
               }
               if (recurrenceFrequency === 'annually') {
                  return Array.from({ length: 12 }, (_, i) => ({
                     id: i + 1,
                     name: `${i + 1} ano${i > 0 ? 's' : ''}`,
                  }));
               }

               return [];
            })(),
         ],
      }),
      [recurrenceFrequency],
   );

   useDeepCompareEffectNoCheck(() => {
      // console.log(settings)
      formRef.current?.setData(settings);
   }, [settings]);

   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function openCreateGroup() {
      history.push('/groups/create');
   }

   function onChangeType() {
      setTypePayment(formRef.current?.getFieldValue('type'));
      setTimeout(() => {
         formRef.current?.setData(settings);
      }, 10);
   }

   function onChangeAmount() {
      console.log('sdsd');
      setAmount(formRef.current?.getFieldValue('amount'));
   }

   function onChangeInstallments() {
      const value = formRef.current?.getFieldValue('booklet.installments');
      setInstallments(value < 1 ? 1 : value);
   }

   function onChangeBooklet() {
      setBooklet(formRef.current?.getFieldValue('bookletEnabled') === 'booklet');
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      openCreateGroup,
      onChangeType,
      onChangeBooklet,
      onChangeAmount,
      onChangeInstallments,
      sellerShowInfo,
      typePayment,
      booklet,
      formRef,
      reloadPage,
      initialData,
      starting,
      saving,
      errorLoadData,
      formInfoData,
      totalAmount: amount * installments,
      sellerSelect,
      setRecurrenceFrequency,
   };
}
