import styled from 'styled-components';

const css = styled.div;

export const ProgressColor = css<any>`
   .progress-bar {
      background-color: ${(props) => props.color} !important;
   }
`;

export const StatusStyle = styled.span<any>`
   .badge {
      font-size: 13px;
      padding: 5px 10px;
      padding: 0px;
      border-radius: 200px;
      color: white;
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 700 !important;

      &.expired {
         color: #f9b955;
      }

      &.active {
         color: #56c470;
      }

      &.canceled {
         color: #dc3545 !important;
      }
   }
`;

export default css`
   .btn-search {
      padding-top: 23px;
   }

   .hour {
      font-weight: 800;
      font-size: 12px;
      color: #349bd3;
   }

   .selected {
      background: #d2ebf8 !important;
   }

   .checkbox-container .checkbox:after {
      border: none;
   }
`;
