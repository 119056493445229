import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { Input, Button } from '@devesharp/react/web';
import { Link } from 'react-router-dom';
import Style from './LoginPage.style';
import { Logo } from '~/assets/images';
import { useLoginPage } from '~/pages/Login/LoginPage.controller';
import { actionSetAuth } from '~/redux/modules/auth';

export const LoginPage: React.FunctionComponent<any> = () => {
   const { logging, dashInit, onSubmit, error } = useLoginPage();

   const [ref, bounds] = useMeasure();
   const animationHide = useSpring({
      height: logging ? 0 : bounds.height,
      opacity: logging ? 0 : 1,
      config: {
         duration: 200,
      },
   });

   const animationShow = useSpring({
      opacity: !dashInit && logging ? 1 : 0,
      config: {
         duration: 200,
      },
   });

   const animationShowLogo = useSpring({
      opacity: dashInit ? 0 : 1,
      config: {
         duration: 200,
      },
   });

   return (
      <Style data-cy="login-page">
         <div className="login-page--container">
            <div className="login-page--container--inner">
               <div className="logos">
                  <animated.div style={{ ...animationShowLogo }}>
                     <img src={Logo} className="logo" />
                  </animated.div>
                  {/* <img src={LogoBlack} className="logo-black" /> */}
               </div>

               <animated.div style={{ overflow: 'hidden', ...animationHide }}>
                  <div ref={ref}>
                     <div className="text-danger font-weight-bold text-center">{error}</div>
                     <Form onSubmit={onSubmit}>
                        <Input name="login" placeholder="Login" />
                        <Input name="password" placeholder="Senha" type="password" />

                        <br />
                        <Button variant="primary" type="submit" style={{ width: '100%' }} size="lg">
                           Entrar
                        </Button>
                     </Form>

                     <div className="text-center pt-2">
                        <br />
                        <br />
                        <Link to="/forget-password">
                           <b>Esqueci a senha</b>
                        </Link>
                     </div>
                  </div>
               </animated.div>

               <animated.div style={{ ...animationShow }}>
                  <div className="text-center">
                     <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                     </Spinner>
                  </div>
               </animated.div>
            </div>

            <div className="version text-center">Dashboard v{process.env.REACT_APP_VERSION}</div>
            {/* <div className="font-weight-bold text-center pt-5">Versão {version}</div> */}
         </div>
      </Style>
   );
};
