import React from 'react';
import { Route as RouteDom, Redirect, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { useIsLogged } from '~/hooks';
import { Header } from '~/components';

export function Route({ isPrivate = false, component: Component, ...rest }: any): any {
   const isLogged = useIsLogged();
   const location = useLocation();
   const query = QueryString.parse(location.search);

   if (!isLogged && isPrivate) {
      if (location.pathname === '/logout') {
         return <Redirect to="/" />;
      }

      return <Redirect to={`/?redirect=${location.pathname}`} />;
   }

   if (isLogged && !isPrivate) {
      if (query.redirect) {
         return <Redirect to={query.redirect as string} />;
      }
      return <Redirect to="/home" />;
   }

   if (isPrivate) {
      return (
         <>
            <Header />
            <RouteDom {...rest} render={(props) => <Component {...props} />} />
            <div className="version text-center">Dashboard v{process.env.REACT_APP_VERSION}</div>
         </>
      );
   }

   // <Dashboard>
   //    <Component {...props} />
   // </Dashboard>

   return <RouteDom {...rest} render={(props) => <Component {...props} />} />;
}
