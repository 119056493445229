import React, { useEffect, useRef, useState } from 'react';
import { ToastList, useViewForm } from '@devesharp/react/web';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { PaymentBBCreateResolve } from '~/api';
import history from '~/config/history';

export function usePaymentsCreatePage(): any {
   const { id } = useParams<any>();
   const [resource, setResource] = useImmer<any>({});
   const paymentData = useRef<any>({});
   const [initialType, setInitialType] = useState('boleto');
   document.title = `Emissão de Boleto para Banco do Brasil`;

   const { starting, saving, errorLoadData, reloadPage, formRef, onSubmitForm } = useViewForm({
      // yupValidateSchema,
      resolves: {},
      handleFormData() {
         return paymentData.current;
      },
      resolveCreate: PaymentBBCreateResolve,
      onSuccess(creating, resp) {
         if (paymentData.current.bookletEnabled) {
            history.push(`/booklets/detail/${resp.id}`);
         } else {
            history.push(`/payments/bb/boletos`);
         }

         ToastList.show({
            message: 'Boleto emitido com sucesso!',
            type: 'success',
         });
      },
      onFailed(creating, resp) {
         ToastList.show({
            message: resp.data.error,
            type: 'error',
         });
      },
   });

   const [step, setStep] = useState(1);

   function onSubmitStep1(v: any): void {
      window.scrollTo(0, 0);
      setStep(2);
      setResource((draft) => ({ ...draft, ...v }));
   }

   function backStep1(): void {
      window.scrollTo(0, 0);
      setStep(1);
   }
   function backStep2(): void {
      window.scrollTo(0, 0);
      setStep(2);
   }

   function onSubmitStep2(v: any): void {
      window.scrollTo(0, 0);
      setResource((draft) => ({ ...draft, ...v }));
      setStep(3);
   }

   function finish(): void {
      const data = { ...resource };

      if (!data?.bookletEnabled) {
         data.booklet = null;
      }

      paymentData.current = data;

      formRef.current.submitForm();
   }

   function onChangeType(type: string): any {
      setInitialType(type);
   }

   return {
      reloadPage,
      starting,
      saving,
      formRef,
      initialType,
      onSubmitForm,
      errorLoadData,
      onChangeType,
      onSubmitStep1,
      backStep1,
      onSubmitStep2,
      backStep2,
      finish,
      resource,
      step,
      setStep,
   };
}
