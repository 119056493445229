import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   .input-title {
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 3px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.8);

      &.focus {
         color: ${(props) => props.theme.primary};
      }
   }

   .input-border {
      display: flex;
      align-content: center;
      align-items: center;
      //border-radius: 4px;
      width: 100%;
      background: white;
      border: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: #444;
      font-size: 15px;
      font-weight: 600;
      height: 40px;

      ${(props) =>
         props.themeInput === 'gray' &&
         `background: #eee;
            border-color: #eee;
        `}

      .prefix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-left: 10px;
         font-size: 14px;
         align-self: center;
         display: flex;
         align-content: center;
         align-items: center;
      }

      .suffix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-right: 10px;
         font-size: 14px;
         align-self: center;
         display: flex;
         align-content: center;
         align-items: center;
      }

      .suffix-loading {
         color: ${(props) => props.theme.primary};
      }

      &.has-error {
         border-color: ${(props) => props.theme.red} !important;
      }

      input {
         padding: 7px 10px;
         width: 100%;
         border: none;
         flex: 1;
         background: transparent;
      }

      .prefix ~ input {
         padding-left: 5px;
      }

      &.text-right {
         input {
            text-align: right;
         }
      }

      &.input-hidden {
         border: none;
      }

      &.focus {
         border-color: ${(props) => props.theme.primary};
      }
   }

   .error-input {
      color: ${(props) => props.theme.red};
      text-align: left;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      font-size: 13px;
      margin-top: 2px;
      margin-bottom: 10px;
   }
`;
