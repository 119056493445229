import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faCalendarCheck,
   faBarcode,
   faClock,
   faPaperPlane,
   faLongArrowAltUp,
   faLongArrowAltDown,
   faArrowUp,
   faArrowDown,
   faAngleDown,
   faSort,
   faSortDown,
   faSortUp,
   faLink,
   faEdit,
   faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   FlexContainer,
   FlexItem,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import Style, { ProgressColor } from './BookletsListingPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useBookletsListingPage } from '~/pages/BookletsListing/BookletsListingPage.controller';

export const BookletsListingPage: React.FunctionComponent<any> = () => {
   const ctrl = useBookletsListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-3">
                  <div className="row align-items-center">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de Carnês</h3>
                     </div>
                     <div className="col text-right">
                        <Button type="submit" size="lg" onClick={() => history.push('/payments/create/boleto')}>
                           Criar carnê
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {ctrl.loading && <LoadingPage />};
         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     <div className="flex-grow-1 px-2">
                        <Input name="name" title="Nome do Carnê" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Input name="customer_name" title="Pagador" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_gte" title="Criado a partir" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_lte" title="Criado até" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="group_id" title="Grupos" items={ctrl.filtersData.groups} />
                     </div>
                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="text-dark">
                           Mostrando <b>{ctrl.pageCountStart}</b>-<b>{ctrl.pageCountEnd}</b> de{' '}
                           <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div />
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Nome do cârne"
                                    sort="name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           {ctrl.showSeller && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? (
                                    <Skeleton height={20} width={100} />
                                 ) : (
                                    'Estabelecimento'
                                 )}
                              </th>
                           )}
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Qtd. boletos"
                                    sort="installments"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : <>Pagador</>}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Criado em"
                                    sort="created_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((customer: any) => (
                           <ItemListing
                              key={customer.id}
                              customer={customer}
                              selected={ctrl.checkSelected(customer.id)}
                              setSelected={ctrl.onSelect}
                              sendSMS={ctrl.sendOne}
                              cancelBooklet={ctrl.cancelBooklet}
                              showEstabelecimento={ctrl.showSeller}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   customer,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   cancelBooklet,
   showEstabelecimento,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   function openBooklets() {
      history.push(`/booklets/detail/${customer.id}`);
   }

   if (skeleton) {
      return (
         <tr>
            <td>
               <Skeleton height={18} width={100} />
            </td>
            <td className="text-center">
               <Skeleton height={18} width={100} />
            </td>
            <td className="text-center">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
         </tr>
      );
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`cursor-pointer ${selected && 'selected'}`}>
            <td onClick={openBooklets}>{customer.name}</td>

            {showEstabelecimento && (
               <td onClick={openBooklets}>
                  <div className="text-dark">
                     <b>{customer.seller_name}</b>
                  </div>
                  <div>
                     {customer?.seller_document?.length > 11 ? 'CNPJ' : 'CPF'}:{' '}
                     {CNPJAndCPFMask(customer?.seller_document)}
                  </div>
               </td>
            )}
            <td onClick={openBooklets} className="text-center">
               {customer.installments}
            </td>
            <td>
               {!!customer.customer?.id && (
                  <Link className="link_ref" to={`/customers/edit/${customer.customer?.id}`}>
                     {customer.customer?.full_name}
                  </Link>
               )}
            </td>
            <td onClick={openBooklets} className="text-center text-nowrap">
               <b>{formatDate(customer.created_at)}</b>
            </td>
            <td className="text-right text-nowrap">
               <span onClick={() => cancelBooklet(customer.id)} className="btn-icon ml-2">
                  <FontAwesomeIcon icon={faTimes} /> Cancelar
               </span>

               <Link to={`/booklets/detail/${customer.id}`}>
                  <div className="btn-icon p-3">
                     <b>Abrir boletos</b>
                  </div>
               </Link>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);

const ExpiredOn = React.memo(function ExpiredOn({ transaction }: any): any {
   if (!transaction.expired_on) return <b>Não expira</b>;

   const diff = DateFNS.differenceInDays(DateFNS.format(convertStringToDate(transaction.expired_on)), new Date());

   if (diff <= 0) {
      return <b className="text-danger">{formatDate(transaction.expired_on)}</b>;
   }

   return <b className="text-success">{formatDate(transaction.expired_on)}</b>;
}, _.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);
