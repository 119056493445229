import styled from 'styled-components';

const css = styled.div;

export const FormStyle = css<any>`
   .input-title {
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 3px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      transition: 0.2s all;

      &.focus {
         transition: 0.2s all;
      }
   }

   .input-border {
      display: flex;
      align-content: center;
      align-items: center;
      width: 100%;
      background: white;
      border: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: #444;
      font-size: 15px;
      font-weight: 600;
      height: 40px;
      transition: 0.2s all;

      ${(props) =>
         props.themeInput === 'gray' &&
         `background: #eee;
            border-color: #eee;
        `}

      .prefix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-left: 10px;
         font-size: 14px;
         align-items: center;
         justify-content: center;
         display: flex;
      }

      .suffix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-right: 10px;
         font-size: 14px;
         align-items: center;
         justify-content: center;
         display: flex;
      }

      &.has-error {
      }

      input {
         padding: 7px 10px;
         width: 100%;
         border: none;
         flex: 1;
         background: transparent;
         font-size: 14px;
         font-weight: 500;
      }

      .prefix ~ input {
         padding-left: 10px;
      }

      &.text-right {
         input {
            text-align: right;
         }
      }

      &.input-hidden {
         border: none;
      }

      &.focus {
         transition: 0.2s all;
      }
   }

   .error-input {
      text-align: left;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      font-size: 13px;
      margin-top: 4px;
      margin-bottom: 10px;
   }

   &.hide {
      display: none;
   }
`;

export const Container = css<any>`
   position: relative;

   option {
      color: black;
      -webkit-appearance: none;
   }
   
   .select-box {
      position: relative;
      width: 100%;

      .select-icon {
         position: absolute;
         height: 100%;
         right: 0px;
         top: 0px;
         width: 30px;
         text-align: center;
         display: flex;
         font-size: 17px;
         justify-content: center;
         align-items: center;
         pointer-events: none;
         z-index: 1;

         &:hover {
            cursor: pointer;
         }
      }
   }
   
   .suffix-loading {
      position: absolute;
      height: 100%;
      right: 30px;
      top: 0px;
      width: 30px;
      text-align: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      font-size: 17px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      pointer-events: none;
      z-index: 1;
   }

   select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      padding: 7px 15px;
      padding-right: 40px;
      width: 100%;
      background: white;
      border: 2px solid rgba(0, 0, 0, 0.25);
      color: #444;
      font-weight: 500;
      padding-right: 26px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 600;
      z-index: 1;
      position: relative;
      background: transparent;

      &:hover {
         cursor: pointer;
      }

      &.has-error {
         border-color: ${(props) => props.theme.red} !important;
      }

      &:focus,
      &.focus {
         border-color: ${(props) => props.theme.primary};
      }
   }
`;
