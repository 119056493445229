import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import fileDownload from 'js-file-download';
import {
   Tabs,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import {
   BoletoCancelResolve,
   CustomersSearchResolve,
   DataDashResolve,
   GroupsSearchResolve,
   PaymentsBBSearchResolve,
   PaymentsSearchResolve,
   RemessaBBSMSBoletoResolve,
   RetornoBBSMSBoletoResolve,
   SendSMSBoletoResolve,
} from '~/api';
import { formatPrice } from '~/services';
import { useViewList } from '~/hooks/useViewList';

const grahSettings = {
   data: {
      labels: [''],
      datasets: [
         {
            label: '# of Votes',
            data: [100],
            backgroundColor: ['#eee'],
         },
      ],
   },
   options: {
      cutoutPercentage: 75,
      legend: {
         display: false,
      },
      tooltips: {
         callbacks: {
            title(tooltipItem: any, data: any) {
               return data.labels[tooltipItem[0].index];
            },
            label() {
               return '';
            },
            afterLabel(tooltipItem: any, data: any) {
               return formatPrice(data?.prices[tooltipItem?.index] ?? 0, 0);
            },
         },
      },
   },
   height: 250,
};

export function useBoletosListingPage(): any {
   document.title = 'Lista de boletos Banco do Brasil';

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      setFilters,
      setOffset,
      registerOnSearch,
      setPage,
      setSort,
      graph,
      filters,
   } = useViewList({
      resolves: {
         items: PaymentsBBSearchResolve,
         groups: GroupsSearchResolve,
      },
      filtersDefault: {
         payment_type: 'boleto',
         dash: true,
      },
   }) as any;

   const [expiredGraphProps, setExpiredGraphProps] = useState<any>(grahSettings);
   const [toExpireGraphProps, setToExpireGraphProps] = useState<any>(grahSettings);
   const [losersBar, setLosersBar] = useState<any[]>([]);
   const [toWinBar, setToWinBar] = useState<any[]>([]);
   const [statement, setStatement] = useState([]);

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [resource, setResource] = useState<any>();
   const [groups, setGroups] = useState<any[]>([]);
   const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();
   const fileInput = useRef<any>();

   useLayoutEffect(() => {
      if (!graph) {
         return;
      }

      setResource(null);
      setExpiredGraphProps(grahSettings);
      setToExpireGraphProps(grahSettings);
      setLosersBar([]);
      setToWinBar([]);
      setStatement([]);

      PaymentsSearchResolve(filters).subscribe(
         (r) => {
            setResource(graph);

            setStatement((graph as any).next);

            setToExpireGraphProps({
               ...expiredGraphProps,
               data: {
                  labels: [
                     'A vencer até 30 dias',
                     'A vencer em de 31 a 60 dias',
                     'A vencer em de 61 a 90 dias',
                     'A vencer em de 91 a 120 dias',
                     'A vencer em de 121 a 180 dias',
                     'Acima de 180 dias',
                  ],
                  prices: [
                     (graph as any).to_win['0_30'].sum,
                     (graph as any).to_win['30_60'].sum,
                     (graph as any).to_win['60_90'].sum,
                     (graph as any).to_win['90_120'].sum,
                     (graph as any).to_win['120_180'].sum,
                     (graph as any).to_win['180'].sum,
                  ],
                  datasets: [
                     {
                        label: '# of Votes',
                        data: [
                           (graph as any).to_win['0_30'].count,
                           (graph as any).to_win['30_60'].count,
                           (graph as any).to_win['60_90'].count,
                           (graph as any).to_win['90_120'].count,
                           (graph as any).to_win['120_180'].count,
                           (graph as any).to_win['180'].count,
                        ],
                        backgroundColor: ['#35a8e0', '#3097ca', '#2a86b3', '#25769d', '#206586', '#1b5470'],
                     },
                  ],
               },
            });
            const to_win: any[] = [];
            to_win.push({
               ...(graph as any).to_win['0_30'],
               name: 'A vencer até 30 dias',
               color: '#35a8e0',
               urlData: {
                  initialDate: dateFNS.format(new Date(), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.addDays(new Date(), 30), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(new Date(), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 30), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            to_win.push({
               ...(graph as any).to_win['30_60'],
               name: 'A vencer em de 31 a 60 dias',
               color: '#3097ca',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.addDays(new Date(), 31), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.addDays(new Date(), 60), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 31), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 60), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            to_win.push({
               ...(graph as any).to_win['60_90'],
               name: 'A vencer em de 61 a 90 dias',
               color: '#2a86b3',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.addDays(new Date(), 61), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.addDays(new Date(), 90), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 61), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 90), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            to_win.push({
               ...(graph as any).to_win['90_120'],
               name: 'A vencer em de 91 a 120 dias',
               color: '#25769d',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.addDays(new Date(), 91), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.addDays(new Date(), 120), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 91), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 120), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            to_win.push({
               ...(graph as any).to_win['120_180'],
               name: 'A vencer em de 121 a 180 dias',
               color: '#206586',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.addDays(new Date(), 121), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.addDays(new Date(), 180), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 121), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 180), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            to_win.push({
               ...(graph as any).to_win['180'],
               name: 'Acima de 180 dias',
               color: '#1b5470',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.addDays(new Date(), 180), 'DD/MM/YYYY'),
                  status: 'PENDING',
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 180), 'YYYYMMDD'),
                  status_group: 'next',
               },
            });
            setToWinBar(to_win);

            setExpiredGraphProps({
               ...expiredGraphProps,
               data: {
                  labels: [
                     'Vencidos a 30 dias',
                     'Vencidos de 31 a 60 dias',
                     'Vencidos de 61 a 90 dias',
                     'Vencidos de 91 a 120 dias',
                     'Vencidos de 121 a 180 dias',
                     'Vencidos a mais de 180 dias',
                  ],
                  prices: [
                     (graph as any).losers['0_30'].sum,
                     (graph as any).losers['30_60'].sum,
                     (graph as any).losers['60_90'].sum,
                     (graph as any).losers['90_120'].sum,
                     (graph as any).losers['120_180'].sum,
                     (graph as any).losers['180'].sum,
                  ],
                  datasets: [
                     {
                        data: [
                           (graph as any).losers['0_30'].count,
                           (graph as any).losers['30_60'].count,
                           (graph as any).losers['60_90'].count,
                           (graph as any).losers['90_120'].count,
                           (graph as any).losers['120_180'].count,
                           (graph as any).losers['180'].count,
                        ],
                        backgroundColor: ['#f32d32', '#db292d', '#c22428', '#aa1f23', '#921b1e', '#7a1719'],
                     },
                  ],
               },
            });

            const losers = [];
            losers.push({
               ...(graph as any).losers['0_30'],
               name: 'Vencidos a 30 dias',
               color: '#f32d32',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.subDays(new Date(), 30), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(new Date(), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 30), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(new Date(), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            losers.push({
               ...(graph as any).losers['30_60'],
               name: 'Vencidos de 31 a 60 dias',
               color: '#db292d',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.subDays(new Date(), 60), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.subDays(new Date(), 31), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 60), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 31), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            losers.push({
               ...(graph as any).losers['60_90'],
               name: 'Vencidos de 61 a 90 dias',
               color: '#c22428',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.subDays(new Date(), 90), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.subDays(new Date(), 61), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 90), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 61), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            losers.push({
               ...(graph as any).losers['90_120'],
               name: 'Vencidos de 91 a 120 dias',
               color: '#aa1f23',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.subDays(new Date(), 120), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.subDays(new Date(), 91), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 120), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 91), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            losers.push({
               ...(graph as any).losers['120_180'],
               name: 'Vencidos de 121 a 180 dias',
               color: '#921b1e',
               urlData: {
                  initialDate: dateFNS.format(dateFNS.subDays(new Date(), 180), 'DD/MM/YYYY'),
                  finalDate: dateFNS.format(dateFNS.subDays(new Date(), 121), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 180), 'YYYYMMDD'),
                  expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 121), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            losers.push({
               ...(graph as any).losers['180'],
               name: 'Vencidos a mais de 180 dias',
               color: '#7a1719',
               urlData: {
                  finalDate: dateFNS.format(dateFNS.subDays(new Date(), 181), 'DD/MM/YYYY'),
               },
               url: {
                  expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 181), 'YYYYMMDD'),
                  status_group: 'expired',
               },
            });
            setLosersBar(losers);
         },
         () => {},
      );
   }, [graph]);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }
   });

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      onUnselectAll();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   const filtersData = {
      types: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: 'credit',
            name: 'Cartão de crédito',
         },
         {
            id: 'boleto',
            name: 'Boleto',
         },
      ],
      groups: [
         {
            id: '',
            name: 'Todos',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'next', name: 'A vencer' },
         { id: 'expired', name: 'Vencidos' },
         { id: 'pay', name: 'Pagos' },
         { id: 'cancel', name: 'Cancelados' },
      ],
   };

   function sendSMS(): void {
      setLoading(true);

      const ids = Array.from(selected.keys());
      let response = {
         filters,
      };

      if (ids.length) {
         response = {
            filters: {
               ids: Array.from(selected.keys()),
            },
         };
      }
      RemessaBBSMSBoletoResolve(response).subscribe(
         (v) => {
            fileDownload(v.data.remessa, `remessa.ret`);
            onUnselectAll();
            setLoading(false);
         },
         () => {
            setLoading(false);
            ToastList.show({
               message: 'Erro ao gerar remessa',
               type: 'error',
            });
         },
      );
   }

   function uploadRetorno(e: React.ChangeEvent<HTMLInputElement>): void {
      if ((e?.target?.files as any).length > 0) {
         RetornoBBSMSBoletoResolve((e?.target?.files as any)[0]).subscribe(
            (v) => {
               ToastList.show({
                  message: 'Retorno enviado com sucesso',
                  type: 'success',
               });
               reloadPage();
               onUnselectAll();
               setLoading(false);
            },
            () => {
               setLoading(false);
               ToastList.show({
                  message: 'Erro ao gerar remessa',
                  type: 'error',
               });
            },
         );
      }
   }

   function sendOne(id: string): void {
      setLoading(true);

      SendSMSBoletoResolve([id]).subscribe(
         () => {
            onUnselectAll();
            setLoading(false);
            ToastList.show({
               message: 'SMS enviado com sucesso',
            });
         },
         () => {
            setLoading(false);
            ToastList.show({
               message: 'Erro ao enviar SMS',
               type: 'error',
            });
         },
      );
   }

   function cancelBoleto(id: string, cancel: boolean): void {
      setLoading(true);

      BoletoCancelResolve(id, cancel).subscribe(
         () => {
            onUnselectAll();
            reloadPage(false);
            setLoading(false);
            ToastList.show({
               message: 'Boleto cancelado com sucesso!',
            });
         },
         () => {
            setLoading(false);
            ToastList.show({
               message: 'Erro ao cancelar boleto',
               type: 'error',
            });
         },
      );
   }

   function onSubmit(data: any) {
      const params = data;
      params.expiration_date_gte = data?.expiration_date_gte?.dateYYYYMMDD;
      params.expiration_date_lte = data?.expiration_date_lte?.dateYYYYMMDD;

      setFilters(() => params);
   }

   return {
      onSubmit,
      setSort,
      formRef,
      reloadPage,
      starting,
      searching,
      loading,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      onSelect,
      onUnselectAll,
      selected,
      sendSMS,
      uploadRetorno,
      sendOne,
      cancelBoleto,
      checkSelected,
      fileInput,
      //
      // starting,
      resource,
      losersBar,
      toWinBar,
      expiredGraphProps,
      toExpireGraphProps,
      statement,
   };
}
