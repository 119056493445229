import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   .badge {
      font-size: 13px;
      padding: 5px 10px;
      padding: 0px;
      border-radius: 200px;
      color: white;
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 700 !important;
   }

   .undefined {
      color: #000;
   }

   .pedding {
      color: #f9b955;
   }

   .badge-paid,
   .badge-succeeded {
      color: #56c470;
   }

   .badge-error,
   .badge-canceled {
      color: #dc3545 !important;
   }

   .badge-failed {
      color: #e35663 !important;
   }

   .badge-blocked {
      color: #222 !important;
   }

   .badge-charged_back {
      color: #888 !important;
   }

   .badge-creating,
   .badge-dispute {
      color: #3caaff !important;

      div {
         display: inline-block !important;
      }
   }

   .badge-queue,
   .badge-pending {
      color: #f9b955 !important;

      div {
         display: inline-block !important;
      }
   }

   .badge-reversed {
      color: #8d55f9 !important;
   }

   .badge-refunded {
      color: #55a6f9 !important;
   }
`;
