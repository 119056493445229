import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { Input, Button } from '@devesharp/react/web';
import Style from './RecoveryPassword.style';
import { Logo } from '~/assets/images';
import { useRecoveryPasswordPage } from '~/pages/RecoveryPassword/RecoveryPassword.controller';
import { LoadingPage } from '~/components';

export const RecoveryPassword: React.FunctionComponent<any> = () => {
   const { logging, dashInit, onSubmit, error, success, start, find } = useRecoveryPasswordPage();

   const [ref, bounds] = useMeasure();
   const animationHide = useSpring({
      height: logging ? 0 : bounds.height,
      opacity: logging ? 0 : 1,
      config: {
         duration: 200,
      },
   });

   const animationShow = useSpring({
      opacity: !dashInit && logging ? 1 : 0,
      config: {
         duration: 200,
      },
   });

   const animationShowLogo = useSpring({
      opacity: dashInit ? 0 : 1,
      config: {
         duration: 200,
      },
   });

   if (success) {
      return (
         <Style data-cy="login-page">
            <img src={Logo} className="logo" width={250} />

            <br />
            <br />
            <h2>Sua foi trocado com sucesso!</h2>
            <p>Agora você pode acessar sua conta com a nova senha.</p>
            <Button onClick={() => (window.location.href = '/login')}>Voltar</Button>
         </Style>
      );
   }

   if (start && !find) {
      return (
         <Style data-cy="login-page">
            <img src={Logo} className="logo" width={250} />

            <br />
            <br />
            <h2 className="text-danger">Token inválido ou expirado</h2>
            <p>O token de recuperação de senha é inválido ou expirou.</p>
            <Button onClick={() => (window.location.href = '/login')}>Voltar</Button>
         </Style>
      );
   }

   return (
      <Style data-cy="login-page">
         {!start && <LoadingPage />}

         <div className="login-page--container">
            <div className="login-page--container--inner">
               <div className="logos">
                  <animated.div style={{ ...animationShowLogo }}>
                     <img src={Logo} className="logo" />
                  </animated.div>
                  {/* <img src={LogoBlack} className="logo-black" /> */}
               </div>

               <animated.div style={{ overflow: 'hidden' }}>
                  <div className="text-center">
                     <h2>Trocar senha</h2>
                  </div>
                  <br />

                  <div ref={ref}>
                     <div className="text-danger font-weight-bold text-center">{error}</div>
                     <Form onSubmit={onSubmit}>
                        Informe sua nova senha
                        <Input name="new_password" placeholder="Senha" />
                        <Input name="re_new_password" placeholder="Confirmar Senha" />
                        <br />
                        <Button variant="primary" type="submit" style={{ width: '100%' }} size="lg">
                           Recuperar senha
                        </Button>
                     </Form>
                  </div>
               </animated.div>

               <animated.div style={{ ...animationShow }}>
                  <div className="text-center">
                     <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                     </Spinner>
                  </div>
               </animated.div>
            </div>

            <div className="version text-center">Dashboard v{process.env.REACT_APP_VERSION}</div>
            {/* <div className="font-weight-bold text-center pt-5">Versão {version}</div> */}
         </div>
      </Style>
   );
};
