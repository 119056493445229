import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Radio, Textarea, Datepicker, SelectAutocomplete } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { faBarcode, faCreditCard, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style, { ProgressColor } from './PaymentsCreateStep2.style';
import { usePaymentsCreateStep1 } from '~/pages/PaymentsCreate/Step1/PaymentsCreateStep1.controller';
import { usePaymentsCreateStep2 } from '~/pages/PaymentsCreate/Step2/PaymentsCreateStep2.controller';
import { Select } from '~/components/Customs/Select';

export const PaymentsCreateStep2: React.FunctionComponent<any> = ({ backStep, onSubmit }: any) => {
   const ctrl = usePaymentsCreateStep2({ onSubmit });

   return (
      <Style>
         <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
            <Card className="p-n">
               <div className="form-padding ">
                  <div className="form-title">Buscar cliente</div>

                  <br />

                  <div className="row field-box">
                     <div className="col">
                        <SelectAutocomplete
                           title="Selecionar cliente"
                           fetch={ctrl.customersResolve}
                           placeholder={'Digite o nome do cliente, CPF ou CNPJ'}
                           name="customer_id"
                           prefix={
                              <div className="pr-2">
                                 <FontAwesomeIcon icon={faSearch} />
                              </div>
                           }
                           getValue={ctrl.getValueCustomers}
                           renderInput={(item: any) => (
                              <div>
                                 <b>
                                    {!!item.business_name && item.business_name}
                                    {!item.business_name && (
                                       <>
                                          {item.first_name} {item.last_name}
                                       </>
                                    )}
                                 </b>
                                 <br />
                                 {!item.business_name && <>CPF: {item.taxpayer_id}</>}
                                 {!!item.business_name && <>CNPJ: {item.taxpayer_id}</>}
                              </div>
                           )}
                        />
                     </div>
                  </div>
               </div>

               <hr />

               <div className="form-padding ">
                  <div className="form-title">Dados do cliente</div>

                  <br />

                  <div className="d-none">
                     <Input name="id" />
                  </div>

                  <div className="row field-box">
                     <div className="col">
                        <Select title="Grupo" options={ctrl.formInfoData.groups} name="group_id" />

                        {/* <SelectAutocomplete */}
                        {/*   title="Grupo" */}
                        {/*   fetch={ctrl.groupResolve} */}
                        {/*   name="group_id" */}
                        {/*   prefix={ */}
                        {/*      <div className="pr-2"> */}
                        {/*         <FontAwesomeIcon icon={faSearch} /> */}
                        {/*      </div> */}
                        {/*   } */}
                        {/*   getValue={(v) => (v ? { label: v.name, value: v.id } : null)} */}
                        {/*   getValueText={(v: any) => v} */}
                        {/*   renderInput={(item: any) => <div>{item.name}</div>} */}
                        {/* /> */}
                     </div>
                     <div className="col" />
                  </div>

                  <div className="row field-box">
                     <div className="col">
                        <Select
                           name="person_type"
                           title="Tipo de Pessoa"
                           options={ctrl.formInfoData.person_type}
                           onChange={ctrl.onChangePersonType}
                        />
                     </div>
                  </div>

                  {ctrl.personType == 1 && (
                     <>
                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="taxpayer_id" title="CPF" CPF />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="first_name" title="Nome" />
                           </div>

                           <div className="col-md-6">
                              <Input name="last_name" title="Sobrenome" />
                           </div>
                        </div>
                     </>
                  )}

                  {ctrl.personType == 2 && (
                     <>
                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="taxpayer_id" title="CNPJ" CNPJ />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="business_name" title="Nome fantasia" />
                           </div>
                        </div>
                     </>
                  )}

                  <div className="row field-box">
                     <div className="col-md-6">
                        <Input name="email" title="Email" />
                     </div>

                     <div className="col-md-6">
                        <Input name="phone_number" title="Celular" phone />
                     </div>
                  </div>
               </div>
               <hr />
               <div className="form-padding ">
                  <div className="form-title">Endereço</div>

                  <br />

                  <div className="row field-box">
                     <div className="col-md-4">
                        <Input name="address_postal_code" title="CEP" CEP onInput={ctrl.onChangeCep} loading={ctrl.isLoadingCep} />
                     </div>

                     <div className="col-md-6" />
                  </div>

                  <div className="row field-box">
                     <div className="col-md-9">
                        <Input name="address_address" title="Endereço" />
                     </div>

                     <div className="col-md-3">
                        <Input name="address_number" title="Número" number />
                     </div>
                  </div>

                  <div className="row field-box">
                     <div className="col-md-4">
                        <Input name="address_neighborhood" title="Bairro" />
                     </div>

                     <div className="col-md-5">
                        <Input name="address_city" title="Cidade" />
                     </div>

                     <div className="col-md-3">
                        <Select name="address_state" title="Estado" options={ctrl.formInfoData.states} />
                     </div>
                  </div>
               </div>
            </Card>
            <div className="text-right pt-2">
               <span className="text-right pr-2">
                  <Button size="lg" onClick={backStep} variant="light">
                     <b>Voltar</b>
                  </Button>
               </span>
               <Button size="lg" type="submit">
                  <b>Avançar</b>
               </Button>
            </div>
            <br />
            <br />
         </Form>
      </Style>
   );
};
