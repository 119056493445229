import styled from 'styled-components';

const css = styled.div;

export default css`
   display: flex;
   align-items: center;
   align-content: center;
   justify-content: center;
   justify-items: center;
   flex-direction: column;
   width: 100%;
   height: 100%;
   background: white;
   //background: #29235c;

   .login-page--container {
      max-width: 400px;
      width: 100%;
      padding: 20px;

      .logos {
         position: relative;
         padding-bottom: 30px;
      }

      .logo {
         margin: auto;
         display: table;
         width: 300px;
         margin-bottom: 30px;
      }

      .logo-black {
         height: 111px;
         position: absolute;
         left: 50%;
         top: 0px;
         transform: translateX(-50%);
      }
   }
`;
