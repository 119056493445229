import React, { useEffect, useMemo, useRef, useState } from 'react';
import { faUserCircle, faCogs, faLayerGroup, faTable, faSignOutAlt, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header as HeaderDS, SelectAutocomplete, UserAvatar, useClickOutside, MaskText } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import Style from './Header.style';
import { LogoHeader } from '~/assets/images';
import { useUser } from '~/hooks';
import { CNPJAndCPFMask, formatPrice } from '~/services';
import { SellerSelect } from '~/components/SellerSelect';
import { sellerPageSelectState, uniqueSellerUserState } from '~/recoil/seller-select.state';

export function Header(): any {
   const user = useUser();
   const [uniqueSeller, setUniqueSeller] = useRecoilState(uniqueSellerUserState);

   const menuOptions = [
      {
         title: 'Início',
         route: '/',
      },
      {
         title: 'Transferência',
         subMenu: [
            {
               title: 'Mesma titularidade',
               route: '/transfers',
            },
            {
               title: 'Outro titularidade',
               route: '/other',
            },
         ],
      },
      {
         title: 'Gerar Cobrança',
         subMenu: [
            {
               title: 'Boleto',
               route: '/payments/create/boleto',
            },
            {
               title: 'Links de Pagamento',
               route: '/payments/create/link-payments',
            },
            {
               title: 'Cartão de Crédito',
               route: '/payments/create/card',
            },
            {
               title: 'Carnê',
               route: '/payments/create/booklets-boleto',
            },
            {
               title: 'Recorrência',
               route: '/payments/create/recurrence',
            },
         ],
      },
      {
         title: 'Consultar',
         subMenu: [
            {
               title: 'Lista de operações',
               route: '/payments/all',
            },
            {
               title: 'Boleto',
               route: '/boletos',
            },
            {
               title: 'Cartão',
               route: '/payments/credit',
            },
            {
               title: 'Links de pagamento',
               route: '/link-payments',
            },
            {
               title: 'Carnês',
               route: '/booklets',
            },
            {
               title: 'Transferências',
               route: '/transfers/all',
            },
            {
               title: 'Pagamentos Futuros',
               route: '/receivables/all',
            },
            {
               title: 'Planos',
               route: '/plans',
            },
            {
               title: 'Assinaturas',
               route: '/subscriptions',
            },
         ],
      },
      {
         title: 'Contatos',
         subMenu: [
            {
               title: 'Novo Contato',
               route: '/customers/create',
            },
            {
               title: 'Consultar Contatos',
               route: '/customers',
            },
            {
               title: 'Novo Grupo de Contatos',
               route: '/groups/create',
            },
            {
               title: 'Consultar Grupos de Contatos',
               route: '/groups',
            },
         ],
      },
      {
         title: 'Pagamentos de contas',
         route: '/accounts-payments',
      },
      {
         title: 'Investimentos',
         route: '/investments',
      },
      user.role != 'SIMPLE' && {
         title: 'Contas',
         subMenu: [
            {
               title: 'Usuários',
               route: '/users',
            },
            user.role == 'MASTER' && {
               title: 'Estabelecimentos PF/PJ',
               route: '/establishment',
            },
         ].filter((i) => !!i),
      },
      {
         title: 'Configurações',
         subMenu: [
            {
               title: 'Minha conta',
               route: '/my-account',
            },
            {
               title: 'Mudar senha',
               route: '/change-password',
            },
            {
               title: 'Notificações de pagamentos',
               route: '/sms',
            },
            {
               title: 'Limites e Taxas',
               route: '/limits-fees',
            },
            {
               title: 'Alterar Conta Bancária',
               route: '/bank-accounts/create',
            },
            {
               title: 'Meios de recebimento',
               route: '/bank-accounts',
            },
         ],
      },
   ].filter((i) => !!i);

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerPageSelectState);

   //
   const dropRef = useRef(null);
   const [menuOpen, setMenuOpen] = useState(false);

   useClickOutside(() => {
      setMenuOpen(false);
   }, dropRef);

   return (
      <Style>
         <div className="seller-setting">
            <div className="logo">
               <Link to="/">
                  <img src={LogoHeader} alt="xpag" />
               </Link>
            </div>
            <div className="flex-1">
               <SellerSelect />
            </div>
            <div>
               <div className="user-box" ref={dropRef}>
                  <div className="d-flex align-items-center username-info" onClick={() => setMenuOpen(true)}>
                     <div>
                        <UserAvatar user={user} sizeCircle={28} />
                     </div>
                     <div className="pl-2">
                        <div className="username">
                           Olá, <b>{user.name}</b>
                        </div>
                     </div>
                     <div>
                        <svg
                           height="20"
                           width="20"
                           viewBox="0 0 20 20"
                           aria-hidden="true"
                           focusable="false"
                           fill="white"
                           className="css-6q0nyr-Svg"
                        >
                           <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                        </svg>
                     </div>
                  </div>
                  {menuOpen && (
                     <div className="dropdown-user">
                        <div>
                           <UserAvatar user={user} sizeCircle={42} />
                           <br />
                           <br />
                        </div>
                        <div className="pl-2">
                           <div className="username2">{user.name}</div>
                        </div>
                        <div className="pl-2">
                           <div className="email">{user.email}</div>
                        </div>
                        <div className="my-perfil">
                           <b>
                              <Link onClick={() => setMenuOpen(false)} to="/my-account">
                                 Meu Perfil
                              </Link>
                           </b>
                        </div>

                        <div className="logout">
                           <b>
                              <Link onClick={() => setMenuOpen(false)} to="/logout">
                                 Sair
                              </Link>
                           </b>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         {!!sellerSelect && (
            <div className="seller-info-box">
               <div className="seller-info">
                  <div className="descrip">Você está no estabelecimento:</div>
                  <div className="name">
                     <b>{sellerSelect.name}</b>
                  </div>
                  <div className="cnpj">
                     <b>{sellerSelect.document.length > 11 ? 'CNPJ' : 'CPF'}:</b>{' '}
                     {CNPJAndCPFMask(sellerSelect.document)} | <b>ID:</b> {sellerSelect.id}
                  </div>
               </div>
               <div className="balance">
                  <div className="balance-box">
                     <div className="col">
                        <div className="title">Saldo disponível:</div>
                        <div className="price text-success">
                           <b>{formatPrice((sellerSelect.current_balance ?? 0) * 100)}</b>
                        </div>
                     </div>
                     <div className="col">
                        <div className="title">Saldo Agendado:</div>
                        <div className="price text-orange">
                           <b>{formatPrice(0)}</b>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         )}

         <div className="menu">
            <Menu items={menuOptions} />
         </div>
      </Style>
   );
}

const Menu: any = ({ items }: any) => {
   const [activeIndex, setActiveIndex] = useState<number | null>(0);
   const ref = useRef<any>(null);
   const handleMouseEnter = (index: number) => {
      clearTimeout(ref.current);
      setActiveIndex(index);
   };

   const handleMouseLeave = () => {
      ref.current = setTimeout(() => {
         setActiveIndex(null);
      }, 300);
   };

   return (
      <div className="menu">
         {items.map((item: any, index: any) => (
            <div
               key={index}
               className="menu-item"
               onMouseEnter={() => handleMouseEnter(index)}
               onMouseLeave={handleMouseLeave}
            >
               {!!item.subMenu && <div className="menu-title">{item.title}</div>}
               {!item.subMenu && (
                  <Link to={item.route} className="menu-title">
                     {item.title}
                  </Link>
               )}
               {activeIndex === index && !!item.subMenu && (
                  <div className="menu-links">
                     {item.subMenu.map((link: any, linkIndex: any) => (
                        <Link to={link.route} className="sub-links">
                           {link.title}
                        </Link>
                     ))}
                  </div>
               )}
            </div>
         ))}
      </div>
   );
};
