import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   APIServiceUrl,
   DataDashResolve,
   GenerateExcel,
   ReceivablesSearchByDayResolve,
   ReceivablesSearchResolve,
   TransfersSearchResolve,
} from '~/api';
import { useUser } from '~/hooks';
import {sellerSelectState, sellerShowInfoState} from '~/recoil/seller-select.state';
import {useViewList} from "~/hooks/useViewList";

export function usePaymentsListingPage(): any {
   document.title = 'Pagamentos futuros - XPAG Tecnologia';
   const location = useLocation();
   const [showSeller] = useRecoilState(sellerShowInfoState);

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnSearch,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      registerOnAfterSearch,
      setFilters,
      setOffset,
      setPage,
      filters,
   } = useViewList({
      resolves: {
         items: ReceivablesSearchByDayResolve,
      },
      filtersDefault: {
         payment_type: location.pathname === '/payments/credit' ? 'credit' : '',
      },
   });

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [sum, setSum] = useState(0);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();

   useLayoutEffect(() => {
      return registerOnAfterSearch((v: any) => {
         setSum(v.sum);
      });
   }, []);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      // window.scrollTo(0, 0);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   /**
    * Seller Select
    */
   const user = useUser();
   const userRole = user.role;

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      reloadPage(true);
   }, [sellerSelect]);

   const [loading, setLoading] = useState(false);

   const filtersData = {
      types: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: 'credit',
            name: 'Cartão de crédito',
         },
         {
            id: 'boleto',
            name: 'Boleto',
         },
      ],
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'succeeded', name: 'Aprovada' },
         { id: 'canceled', name: 'Cancelada' },
         { id: 'failed', name: 'Falhada' },
         { id: 'pending', name: 'Pendente' },
      ],
   };

   function onSubmit(data: any): void {
      const params = data;
      params.expected_date_gte = data?.expected_date_gte?.dateYYYYMMDD;
      params.expected_date_lte = data?.expected_date_lte?.dateYYYYMMDD;

      setSum(0);
      setFilters(() => params);
   }

   function generateExcel(): void {
      setLoading(true);
      if (selected.size) {
         GenerateExcel({ ids: Array.from(selected.keys()) }, 'transfers').subscribe(
            (link) => {
               onUnselectAll();
               setLoading(false);
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               onUnselectAll();
               setLoading(false);
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcel(filters, 'transfers').subscribe(
            (link) => {
               onUnselectAll();
               setLoading(false);
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               onUnselectAll();
               setLoading(false);
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
   }

   return {
      onSubmit,
      formRef,
      reloadPage,
      starting,
      searching,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      sum,
      loading,
      generateExcel,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
      userRole,
      showSeller,
   };
}
