import React from 'react';
import './boostrap.scss';
import 'react-credit-cards/es/styles-compiled.css';
import MainStyle from './main.style';

export function GlobalStyles(): any {
   return (
      <>
         <MainStyle />
      </>
   );
}
