import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Form } from '@unform/web';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import { Input, Button, LoaderIcon } from '@devesharp/react/web';
import * as S from './LoginScreen.style';
import { Logo } from '~/assets/images';
import { useLoginPage } from '~/pages/Login/LoginPage.controller';
import { actionSetAuth } from '~/redux/modules/auth';

export const LoginScreen: React.FunctionComponent<any> = ({ ...rest }: any) => {
   return (
      <S.Container>
         <div className="login-page--container">
            <div className="login-page--container--inner">
               <div className="logos">
                  <div>
                     <img src={Logo} className="logo" />
                  </div>
               </div>
               <div>
                  {!!rest.error && (
                     <div className="text-center">
                        <h4>Erro ao conectar com o servidor, verifique sua internet e tente novamente!</h4>
                        <small>Se o problema persistir entre em contato o suporte.</small>
                        <br />
                        <br />
                        <Button onClick={rest.onRetry}>Tentar novamente</Button>
                     </div>
                  )}
                  {!rest.error && (
                     <div className="icon-loading">
                        <LoaderIcon size={48} />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </S.Container>
   );
};
