import React, { useEffect, useRef, useState } from 'react';
import { Tabs, useView, useDidUpdateEffect, useDidUpdateDeepCompareLayoutEffect } from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { DataDashResolve, PaymentsGetResolve, PaymentsSearchResolve, TransfersGetResolve } from '~/api';
import { formatDate } from '~/services';

export function usePaymentsDetailPage(): any {
   const { id } = useParams() as any;

   const { starting, errorLoadData, reloadPage, registerResolveParams, registerOnInit } = useView({
      resolves: {
         resource: TransfersGetResolve,
      },
   });

   const [resource, setResource] = useState<any>();
   const formRef = useRef<FormHandles>();

   document.title = `Detalhes da venda (${formatDate(resource?.created_at)}) - XPAG Tecnologia`;

   registerResolveParams('resource', () => id);

   registerOnInit((res: any) => {
      // colocar original_amount = amount pois mostra saldo da conta o inves do valor "original"
      if (res.resource.payment_type == 'commission') {
         res.resource.original_amount = res.resource.amount;
      }
      setResource(res.resource);
   });

   // useDidUpdateEffect(() => {
   //    // setPage(currentPage);
   // }, [currentPage]);

   return {
      starting,
      errorLoadData,
      resource,
   };
}
