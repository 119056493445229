import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AppProvider, ToastList } from '@devesharp/react/web';
import { RecoilRoot } from 'recoil';
import history from '~/config/history';
import { ThemeVariables } from '~/config/theme';
import { Routes } from '~/routes/Routes';
import { store, persistor } from '~/redux/store';
import { GlobalStyles } from '~/styles';
import { PageErrorImage, PageNotFoundImage } from '~/assets/images';
import RecoilNexus from "recoil-nexus";
import {ModalDownload} from "~/components/ModalDownload";

function App(): JSX.Element {
   return (
      <RecoilRoot>
         <RecoilNexus />
         <Provider store={store}>
            <PersistGate persistor={persistor}>
               <ThemeProvider theme={ThemeVariables}>
                  <AppProvider
                     initialData={{ history, imagePageError: PageErrorImage, imageNotFound: PageNotFoundImage }}
                  >
                     <ModalDownload />
                     <Router history={history}>
                        <Routes />
                     </Router>
                     <ToastList />
                  </AppProvider>
                  <GlobalStyles />
               </ThemeProvider>
            </PersistGate>
         </Provider>
      </RecoilRoot>
   );
}

export default App;
