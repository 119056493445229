import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// Vendedor selecionado na página
export const sellerPageSelectState = atom<any>({
   key: '@xpag/seller-page-select',
   default: null,
   effects_UNSTABLE: [persistAtom],
});

// Vendedor selecionado para ações
export const sellerSelectState = atom<any>({
   key: '@xpag/seller-select',
   default: null,
   effects_UNSTABLE: [persistAtom],
});

// Mostrar informações do seller nas listagens
export const sellerShowInfoState = atom<any>({
   key: '@xpag/show-seller-info',
   default: true,
   effects_UNSTABLE: [persistAtom],
});

// Usuário de seller único
export const uniqueSellerUserState = atom<any>({
   key: '@xpag/unique-seller-user',
   default: true,
   effects_UNSTABLE: [persistAtom],
});
