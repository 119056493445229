import React, { InputHTMLAttributes, useImperativeHandle, useRef, useState } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoaderIcon } from '@devesharp/react/web';
import { ISelectProps } from './Select.interface';
import * as S from './Select.style';
import { FormStyle } from './Select.style';
import { convertToJSON, convertToString, SelectCore } from './SelectCore';

export const SelectPure = React.forwardRef<InputHTMLAttributes<any>, ISelectProps>(function SelectPure(
   { value, onChange, options, title, defaultValue = null, error, color, loading = false, children, as, ...rest },
   inputRef: any,
) {
   const ref = useRef<any>();
   useImperativeHandle(inputRef, () => ref.current, []);

   const [focus, setFocus] = useState(false);

   return (
      <FormStyle className="select-input-container">
         <S.Container color={color}>
            {title && <div className={`input-title ${focus && 'focus'}`}>{title}</div>}

            <label className="select-box">
               <select
                  {...rest}
                  defaultValue={defaultValue ? convertToJSON(defaultValue) : undefined}
                  ref={ref}
                  value={value ? convertToJSON(value) : undefined}
                  onChange={(event) => {
                     if (onChange) {
                        onChange(convertToString(event.target.value));
                     }
                  }}
                  className={error && 'has-error'}
                  onFocus={(ev) => {
                     if (rest.onFocus) rest.onFocus(ev);
                     setFocus(true);
                  }}
                  onBlur={(ev) => {
                     if (rest.onBlur) rest.onBlur(ev);
                     setFocus(false);
                  }}
               >
                  {!options && children}
                  {options &&
                     options.map((i, index) => {
                        return (
                           <option
                              value={convertToJSON(i.value)}
                              key={convertToJSON(i.value)}
                              disabled={i.disabled}
                              selected={index == 0}
                           >
                              {i.name}
                           </option>
                        );
                     })}
               </select>

               {loading && (
                  <div className="suffix-loading">
                     <LoaderIcon size={18} />
                  </div>
               )}

               <div className="select-icon">
                  <FontAwesomeIcon icon={faAngleDown} />
               </div>
            </label>

            {error && (
               <div className="error-input" data-testid="input-error">
                  {error}
               </div>
            )}
         </S.Container>
      </FormStyle>
   );
});

export const Select = React.forwardRef<InputHTMLAttributes<any>, ISelectProps>(function Checkbox(props, inputRef: any) {
   const ref = useRef<any>();
   useImperativeHandle(inputRef, () => ref.current, []);

   return <SelectCore {...props} ref={ref} as={SelectPure} />;
});
