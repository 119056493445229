import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, Radio, Textarea, Datepicker } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { faBarcode, faCreditCard, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style, { ProgressColor } from './PaymentsCreateStep1.style';
import { usePaymentsCreateStep1 } from './PaymentsCreateStep1.controller';

export const PaymentsCreateStep1: React.FunctionComponent<any> = ({ onSubmit, initialType, onChangeType }: any) => {
   const ctrl = usePaymentsCreateStep1({ onSubmit, initialType, onChangeType });

   return (
      <Style>
         <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.initialData}>
            <Card className="p-n">
               <div className="form-padding ">
                  <div className="form-title">Dados da venda</div>

                  <br />

                  <div className="row field-box">
                     <div className="col-md-4">
                        <Input
                           name="amount"
                           title="Valor da venda"
                           number
                           placeholder="0,00"
                           decimals={2}
                           prefix="R$ "
                           textRight
                        />
                     </div>
                  </div>

                  <div className="row field-box">
                     <div className="col">
                        <Textarea
                           name="description"
                           title={ctrl.typePayment == 'boleto' ? 'Instruções' : 'Descrição'}
                           placeholder={
                              ctrl.typePayment == 'boleto'
                                 ? 'Escreva instruções para seu boleto'
                                 : 'Escreve um descrição para essa venda'
                           }
                        />
                     </div>
                  </div>

                  {/*<Radio*/}
                  {/*   name="type"*/}
                  {/*   options={[*/}
                  {/*      { id: 'boleto', label: 'Boleto' },*/}
                  {/*      { id: 'credit', label: 'Cartão crédito' },*/}
                  {/*      { id: 'payment_link', label: 'Link de pagamento' },*/}
                  {/*   ]}*/}
                  {/*   onChange={ctrl.onChangeType}*/}
                  {/*/>*/}
               </div>

               {ctrl.typePayment == 'boleto' && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faBarcode} />
                           </span>{' '}
                           Dados do Boleto
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Datepicker name="expiration_date" title="Vencimento da Primeira Parcela" />
                           </div>

                           <div className="col-md-6" />
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input
                                 name="interest_percent"
                                 title="Multa em Porcentagem (Máximo 2%)"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 suffix="%"
                                 textRight
                              />
                           </div>

                           <div className="col-md-6">
                              <Input
                                 name="fine_percent"
                                 title="Juros diário (Máximo 3,3%)"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 suffix="%"
                                 textRight
                              />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input
                                 name="discount_value"
                                 title="Valor do desconto"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 prefix="R$ "
                                 textRight
                              />
                           </div>

                           <div className="col-md-6">
                              <Datepicker
                                 name="discount_date"
                                 title="Vencimento da desconto"
                                 placeholder="dd/mm/aaaa"
                              />
                           </div>
                        </div>

                        {/*<Radio*/}
                        {/*   name="bookletEnabled"*/}
                        {/*   options={[*/}
                        {/*      { id: 'unique', label: 'Boleto único' },*/}
                        {/*      { id: 'booklet', label: 'Carnê' },*/}
                        {/*   ]}*/}
                        {/*   onChange={ctrl.onChangeBooklet}*/}
                        {/*/>*/}
                     </div>
                  </>
               )}

               {ctrl.typePayment == 'credit' && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faCreditCard} />
                           </span>{' '}
                           Dados do Cartão
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-8">
                              <Input name="card.holder_name" title="Nome no cartão" />
                           </div>

                           <div className="col-md-6" />
                        </div>

                        <div className="row field-box">
                           <div className="col-md-8">
                              <Input
                                 name="card.card_number"
                                 title="Número do cartão"
                                 placeholder="xxxx xxxx xxxx xxxx"
                                 maxLength={16}
                              />
                           </div>

                           <div className="col-md-6" />
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <div className="input-title false">Vencimento</div>
                              <div className="row">
                                 <div className="col">
                                    <Select name="card.expiration_month" items={ctrl.formInfoData.expiration_month} />
                                 </div>
                                 <div className="col">
                                    <Select name="card.expiration_year" items={ctrl.formInfoData.expiration_year} />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-4">
                              <Input name="card.security_code" title="CVV" maxLength={3} placeholder="xxx" />
                           </div>
                        </div>
                     </div>
                  </>
               )}

               {ctrl.booklet && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faTicketAlt} />
                           </span>{' '}
                           Dados do Carnê
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-8">
                              <Input name="booklet.name" title="Nome do Carnê" />
                           </div>

                           <div className="col-md-6" />
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="booklet.installments" title="Numero de parcelas" />
                           </div>

                           <div className="col-md-6">
                              <Select
                                 name="booklet.installments_interval"
                                 title="Intervalos do Carnê"
                                 items={ctrl.formInfoData.installments}
                              />
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </Card>
            <div className="text-right pt-2">
               <Button size="lg" type="submit">
                  <b>Avançar</b>
               </Button>
            </div>
            <br />
            <br />
         </Form>
      </Style>
   );
};
