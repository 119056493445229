import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Http } from '@devesharp/react/web/index';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import {AuthAPI, AuthPasswordRecoveryAPI, AuthRecoveryPasswordCheck, AuthResetPasswordAPI} from '~/api';
import { actionSetAuth } from '~/redux/modules/auth';
import { actionUpdateUser } from '~/redux/modules/user';
import {
   sellerPageSelectState,
   sellerSelectState,
   sellerShowInfoState,
   uniqueSellerUserState,
} from '~/recoil/seller-select.state';

export function useRecoveryPasswordPage(): any {
   const { token } = useParams() as any;
   const [logging, setLogging] = useState(false);
   const [dashInit, setDashInit] = useState(false);
   const [error, setError] = useState('');
   const [success, setSuccess] = useState(false);
   const [start, setStart] = useState(false);
   const [find, setFind] = useState(false);

   document.title = 'Login - XPAG Tecnologia';

   const store = useSelector<any>((store: any) => store);
   const dispatch = useDispatch();

   const [, setSellerPageSelect] = useRecoilState(sellerPageSelectState);
   const [, setSellerSelect] = useRecoilState(sellerSelectState);
   const [, setShowInfoSeller] = useRecoilState(sellerShowInfoState);
   const [, setUniqueSeller] = useRecoilState(uniqueSellerUserState);

   useEffect(() => {
      AuthRecoveryPasswordCheck({
         remember_token: token,
      }).subscribe(
         (e) => {
            setFind(true);
            setStart(true);
         },
         () => {
            setStart(true);
         },
      );
   }, []);

   function onSubmit(data: any): void {
      setLogging(true);

      if (!data.new_password) {
         setError('Informe a nova senha');
         return;
      }

      if (!data.re_new_password) {
         setError('Informe a confirmação da senha');
         return;
      }
      
      if (data.new_password != data.re_new_password) {
         setError('As senhas não conferem');
         return;
      }

      AuthResetPasswordAPI({
         remember_token: token,
         new_password: data.new_password,
      }).subscribe(
         (response: any) => {
            setSuccess(true);
            // const { info, user } = response.data;
            // Http.defaults.headers.Authorization = `Bearer ${user.access_token}`;
            // dispatch(actionSetAuth(true, user.access_token));
            // dispatch(actionUpdateUser(user));
            // setDashInit(true);
         },
         (err: any) => {
            setError(err?.detail?.description || err?.data?.error);
            setLogging(false);
         },
      );
   }

   return {
      find,
      start,
      success,
      dashInit,
      logging,
      error,
      onSubmit,
   };
}
