import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useRecoilState } from 'recoil';
import {
   APIServiceUrl,
   PlansManyUpdateResolve,
   PlansSearchResolve,
   GenerateExcel,
   GenerateExcelPlans,
   GroupsSearchResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { useUser } from '~/hooks';
import { sellerSelectState } from '~/recoil/seller-select.state';

export function usePlansListingPage(): any {
   document.title = 'Planos - XPAG Tecnologia';

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      setFilters,
      setOffset,
      setPage,
      setSort,
      filters,
   } = useViewList({
      resolves: {
         items: PlansSearchResolve,
         groups: GroupsSearchResolve,
      },
      filtersDefault: {},
   });

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [groups, setGroups] = useState<any[]>([]);
   const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();
   const formRefGroup = useRef<FormHandles>();

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }
   });

   /**
    * Seller Select
    */
   const user = useUser();
   const userRole = user.role;

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      reloadPage(true);
   }, [sellerSelect]);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      onUnselectAll();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   const filtersData = {
      groups: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: -1,
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      groupsChange: [
         {
            id: -1,
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'succeeded', name: 'Pago' },
         { id: 'pending', name: 'Pendente' },
         { id: 'expired', name: 'Expirado' },
      ],
   };

   function generateExcel(): void {
      if (selected.size) {
         GenerateExcelPlans({ ids: Array.from(selected.keys()) }).subscribe(
            (link) => {
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcelPlans(filters).subscribe(
            (link) => {
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
   }

   function onSubmit(data: any) {
      const params = data;
      params.created_at_gte = data?.created_at_gte?.dateYYYYMMDD;
      params.created_at_lte = data?.created_at_lte?.dateYYYYMMDD;

      setFilters(() => params);
   }

   const page = filters.page ?? 1;
   let pageCountStart = (page - 1) * 20 + 1;
   pageCountStart = resourcesTotal ? pageCountStart : 0;
   let pageCountEnd = (page - 1) * 20 + 20;
   pageCountEnd = pageCountEnd > resourcesTotal ? resourcesTotal : pageCountEnd;

   const [groupModalShow, setGroupModalShow] = useState(false);

   function changePlanGroup(): void {
      PlansManyUpdateResolve({
         ...formRefGroup.current?.getData(),
         ids: Array.from(selected.keys()),
      }).subscribe(
         () => {
            onUnselectAll();
            setGroupModalShow(false);
            reloadPage(false);
         },
         () => {
            // onUnselectAll();
            setGroupModalShow(false);
            ToastList.show({
               message: 'Erro ao mudar grupos de usuários',
               type: 'error',
            });
         },
      );
   }

   return {
      onSubmit,
      setSort,
      formRef,
      formRefGroup,
      changePlanGroup,
      groupModalShow,
      setGroupModalShow,
      reloadPage,
      starting,
      searching,
      loading,
      pageCountStart,
      pageCountEnd,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      generateExcel,
      filtersData,
      currentPage,
      setCurrentPage,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
      userRole,
   };
}
