import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsSearchResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation, OnlyNumbers, searchCEP } from '~/services';
import history from '~/config/history';
import { sellerSelectState } from '~/recoil/seller-select.state';

const yupValidateSchema = Yup.object().shape({
   first_name: Yup.string().when('person_type', {
      is: '1',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   last_name: Yup.string().when('person_type', {
      is: '1',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   business_name: Yup.string().when('person_type', {
      is: '2',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   taxpayer_id: Yup.string()
      .required('Esse campo é obrigatório!')
      .when('person_type', {
         is: '2',
         then: Yup.string().test('cpf-length', 'Digite o CNPJ corretamente', (val): any => {
            const newVal = val?.replace(/\D/g, '');

            return newVal?.length === 14;
         }),
      })
      .when('person_type', {
         is: '1',
         then: Yup.string().test('cpf-valid', 'Digite um CPF válido', CPFValidation),
      }),
   phone_number: Yup.string()
      .required('Esse campo é obrigatório!')
      .test('length', 'Digite o celular corretamente', (val): any => {
         const newVal = val?.replace(/\D/g, '');

         return newVal?.length === 10 || newVal?.length === 11;
      }),
   email: Yup.string().email('Email Inválido!').required('Esse campo é obrigatório!'),
   // socialName: Yup.string().when('person_type', {
   //    is: 2,
   //    then: Yup.string().required('Esse campo é obrigatório!'),
   // }),
   address_postal_code: Yup.string()
      .required('Esse campo é obrigatório!')
      .test('length', 'Digite o CEP corretamente', (val): any => {
         const newVal = val?.replace(/\D/g, '');

         return newVal?.length === 8;
      }),
   address_address: Yup.string().required('Esse campo é obrigatório!'),
   address_number: Yup.string().required('Esse campo é obrigatório!'),
   address_neighborhood: Yup.string().required('Esse campo é obrigatório!'),
   address_city: Yup.string().required('Esse campo é obrigatório!'),
   address_state: Yup.string().required('Esse campo é obrigatório!'),
});

export function useCustomersCreatePage(): any {
   const { id } = useParams<any>();

   document.title = 'Novo cliente - XPAG Tecnologia';
   const [isLoadingCep, setIsLoadingCep] = useState(false);
   const ref = useRef('');

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      yupValidateSchema,
      resolves: {
         groups: GroupsSearchResolve,
      },
      resolveGet: CustomersGetResolve,
      resolveCreate: CustomersCreateResolve,
      resolveUpdate: (params) => CustomersUpdateResolve(id, params),
      // handleFormData: (formData: any) => {
      //    const newForm = { ...formData };
      //
      //    return newForm;
      // },
      onFailed() {
         ToastList.show({
            message: 'Erro ao adicionar cliente',
            type: 'error',
         });
      },
      onSuccess(creating) {
         if (creating) {
            ToastList.show({
               message: 'Cliente adicionado com sucesso',
               type: 'success',
            });
            history.push('/customers');
         } else {
            ToastList.show({
               message: 'Cliente editado com sucesso',
               type: 'success',
            });
            history.push('/customers');
         }
      },
   });

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);

   const [resource, setResource] = useState<any>();
   const [groups, setGroups] = useState<any[]>([]);
   const [personType, setPersonType] = useState(1);

   // console.log(groups)
   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }

      if (resources.resource) {
         const resource2 = { ...resources.resource };
         // if (resource2.group_id) {
         //    resource2.group_id = '1';
         // }

         setResource(resource2);
         setPersonType(resource2.person_type);
         formRef.current.setData(resource2);
      }
   });

   useEffect(() => {
      // console.log(resource);
      // console.log(resource?.group_id?.label);
      // console.log('sdsd');
      // formRef.current.setFieldValue('group_id', '1');
   }, [groups, resource]);

   const formInfoData = {
      groups: [
         {
            value: '',
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            value: group.id,
            name: group.name,
         })),
      ],
      person_type: [
         {
            value: 1,
            name: 'Pessoa física',
         },
         {
            value: 2,
            name: 'Pessoa júrica',
         },
      ],
      states: states.map((state) => ({ name: state.name, value: state.id })),
   };

   function onChangeCep(): any {
      const cep = OnlyNumbers(formRef.current.getFieldValue('address_postal_code'));
      if (cep.length == 8 && ref.current !== cep) {
         setIsLoadingCep(true);
         formRef.current.setFieldError('address_postal_code', '');
         searchCEP(cep)
            .then((e: any) => {
               setIsLoadingCep(false);
               const address = (formRef.current.getData() as any).address ?? {};
               formRef.current.setData({
                  ...formRef.current.getData(),
                  address_address: e.data.street,
                  address_city: e.data.city,
                  address_neighborhood: e.data.neighborhood,
                  address_state: e.data.state,
               });
            })
            .catch(() => {
               setIsLoadingCep(false);
               formRef.current.setFieldError('address_postal_code', 'CEP não encontrado');
            });
      }
      ref.current = cep;
   }

   // function searchCep() {
   //    searchCEP.then((e) => {
   //       console.log('e');
   //    });
   // }
   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function openCreateGroup() {
      history.push('/groups/create');
   }

   function onChangePersonType() {
      setPersonType(formRef.current.getFieldValue('person_type'));
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      openCreateGroup,
      onChangePersonType,
      id,
      editing: !!id,
      personType,
      formRef,
      reloadPage,
      starting,
      saving,
      isLoadingCep,
      onChangeCep,
      errorLoadData,
      formInfoData,
      sellerSelect,
   };
}
