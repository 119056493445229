import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';
import {useRecoilState} from "recoil";
import {sellerSelectState} from "~/recoil/seller-select.state";

const yupValidateSchema = Yup.object().shape({
   name: Yup.string().required('Esse campo é obrigatório!'),
});

export function useGroupsCreatePage(): any {
   const { id } = useParams<any>();

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   
   document.title = 'Crir grupo - XPAG Tecnologia';

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      // yupValidateSchema,
      resolves: {
         customers: (id ? CustomersSearchResolve : null) as any,
      },
      resolveGet: GroupsGetResolve,
      resolveCreate: GroupsCreateResolve,
      resolveUpdate: (params) => GroupsUpdateResolve(id, params),
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         newForm.group_id = formData?.group_id?.value ?? null;

         return newForm;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao adicionar grupo',
            type: 'error',
         });
      },
      onSuccess(creating) {
         if (creating) {
            ToastList.show({
               message: 'Grupo adicionado com sucesso',
               type: 'success',
            });
            history.push('/groups');
         } else {
            ToastList.show({
               message: 'Grupo editado com sucesso',
               type: 'success',
            });
            history.push('/groups');
         }
      },
   });

   useLayoutEffect(() => {
      registerResolveParams('customers', () => {
         return {
            group_id: id,
            limit: 100,
         };
      });
   }, [id]);

   const [customers, setCustomers] = useState([]);
   registerOnInit((resources: any) => {
      if (resources.resource) {
         const resource = { ...resources.resource };
         if (resource.group_id) {
            resource.group_id = { label: resource.group_id, value: resource.group_name };
         }
         formRef.current.setData(resource);
      }
      if (resources.customers) {
         setCustomers(resources.customers.results);
      }
   });

   const [loading, setLoading] = useState(false);

   function removeGroup(contactId: string): void {
      setLoading(true);
      CustomersUpdateResolve(contactId, {
         group_id: null,
      }).subscribe(
         () => {
            setLoading(false);
            CustomersSearchResolve({
               group_id: id,
               limit: 100,
            }).subscribe(
               (e) => {
                  setCustomers(e.results);
                  setLoading(false);
               },
               () => {
                  setLoading(false);
               },
            );
         },
         () => {
            setLoading(false);
         },
      );
   }

   return {
      onSubmit: onSubmitForm,
      id,
      customers,
      editing: !!id,
      removeGroup,
      formRef,
      reloadPage,
      starting,
      saving: saving || loading,
      errorLoadData,
      sellerSelect,
   };
}
