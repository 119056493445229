import React, { useEffect, useRef, useState } from 'react';
import dateFNS from 'date-fns';
import { convertStringToDate } from '@devesharp/react/web';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style from './PaymentType.style';
import { BrandMasterCard, BrandVisa, Boleto, BrandElo } from '~/assets/images';

export function TransferType({ transaction }: any): any {
   switch (transaction.type) {
      case 'transfer':
         return <Style>Transferência entre conta digital</Style>;
      case 'payout_automatic':
         return <Style>Transferência bancária automática</Style>;
      case 'payout_manual':
         return <Style>Transferência bancária manual</Style>;
      case 'default':
         return <Style>Outro</Style>;
   }

   return null;
}
