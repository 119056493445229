import React, { useEffect, useReducer, useState } from 'react';
import { Route as RouteDom, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HomePage } from '~/pages/Home';
import { NotfoundPage } from '~/routes/NotFound';
import {LoginPage} from '~/pages/Login';
import { Route } from '~/routes/Route';
import { LoginScreen } from '~/pages/LoginScreen';
import { useRoutes } from '~/routes/Routes.controller';
import { PaymentsListingPage } from '~/pages/PaymentsListing';
import { BoletosListingPage } from '~/pages/BoletosListing';
import { PaymentsDetailPage } from '~/pages/PaymentsDetail';
import { LinkPaymentsListingPage } from '~/pages/LinkPaymentsListing';
import { LinkPaymentsDetailPage } from '~/pages/LinkPaymentsDetail';
import { CustomersListingPage } from '~/pages/CustomersListing';
import { EstablishmentListingPage } from '~/pages/EstablishmentListing';
import { EstablishmentCreatePage } from '~/pages/EstablishmentCreate';
import { GroupsListingPage } from '~/pages/GroupsListing';
import { BanksAccountsListingPage } from '~/pages/BanksAccountsListing';
import { BookletsListingPage } from '~/pages/BookletsListing';
import { CustomersCreatePage } from '~/pages/CustomersCreate';
import { GroupsCreatePage } from '~/pages/GroupsCreate';
import { MyAccountPage } from '~/pages/MyAccount';
import { SMSMessagePage } from '~/pages/SMSMessage';
import { BanksAccountsCreatePage } from '~/pages/BanksAccountsCreate';
import { PaymentsCreatePage } from '~/pages/PaymentsCreate';
import { BookletsDetailPage } from '~/pages/BookletsDetail';
import { ComingSoon } from '~/pages/ComingSoon';
import { TransfersPage } from '~/pages/Transfers';
import { actionSetLogout } from '~/redux/modules/auth';
import { LinkPaymentsPayPage } from '~/pages/LinkPaymentsPay';
import { LimitsAndFeesPage } from '~/pages/LimitsAndFees';
import { ChangePasswordPage } from '~/pages/ChangePassword';
import { PaymentsCancelPage } from '~/pages/PaymentsCancel';
import { TransfersListingPage } from '~/pages/TransfersListing';
import { TransfersDetailPage } from '~/pages/TransfersDetail';
import { BoletoBBCreatePage } from '~/pages/BoletoBBCreate';
import { BoletosBBListingPage } from '~/pages/BoletosBBListing';
import { ReceivablesListingPage } from '~/pages/ReceivablesListing/ReceivablesListingPage';
import { ReceivablesDateDetailPage } from '~/pages/ReceivablesDateDetail/ReceivablesDateDetailPage';
import { UsersListingPage } from '~/pages/UsersListing';
import { UsersCreatePage } from '~/pages/UsersCreate';
import { PlansListingPage } from '~/pages/PlansListing';
import { SubscriptionsListingPage } from '~/pages/SubscriptionsListing';
import {ForgetPassword} from "~/pages/ForgetPassword";
import {RecoveryPassword} from "~/pages/RecoveryPassword";

export function Routes(): any {
   const routeController = useRoutes();
   const location = useLocation();

   if (location.pathname.search('link-payments/pay/') >= 0) {
      return (
         <Switch>
            <RouteDom path="/link-payments/pay/:id" exact render={(props) => <LinkPaymentsPayPage {...props} />} />
         </Switch>
      );
   }

   return (
      <>
         {routeController.transitions.map(
            ({ item, key, props }: any) =>
               item && (
                  <LoginScreen
                     key={key}
                     style={props}
                     onRetry={routeController.onRetry}
                     error={routeController.error}
                  />
               ),
         )}

         {routeController.started && (
            <Switch>
               <Route path="/" exact component={LoginPage} />
               <Route path="/login" exact component={LoginPage} />
               <Route path="/forget-password" exact component={ForgetPassword} />
               <Route path="/recovery-password/:token" exact component={RecoveryPassword} />
               <Route path="/home" exact component={HomePage} isPrivate />
               <Route path="/payments/all" exact component={PaymentsListingPage} isPrivate />
               <Route path="/payments/credit" exact component={() => <PaymentsListingPage />} isPrivate />
               <Route path="/payments/create" exact component={() => <PaymentsCreatePage />} isPrivate />
               <Route
                  path="/payments/create/boleto"
                  exact
                  component={() => <PaymentsCreatePage initialType="boleto" />}
                  isPrivate
               />
               <Route
                  path="/payments/create/link-payments"
                  exact
                  component={() => <PaymentsCreatePage initialType="payment_link" />}
                  isPrivate
               />
               <Route
                  path="/payments/create/card"
                  exact
                  component={() => <PaymentsCreatePage initialType="card" />}
                  isPrivate
               />
               <Route
                  path="/payments/create/booklets-boleto"
                  exact
                  component={() => <PaymentsCreatePage initialType="booklets_boleto" />}
                  isPrivate
               />
               <Route
                  path="/payments/create/recurrence"
                  exact
                  component={() => <PaymentsCreatePage initialType="recurrence" />}
                  isPrivate
               />
               <Route path="/payments/cancel/:id" exact component={PaymentsCancelPage} isPrivate />
               <Route path="/payments/detail/:id" exact component={PaymentsDetailPage} isPrivate />
               <Route path="/payments/bb/create" exact component={BoletoBBCreatePage} isPrivate />
               <Route path="/payments/bb/boletos" exact component={BoletosBBListingPage} isPrivate />
               <Route path="/transfers/all" exact component={TransfersListingPage} isPrivate />
               <Route path="/transfers/detail/:id" exact component={TransfersDetailPage} isPrivate />
               <Route path="/boletos" exact component={BoletosListingPage} isPrivate />
               <Route path="/link-payments" exact component={LinkPaymentsListingPage} isPrivate />
               <Route path="/link-payments/detail/:id" exact component={LinkPaymentsDetailPage} isPrivate />
               <Route path="/plans" exact component={PlansListingPage} isPrivate />
               <Route path="/subscriptions" exact component={SubscriptionsListingPage} isPrivate />
               <Route path="/users" exact component={UsersListingPage} isPrivate />
               <Route path="/users/create" exact component={UsersCreatePage} isPrivate />
               <Route path="/users/edit/:id" exact component={UsersCreatePage} isPrivate />
               <Route path="/establishment" exact component={EstablishmentListingPage} isPrivate />
               <Route path="/establishment/create" exact component={EstablishmentCreatePage} isPrivate />
               <Route path="/establishment/edit/:id" exact component={EstablishmentCreatePage} isPrivate />
               <Route path="/customers" exact component={CustomersListingPage} isPrivate />
               <Route path="/customers/create" exact component={CustomersCreatePage} isPrivate />
               <Route path="/customers/edit/:id" exact component={CustomersCreatePage} isPrivate />
               <Route path="/groups" exact component={GroupsListingPage} isPrivate />
               <Route path="/groups/create" exact component={GroupsCreatePage} isPrivate />
               <Route path="/groups/edit/:id" exact component={GroupsCreatePage} isPrivate />
               <Route path="/bank-accounts" exact component={BanksAccountsListingPage} isPrivate />
               <Route path="/bank-accounts/create" exact component={BanksAccountsCreatePage} isPrivate />
               <Route path="/booklets" exact component={BookletsListingPage} isPrivate />
               <Route path="/booklets/detail/:id" exact component={BookletsDetailPage} isPrivate />
               <Route path="/my-account" exact component={MyAccountPage} isPrivate />
               <Route path="/receivables/all" exact component={ReceivablesListingPage} isPrivate />
               <Route path="/receivables/date/:date" exact component={ReceivablesDateDetailPage} isPrivate />
               <Route path="/change-password" exact component={ChangePasswordPage} isPrivate />
               <Route path="/other" exact component={ComingSoon} isPrivate />
               <Route path="/accounts-payments" exact component={ComingSoon} isPrivate />
               <Route path="/investments" exact component={ComingSoon} isPrivate />
               <Route path="/em-breve" exact component={ComingSoon} isPrivate />
               <Route path="/em-breve" exact component={ComingSoon} isPrivate />
               <Route path="/sms" exact component={SMSMessagePage} isPrivate />
               <Route path="/transfers" exact component={TransfersPage} isPrivate />
               <Route path="/limits-fees" exact component={LimitsAndFeesPage} isPrivate />
               <Route path="/logout" exact component={Logout} isPrivate />
               <Route component={NotfoundPage} />
            </Switch>
         )}
      </>
   );
}

function Logout() {
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(actionSetLogout());
   }, []);

   return <></>;
}
