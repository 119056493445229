import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTransition } from 'react-spring';
import { AuthAPI, AuthMeAPI, AuthRefresh } from '~/api';
import { actionSetAuth, actionSetLogout } from '~/redux/modules/auth';
import { actionUpdateUser } from '~/redux/modules/user';
import { IAuthReducer } from '~/redux/modules/auth/auth.interface';

export function useRoutes(): any {
   const dispatch = useDispatch();
   const auth = useSelector((e: any) => e.auth) as IAuthReducer;

   const [started, setStarted] = useState(false);
   const [show, setShow] = useState(true);
   const [error, setError] = useState(false);
   const transitions = useTransition(show, null, {
      from: { opacity: 1 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
   });

   useEffect(() => {
      if (auth.isLogged) {
         checkUser();
      } else {
         setShow(false);
         setStarted(true);
      }
   }, []);

   function checkUser(): void {
      AuthMeAPI().subscribe(
         (response: any) => {
            setShow(false);

            setStarted(true);
            dispatch(actionUpdateUser(response));
         },
         (err: any) => {
            if (err?.data?.status_code == 401) {
               dispatch(actionSetLogout());
               setShow(false);
               setStarted(true);
            } else {
               setError(true);
            }
         },
      );
   }

   function onRetry(): void {
      setStarted(false);
      setShow(true);
      setError(false);

      setTimeout(() => checkUser(), 1200);
   }

   return {
      started,
      error,
      transitions,
      onRetry,
   };
}
