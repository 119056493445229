import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, Radio, Textarea, Datepicker, Checkbox } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { faBarcode, faCreditCard, faRetweet, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState } from 'recoil';
import { Select as Select2 } from '~/components/Customs/Select';
import Style, { ProgressColor } from './PaymentsCreateStep1.style';
import { usePaymentsCreateStep1 } from '~/pages/PaymentsCreate/Step1/PaymentsCreateStep1.controller';
import { formatPrice } from '~/services';
import { SellerSelectInput } from '~/components/SellerSelectInput';
import { sellerPageSelectState } from '~/recoil/seller-select.state';

export const PaymentsCreateStep1: React.FunctionComponent<any> = ({
   onSubmit,
   initialType,
   onChangeType,
   settings,
}: any) => {
   const ctrl = usePaymentsCreateStep1({ onSubmit, initialType, onChangeType, settings });

   const [sellerPageSelect] = useRecoilState(sellerPageSelectState);

   if (!sellerPageSelect) {
      return (
         <Style>
            <h4>Escolha um vendedor para poder fazer um pagamento</h4>
         </Style>
      );
   }

   return (
      <Style>
         <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.initialData}>
            <Card className="p-n">
               <div className="form-padding ">
                  <div className="form-title">Dados da venda</div>

                  <br />
                  <br />

                  <SellerSelectInput />

                  <div className="row field-box">
                     <div className="col-md-12">
                        <div className="input-title false pb-3">Tipo da venda</div>
                        <Radio
                           name="type"
                           options={[
                              { id: 'boleto', label: 'Boleto' },
                              { id: 'card', label: 'Cartão de crédito' },
                              { id: 'payment_link', label: 'Link de pagamento' },
                              { id: 'booklets_boleto', label: 'Carnê' },
                              { id: 'recurrence', label: 'Recorrência' },
                           ]}
                           onChange={ctrl.onChangeType}
                        />
                     </div>
                  </div>

                  {ctrl.typePayment == 'booklets_boleto' && (
                     <div className="row field-box">
                        <div className="col-md-8">
                           <Input name="booklet.name" title="Nome do Carnê" />
                        </div>

                        <div className="col-md-6" />
                     </div>
                  )}

                  <div className="row field-box">
                     <div className="col-md-6">
                        <Input
                           name="amount"
                           title={ctrl.typePayment == 'booklets_boleto' ? 'Valor das parcelas' : 'Valor da venda'}
                           number
                           placeholder="0,00"
                           decimals={2}
                           prefix="R$ "
                           textRight
                           onInput={ctrl.onChangeAmount}
                        />
                        {ctrl.typePayment == 'booklets_boleto' && (
                           <div className="pt-2">
                              Valor total: <b>{formatPrice(ctrl.totalAmount)}</b>
                           </div>
                        )}
                     </div>
                     {ctrl.typePayment == 'booklets_boleto' && (
                        <div className="col-md-3">
                           <Input
                              name="booklet.installments"
                              title="Número de parcelas"
                              placeHolder={2}
                              onInput={ctrl.onChangeInstallments}
                           />
                        </div>
                     )}
                  </div>

                  {ctrl.typePayment == 'booklets_boleto' && (
                     <div className="row field-box">
                        <div className="col-md-6">
                           <Select
                              name="booklet.installments_interval"
                              title="Intervalos do Carnê"
                              items={ctrl.formInfoData.installments}
                           />
                        </div>
                     </div>
                  )}

                  {ctrl.typePayment == 'payment_link' && (
                     <div className="row field-box">
                        <div className="col-md-6">
                           <Select
                              name="installments"
                              title="Parcelar em"
                              items={[
                                 {
                                    id: '1',
                                    name: '1x',
                                 },
                                 {
                                    id: '2',
                                    name: '2x',
                                 },
                                 {
                                    id: '3',
                                    name: '3x',
                                 },
                                 { id: '4', name: '4x' },
                                 { id: '5', name: '5x' },
                                 { id: '6', name: '6x' },
                                 { id: '7', name: '7x' },
                                 { id: '8', name: '8x' },
                                 { id: '9', name: '9x' },
                                 { id: '10', name: '10x' },
                                 { id: '11', name: '11x' },
                                 { id: '12', name: '12x' },
                              ]}
                           />
                        </div>
                     </div>
                  )}

                  {ctrl.typePayment != 'recurrence' && (
                     <div className="row field-box">
                        <div className="col">
                           <Textarea
                              name="description"
                              title={
                                 ctrl.typePayment == 'boleto' || ctrl.typePayment == 'booklets_boleto'
                                    ? 'Instruções'
                                    : 'Descrição'
                              }
                              placeholder={
                                 ctrl.typePayment == 'boleto' || ctrl.typePayment == 'booklets_boleto'
                                    ? 'Escreva instruções para seu boleto'
                                    : 'Escreva um descrição para essa venda'
                              }
                           />
                        </div>
                     </div>
                  )}
               </div>

               {(ctrl.typePayment == 'boleto' || ctrl.typePayment == 'booklets_boleto') && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faBarcode} />
                           </span>{' '}
                           Dados do Boleto
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Datepicker name="expiration_date" title="Vencimento da Primeira Parcela" />
                           </div>

                           <div className="col-md-6" />
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input
                                 name="fine_percent"
                                 title="Multa em Porcentagem (Máximo 2%)"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 suffix="%"
                                 textRight
                              />
                           </div>

                           <div className="col-md-6">
                              <Input
                                 name="interest_percent"
                                 title="Juros diário (Máximo 3,3%)"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 suffix="%"
                                 textRight
                              />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input
                                 name="discount_value"
                                 title="Valor do desconto"
                                 number
                                 decimals={2}
                                 placeholder="0,00"
                                 prefix="R$ "
                                 textRight
                              />
                           </div>

                           <div className="col-md-6">
                              <Datepicker
                                 name="discount_date"
                                 title="Vencimento da desconto"
                                 placeholder="dd/mm/aaaa"
                              />
                           </div>
                        </div>

                        <div className="input-title false">Notificações por email</div>
                        <br />

                        <div className="row field-box">
                           <div className="col">
                              <Checkbox name="EMAIL_SEND_ON_CREATE_BOLETO" title="Enviar email ao criar boleto" />
                           </div>
                        </div>
                        <div className="row field-box">
                           <div className="col">
                              <Checkbox
                                 name="EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO"
                                 title="Enviar emails
                            2 dias antes do vencimento"
                              />
                           </div>
                        </div>
                        <div className="row field-box">
                           <div className="col">
                              <Checkbox
                                 name="EMAIL_SEND_ON_DAY_EXPIRED_BOLETO"
                                 title="Enviar emails
                            no dia do vencimento"
                              />
                           </div>
                        </div>
                        <div className="row field-box">
                           <div className="col">
                              <Checkbox
                                 name="EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO"
                                 title="Enviar emails
                            um dia após o vencimento"
                              />
                           </div>
                        </div>
                        <br />
                     </div>
                  </>
               )}

               {(ctrl.typePayment == 'card' || ctrl.typePayment == 'recurrence') && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faCreditCard} />
                           </span>{' '}
                           Dados do Cartão
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="card.holder_name" title="Nome no cartão" />
                           </div>

                           <div className="col-md-6">
                              <Input
                                 name="card.card_number"
                                 title="Número do cartão"
                                 placeholder="xxxx xxxx xxxx xxxx"
                                 maxLength={16}
                              />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <div className="input-title false">Vencimento</div>
                              <div className="row">
                                 <div className="col">
                                    <Select name="card.expiration_month" items={ctrl.formInfoData.expiration_month} />
                                 </div>
                                 <div className="col">
                                    <Select name="card.expiration_year" items={ctrl.formInfoData.expiration_year} />
                                 </div>
                              </div>
                           </div>

                           <div className="col-md-2">
                              <Input name="card.security_code" title="CVV" maxLength={3} placeholder="xxx" />
                           </div>
                           {ctrl.typePayment == 'card' && (
                              <div className="col-md-2">
                                 <div className="input-title false">Parcelar</div>
                                 <Select name="installments" items={ctrl.formInfoData.installmentsCard} />
                              </div>
                           )}
                        </div>
                     </div>
                  </>
               )}

               {ctrl.typePayment == 'recurrence' && (
                  <>
                     <hr />
                     <div className="form-padding ">
                        <div className="form-title">
                           <span className="text-primary">
                              <FontAwesomeIcon icon={faRetweet} />
                           </span>{' '}
                           Dados do Recorrência
                        </div>

                        <br />

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Input name="recurrence.plan_name" title="Nome do Plano" />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Datepicker
                                 name="recurrence.due_date"
                                 title="Data da primeira cobrança"
                                 placeholder="dd/mm/aaaa"
                              />
                           </div>
                        </div>

                        <div className="row field-box">
                           <div className="col-md-6">
                              <Select2
                                 name="recurrence.frequency"
                                 options={ctrl.formInfoData.frequency}
                                 title="Frequência"
                                 onChange={ctrl.setRecurrenceFrequency}
                              />
                           </div>
                           <div className="col-md-6">
                              <Select name="recurrence.duration" items={ctrl.formInfoData.duration} title="Duração" />
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </Card>
            <div className="text-right pt-2">
               <Button size="lg" type="submit">
                  <b>Avançar</b>
               </Button>
            </div>
            <br />
            <br />
         </Form>
      </Style>
   );
};
