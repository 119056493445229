import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { faCalendarCheck, faBarcode } from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexContainer, FlexItem } from '@devesharp/react/web';
import * as QueryString from 'querystring';
import Style, { ProgressColor } from './HomePage.style';
import { PaymentStatus, Header, LoadingPage } from '~/components';
import { useHomePage } from '~/pages/Home/HomePage.controller';
import { formatDate, formatPrice } from '~/services';

export const HomePage: React.FunctionComponent<any> = () => {
   const homePageController = useHomePage();

   return (
      <Style>
         {homePageController.starting && <LoadingPage />}
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <h3 className="m-0 mb-1">Dashboard</h3>
               </div>
            </div>
         </div>

         <Container>
            <FlexContainer>
               <FlexItem flex={1}>
                  <Card className="p-4">
                     <h4 className="pb-2">Boletos vencidos</h4>
                     <div className="row">
                        <div className="col-6">
                           <div className="position-relative p-3">
                              <Doughnut {...homePageController.expiredGraphProps} />
                              <div className="value-inside-graph expired">
                                 {formatPrice(homePageController.resource?.losers.price, 0)}
                              </div>
                           </div>
                        </div>
                        <div className="col-6">
                           {homePageController.losersBar.map((data: any, i: number) => (
                              <ProgressBarInfo
                                 key={`loser-bar-${i}`}
                                 data={data}
                                 total={homePageController.resource?.losers?.total}
                              />
                           ))}
                        </div>
                     </div>
                  </Card>
               </FlexItem>

               <FlexItem className="m-2" />

               <FlexItem flex={1}>
                  <Card className="p-4">
                     <h4 className="pb-2">Boletos a Vencer</h4>
                     <div className="row">
                        <div className="col-6">
                           <div className="position-relative p-3">
                              <Doughnut {...homePageController.toExpireGraphProps} />
                              <div className="value-inside-graph">
                                 {formatPrice(homePageController.resource?.to_win.price, 0)}
                              </div>
                           </div>
                        </div>
                        <div className="col-6">
                           {homePageController.toWinBar.map((data: any, i: number) => (
                              <ProgressBarInfo
                                 key={`loser-bar-${i}`}
                                 data={data}
                                 total={homePageController.resource?.to_win?.total}
                              />
                           ))}
                        </div>
                     </div>
                  </Card>
               </FlexItem>
            </FlexContainer>

            <Card className="p-4">
               <h4>Boletos a Vencer</h4>
               <p>Próximos boletos a vencer</p>
               <br />
               <Table striped bordered hover className="listing">
                  <thead>
                     <tr>
                        <th>Data</th>
                        <th>Pagador</th>
                        <th>ID da transação</th>
                        <th className="text-center">Status</th>
                        <th className="text-right">Valor</th>
                        <th className="text-right" />
                     </tr>
                  </thead>
                  <tbody>
                     {homePageController.statement &&
                        homePageController.statement.map((_statement: any) => (
                           <tr>
                              <td>{formatDate(_statement.payment_method.expiration_date)}</td>
                              <td>{_statement.customer?.full_name}</td>
                              <td>{_statement.seller_name}</td>
                              <td>{_statement.id}</td>
                              <td className="text-center">
                                 <PaymentStatus transaction={_statement} />
                              </td>
                              <td className="text-right">
                                 <b>{formatPrice(_statement.amount)}</b>
                              </td>
                              <td className="text-right">
                                 <a href="#" className="btn-icon">
                                    <FontAwesomeIcon icon={faBarcode} />
                                 </a>
                              </td>
                           </tr>
                        ))}
                  </tbody>
               </Table>
            </Card>
            <br />
            <br />
         </Container>
      </Style>
   );
};

function ProgressBarInfo({ data, total }: any) {
   return (
      <div className="bars-container">
         <div className="bar-container">
            <Link to={`/boletos?${QueryString.stringify(data.url)}`}>
               <div className="bar-name">{data.name}</div>
               <div className="progress-container">
                  <div className="progress-bar-container">
                     <ProgressColor color={data.color}>
                        <div className="progress">
                           <div
                              className="progress-bar"
                              style={{ width: `${parseInt(((data.count / total) * 100) as any, 10)}%` }}
                           />
                        </div>
                     </ProgressColor>
                  </div>

                  <span className="progress-value">
                     {data.count}/{total}
                  </span>
               </div>
            </Link>
         </div>
      </div>
   );
}
