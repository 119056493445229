import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import {
   Button,
   Modal,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
   SelectAutocomplete,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import Style, { ProgressColor } from './PaymentsCreatePage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useCustomersListingPage } from '~/pages/CustomersListing/CustomersListingPage.controller';
import { useCustomersCreatePage } from '~/pages/CustomersCreate/CustomersCreatePage.controller';
import { GroupsSearchResolve } from '~/api';
import { PaymentsCreateStep1 } from '~/pages/PaymentsCreate/Step1';
import { PaymentsCreateStep2 } from '~/pages/PaymentsCreate/Step2';
import { usePaymentsCreatePage } from '~/pages/PaymentsCreate/PaymentsCreatePage.controller';
import { PaymentsCreateStep3 } from '~/pages/PaymentsCreate/Step3';

export const PaymentsCreatePage: React.FunctionComponent<any> = ({ initialType }) => {
   const ctrl = usePaymentsCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">
                              {ctrl.initialType == 'payment_link'
                                 ? 'Link de pagamento'
                                 : ctrl.initialType == 'boleto'
                                 ? 'Boleto Bancário'
                                 : ctrl.initialType == 'recurrence'
                                 ? 'Recorrência'
                                 : ctrl.initialType == 'booklets_boleto' ? 'Carnê' : 'Cartão de crédito'}
                           </h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <div className={`${ctrl.step !== 1 && 'd-none'}`}>
               <PaymentsCreateStep1
                  onSubmit={ctrl.onSubmitStep1}
                  initialType={initialType}
                  onChangeType={ctrl.onChangeType}
                  settings={ctrl.settings}
               />
            </div>
            <div className={`${ctrl.step !== 2 && 'd-none'}`}>
               <PaymentsCreateStep2 backStep={ctrl.backStep1} onSubmit={ctrl.onSubmitStep2} />
            </div>

            {ctrl.step == 3 && (
               <PaymentsCreateStep3 backStep={ctrl.backStep2} onSubmit={ctrl.finish} data={ctrl.resource} />
            )}
         </div>

         <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmitForm}>
            <div />
         </Form>
      </Style>
   );
};
