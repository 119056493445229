import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   UsersCreateResolve,
   UsersGetResolve,
   UsersUpdateResolve,
   GroupsSearchResolve,
   SellersSearchResolve,
   SellersAllSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation, OnlyNumbers, searchCEP } from '~/services';
import history from '~/config/history';
import { sellerSelectState } from '~/recoil/seller-select.state';
import { useUser } from '~/hooks';

const yupValidateSchema = Yup.object().shape({
   name: Yup.string().required('Esse campo é obrigatório!'),
   role: Yup.string().required('Esse campo é obrigatório!'),
   login: Yup.string().required('Esse campo é obrigatório!'),
   // password: Yup.string().required('Esse campo é obrigatório!'),
   CPF: Yup.string().required('Esse campo é obrigatório!'),
   // .test('length', 'Digite o CPF corretamente', (val): any => {
   //    const newVal = val?.replace(/\D/g, '');
   //
   //    return newVal?.length === 14;
   // }),
   phone: Yup.string().test('length', 'Digite o celular corretamente', (val): any => {
      const newVal = val?.replace(/\D/g, '');

      return !newVal || newVal?.length === 10 || newVal?.length === 11;
   }),
   email: Yup.string().email('Email Inválido!').required('Esse campo é obrigatório!'),
});

export function useUsersCreatePage(): any {
   const { id } = useParams<any>();
   const user = useUser();

   document.title = 'Novo usuário - XPAG Tecnologia';
   const [isLoadingCep, setIsLoadingCep] = useState(false);
   const ref = useRef('');

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      yupValidateSchema,
      resolves: {
         sellers: SellersAllSearchResolve,
      },
      resolveGet: UsersGetResolve,
      resolveCreate: UsersCreateResolve,
      resolveUpdate: (params) => UsersUpdateResolve(id, params),
      handleFormData: (formData: any) => {
         const newForm = { ...formData };

         if (!newForm.sellers) {
            newForm.sellers_id = [];
         }

         Object.keys(newForm).forEach((key) => {
            if (key.startsWith('seller_')) {
               if (newForm[key]) {
                  const idSeller = key.split('_')[1];
                  newForm.sellers_id.push(idSeller);
               }
               delete newForm[key];
            }
         });

         console.log(newForm);

         return newForm;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao adicionar usuário',
            type: 'error',
         });
      },
      onSuccess(creating) {
         if (creating) {
            ToastList.show({
               message: 'usuário adicionado com sucesso',
               type: 'success',
            });
            history.push('/Users');
         } else {
            ToastList.show({
               message: 'usuário editado com sucesso',
               type: 'success',
            });
            history.push('/Users');
         }
      },
   });

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);

   const [role, setRole] = useState('SIMPLE');

   const [resource, setResource] = useState<any>();
   const [groups, setGroups] = useState<any[]>([]);
   const [sellers, setSellers] = useState<any[]>([]);

   // console.log(groups)
   registerOnInit((resources: any) => {
      if (resources.sellers) {
         setSellers(
            resources.sellers.sort(function (a: any, b: any) {
               if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
                  return -1;
               }
               if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
                  return 1;
               }
               return 0;
            }),
         );
      }

      if (resources.resource) {
         const resource2 = { ...resources.resource };
         if (resource2.sellers) {
            (resource2 as any)?.sellers?.forEach((i: any) => {
               resource2[`seller_${i.id}`] = true;
            });
         }

         setResource(resource2);
         formRef.current.setData(resource2);
      }
   });

   useEffect(() => {}, []);

   useEffect(() => {
      // console.log(resource);
      // console.log(resource?.group_id?.label);
      // console.log('sdsd');
      // formRef.current.setFieldValue('group_id', '1');
   }, [groups, resource]);

   const formInfoData = {
      groups: [
         {
            value: '',
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            value: group.id,
            name: group.name,
         })),
      ],
      roles:
         user.role == 'MASTER'
            ? [
                 {
                    value: 'MASTER',
                    name: 'Administrador Martketplace (Pode ver tudo)',
                 },
                 {
                    value: 'ADMIN',
                    name: 'Administrador',
                 },
                 {
                    value: 'SIMPLE',
                    name: 'Simples',
                 },
              ]
            : [
                 {
                    value: 'ADMIN',
                    name: 'Administrador',
                 },
                 {
                    value: 'SIMPLE',
                    name: 'Simples',
                 },
              ],
      states: states.map((state) => ({ name: state.name, value: state.id })),
   };

   function onChangeCep(): any {
      const cep = OnlyNumbers(formRef.current.getFieldValue('address_postal_code'));
      if (cep.length == 8 && ref.current !== cep) {
         setIsLoadingCep(true);
         formRef.current.setFieldError('address_postal_code', '');
         searchCEP(cep)
            .then((e: any) => {
               setIsLoadingCep(false);
               const address = (formRef.current.getData() as any).address ?? {};
               formRef.current.setData({
                  ...formRef.current.getData(),
                  address_address: e.data.street,
                  address_city: e.data.city,
                  address_neighborhood: e.data.neighborhood,
                  address_state: e.data.state,
               });
            })
            .catch(() => {
               setIsLoadingCep(false);
               formRef.current.setFieldError('address_postal_code', 'CEP não encontrado');
            });
      }
      ref.current = cep;
   }

   // function searchCep() {
   //    searchCEP.then((e) => {
   //       console.log('e');
   //    });
   // }
   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function openCreateGroup() {
      history.push('/groups/create');
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      openCreateGroup,
      role,
      setRole,
      id,
      editing: !!id,
      formRef,
      sellers,
      reloadPage,
      starting,
      saving,
      isLoadingCep,
      onChangeCep,
      errorLoadData,
      formInfoData,
      sellerSelect,
   };
}
