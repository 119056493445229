import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Button, CloseBtn, Modal, Select, ToastList } from '@devesharp/react/web/index';
import { Form } from '@unform/web';
import { ProgressBar } from 'react-bootstrap';
import Style from './ModalDownload.style';
import { fileDownloadGlobalState } from '~/recoil/file-donwload-global.state';
import { GetFileStatus } from '~/api';

export const ModalDownload: React.FunctionComponent<{}> = () => {
   const [fileDownloadGlobal, setFileDownloadGlobal] = useRecoilState(fileDownloadGlobalState);
   const interval = React.useRef<any>(null);

   useEffect(() => {
      if (fileDownloadGlobal) {
         interval.current = setInterval(() => {
            GetFileStatus(fileDownloadGlobal.id).subscribe(
               (res) => {
                  if (res.data.status === 'error') {
                     setFileDownloadGlobal(null);
                     ToastList.show({
                        message: 'Erro ao gerar arquivo',
                        type: 'error',
                     });
                  } else if (fileDownloadGlobal) {
                     setFileDownloadGlobal(res.data);
                  }
               },
               () => {
                  console.log('sdsd');
               },
            );
         }, 2000);

         return () => clearInterval(interval.current);
      }
   }, [fileDownloadGlobal]);

   function openFile(): void {
      if (fileDownloadGlobal) {
         window.open(fileDownloadGlobal.url, '_blank');
      }
   }

   function closeModal(): void {
      if (interval.current) {
         clearInterval(interval.current);
      }
      setFileDownloadGlobal(null);
   }

   if (!fileDownloadGlobal?.id) {
      return null;
   }

   return (
      <>
         <Modal show={!!fileDownloadGlobal} onHide={closeModal}>
            {fileDownloadGlobal?.status != 'finish' && (
               <Modal.Body className="p-4 text-left w-100">
                  <div className="text-center">
                     <h4>Gerando arquivo...</h4>
                  </div>
                  <div className="pt-4" />
                  <ProgressBar now={fileDownloadGlobal?.process ?? 0} min={0} max={100} />

                  <br />
                  <div className="text-center">
                     <Button variant="danger" onClick={closeModal}>
                        Cancelar download
                     </Button>
                  </div>
               </Modal.Body>
            )}

            {fileDownloadGlobal?.status == 'finish' && (
               <Modal.Body className="p-4 text-left w-100">
                  <div className="close">
                     <CloseBtn onClick={closeModal} />
                  </div>

                  <div className="text-center">
                     <h4>Arquivo gerado com sucesso!</h4>
                  </div>

                  <div className="pt-4" />
                  <div className="row">
                     <div className="col text-right">
                        <Button onClick={openFile}>Baixar excel</Button>
                     </div>
                     <div className="col">
                        <Button variant="danger" onClick={closeModal}>
                           Fechar
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            )}
         </Modal>
      </>
   );

   return (
      <Style>
         <div className="loading-icon">
            <div className="load">
               <div />
            </div>
         </div>
      </Style>
   );
};
