import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete, Textarea, Checkbox } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Style, { ProgressColor } from './SMSMessagePage.style';
import { LoadingPage } from '~/components';
import { useSMSMessagePage } from '~/pages/SMSMessage/SMSMessagePage.controller';

export const SMSMessagePage: React.FunctionComponent<any> = () => {
   const ctrl = useSMSMessagePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Configurar Notificações</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">SMS</div>

                     <br />

                     <div className="row field-box">
                        <div className="col">
                           <Textarea
                              name="SMS_MESSAGE_BOLETO"
                              title="Mensagem SMS"
                              rows={5}
                              onChange={ctrl.onChangeMessage}
                           />
                        </div>
                     </div>
                     <br />

                     <div className="row field-box">
                        <div className="col">
                           <Checkbox name="SMS_SEND_ON_CREATE_BOLETO" title="Enviar SMS ao criar boleto" />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox
                              name="SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO"
                              title="Enviar SMS 2 dias antes do vencimento"
                           />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox name="SMS_SEND_ON_DAY_EXPIRED_BOLETO" title="Enviar SMS no dia do vencimento" />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox
                              name="SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO"
                              title="Enviar SMS um dia após o vencimento"
                           />
                        </div>
                     </div>
                  </div>
               </Card>

               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Aviso por email</div>

                     <br />

                     <div className="row field-box">
                        <div className="col">
                           <Checkbox name="EMAIL_SEND_ON_CREATE_BOLETO" title="Enviar email ao criar boleto" />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox
                              name="EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO"
                              title="Enviar emails
                            2 dias antes do vencimento"
                           />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox
                              name="EMAIL_SEND_ON_DAY_EXPIRED_BOLETO"
                              title="Enviar emails
                            no dia do vencimento"
                           />
                        </div>
                     </div>
                     <div className="row field-box">
                        <div className="col">
                           <Checkbox
                              name="EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO"
                              title="Enviar emails
                            um dia após o vencimento"
                           />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Atualizar</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
