import React, { useEffect, useRef, useState } from 'react';
import DateFNS from 'date-fns';
import { convertStringToDate } from '@devesharp/react/web';
import Style from './PaymentOperationType.style';

export function PaymentOperationType({ history, status }: any): any {
   let text = '';
   let currentStatus = status;

   switch (currentStatus) {
      case 'created':
         text = 'Criada';
         break;
      case 'succeeded':
      case 'paid':
         text = 'Paga';
         break;
      case 'canceled':
         text = 'Cancelado';
         break;
      case 'deletado':
         text = 'Deletado';
         break;
      case 'refunded':
         text = 'Estornado';
         break;
      case 'scheduled':
         text = 'Agendado';
         break;
      case 'authorization':
         if (history?.status == 'succeeded') {
            text = 'Transação autorizado';
            currentStatus = 'paid';
         } else {
            currentStatus = 'canceled';
            text = 'Erro na autorização';
         }
         break;
      case 'void':
         if (history?.status == 'succeeded') {
            text = 'Estornado';
            currentStatus = 'scheduled';
         } else {
            currentStatus = 'canceled';
            text = 'Erro ao estornar';
         }
         break;
   }

   return (
      <Style>
         <div className={`badge badge-${currentStatus}`}>{text}</div>
      </Style>
   );
}
