import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useRecoilState } from 'recoil';
import { CustomersSearchResolve, GroupsSearchResolve, PaymentLinksSearchResolve } from '~/api';
import { useUser } from '~/hooks';
import { sellerSelectState, sellerShowInfoState } from '~/recoil/seller-select.state';

export function useGroupsListingPage(): any {
   document.title = 'Grupos - XPAG Tecnologia';
   const [showSeller] = useRecoilState(sellerShowInfoState);

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      setFilters,
      setOffset,
      setPage,
      setSort,
      filters,
   } = useViewList({
      resolves: {
         items: GroupsSearchResolve,
      },
      filtersDefault: {},
   });

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [loading, setLoading] = useState(false);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      onUnselectAll();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   const filtersData = {
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'succeeded', name: 'Pago' },
         { id: 'pending', name: 'Pendente' },
         { id: 'expired', name: 'Expirado' },
      ],
   };

   function onSubmit(data: any) {
      const params = data;
      params.created_at_gte = data?.created_at_gte?.dateYYYYMMDD;
      params.created_at_lte = data?.created_at_lte?.dateYYYYMMDD;

      setFilters(() => params);
   }

   /**
    * Seller Select
    */
   const user = useUser();
   const userRole = user.role;

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      reloadPage(true);
   }, [sellerSelect]);

   const page = filters.page ?? 1;
   let pageCountStart = (page - 1) * 20 + 1;
   pageCountStart = resourcesTotal ? pageCountStart : 0;
   let pageCountEnd = (page - 1) * 20 + 20;
   pageCountEnd = pageCountEnd > resourcesTotal ? resourcesTotal : pageCountEnd;

   return {
      onSubmit,
      setSort,
      formRef,
      reloadPage,
      starting,
      searching,
      loading,
      pageCountStart,
      pageCountEnd,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
      userRole,
      showSeller,
   };
}
