import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Form } from '@unform/web';
import Style from './BoletoBBCreatePage.style';
import { LoadingPage } from '~/components';
import { PaymentsCreateStep1 } from './Step1';
import { PaymentsCreateStep2 } from './Step2';
import { usePaymentsCreatePage } from './BoletoBBCreatePage.controller';
import { PaymentsCreateStep3 } from './Step3';

export const BoletoBBCreatePage: React.FunctionComponent<any> = ({ initialType = 'boleto' }) => {
   const ctrl = usePaymentsCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">Emissão de Boleto para Banco do Brasil</h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <div className={`${ctrl.step !== 1 && 'd-none'}`}>
               <PaymentsCreateStep1
                  onSubmit={ctrl.onSubmitStep1}
                  initialType={initialType}
                  onChangeType={ctrl.onChangeType}
               />
            </div>
            <div className={`${ctrl.step !== 2 && 'd-none'}`}>
               <PaymentsCreateStep2 backStep={ctrl.backStep1} onSubmit={ctrl.onSubmitStep2} />
            </div>

            {ctrl.step == 3 && (
               <PaymentsCreateStep3 backStep={ctrl.backStep2} onSubmit={ctrl.finish} data={ctrl.resource} />
            )}
         </div>

         <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmitForm}>
            <div />
         </Form>
      </Style>
   );
};
