import styled from 'styled-components';

const css = styled.div;

export default css`
   background: ${(props) => props.theme.primary};
   flex: 1;
   min-height: 100%;

   .container {
      max-width: 1000px;
   }

   .form-padding {
      padding: 2em;
   }

   .logo {
      height: 35px;
   }

   @media (max-width: 700px) {
      .rccs {
         margin-left: -29px;
      }
   }
`;
