import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   AuthMeAPI,
   AuthMeUpdateAPI,
   ChangeMyPasswordAPI,
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation, OnlyNumbers, searchCEP } from '~/services';
import history from '~/config/history';

const yupValidateSchema = Yup.object().shape({
   old_password: Yup.string().required('Esse campo é obrigatório!'),
   new_password: Yup.string().required('Esse campo é obrigatório!'),
   re_new_password: Yup.string()
      .required('Esse campo é obrigatório!')
      .oneOf([Yup.ref('new_password'), null], 'As duas senhas precisam ser iguais.'),
});

export function useChangePasswordPage(): any {
   const { id } = useParams<any>();

   document.title = 'Mudar senha - XPAG Tecnologia';

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      yupValidateSchema,
      resolveCreate: ChangeMyPasswordAPI,
      onFailed(editing, data) {
         ToastList.show({
            message: data.data.error,
            type: 'error',
         });
      },
      onSuccess() {
         ToastList.show({
            message: 'Senha atualizada',
            type: 'success',
         });
      },
   });

   return {
      onSubmit: onSubmitForm,
      id,
      editing: !!id,
      formRef,
      reloadPage,
      starting,
      saving,
      errorLoadData,
   };
}
