import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { APIServiceUrl, DataDashResolve, GenerateExcel, TransfersSearchResolve } from '~/api';
import { useUser } from '~/hooks';
import { sellerSelectState, sellerShowInfoState } from '~/recoil/seller-select.state';
import { useViewList } from '~/hooks/useViewList';
import { fileDownloadGlobalState } from '~/recoil/file-donwload-global.state';

export function usePaymentsListingPage(): any {
   document.title = 'Lista de operações - XPAG Tecnologia';
   const location = useLocation();
   const [showSeller] = useRecoilState(sellerShowInfoState);

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnAfterSearch,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      setSort,
      setFilters,
      setOffset,
      setPage,
      filters,
   } = useViewList({
      resolves: {
         items: TransfersSearchResolve,
      },
      filtersDefault: {
         payment_type: location.pathname === '/payments/credit' ? 'credit' : '',
      },
   });

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [sum, setSum] = useState(0);
   const [sumPage, setSumPage] = useState(0);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();

   /**
    * Seller Select
    */
   const user = useUser();
   const userRole = user.role;

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      reloadPage(true);
   }, [sellerSelect]);

   useLayoutEffect(() => {
      return registerOnInit((v: any) => {
         setSum(v.items.sum);
         setSumPage(v.items.sum_page);
      });
   }, []);

   useLayoutEffect(() => {
      return registerOnAfterSearch((v: any) => {
         setSum(v.sum);
         setSumPage(v.sum_page);
      });
   }, []);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      // window.scrollTo(0, 0);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   const [loading, setLoading] = useState(false);

   const filtersData = {
      types: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: 'credit',
            name: 'Cartão de crédito',
         },
         {
            id: 'boleto',
            name: 'Boleto',
         },
      ],
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'succeeded', name: 'Aprovada' },
         { id: 'canceled', name: 'Cancelada' },
         { id: 'failed', name: 'Falhada' },
         { id: 'pending', name: 'Pendente' },
      ],
   };

   function onSubmit(data: any): void {
      const params = data;
      params.created_at_gte = data?.created_at_gte?.dateYYYYMMDD;
      params.created_at_lte = data?.created_at_lte?.dateYYYYMMDD;
      params.transfer_date_gte = data?.transfer_date_gte?.dateYYYYMMDD;
      params.transfer_date_lte = data?.transfer_date_lte?.dateYYYYMMDD;
      params.pay_date_lte = data?.pay_date_lte?.dateYYYYMMDD;
      params.pay_date_gte = data?.pay_date_gte?.dateYYYYMMDD;

      setSum(0);
      setFilters(() => params);
   }

   const [fileDownloadGlobal, setFileDownloadGlobal] = useRecoilState(fileDownloadGlobalState);

   function generateExcel(): void {
      setLoading(true);

      if (selected.size) {
         GenerateExcel({ ids: Array.from(selected.keys()) }, 'transfers').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
               // window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcel(filters, 'transfers').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
      // if (selected.size) {
      //    GenerateExcel({ ids: Array.from(selected.keys()) }, 'transfers').subscribe(
      //       (link) => {
      //          onUnselectAll();
      //          setLoading(false);
      //          window.open(`${APIServiceUrl}/excel/${link}`);
      //       },
      //       (data) => {
      //          onUnselectAll();
      //          setLoading(false);
      //          ToastList.show({
      //             message: data.error,
      //             type: 'error',
      //          });
      //       },
      //    );
      // } else {
      //    GenerateExcel(filters, 'transfers').subscribe(
      //       (link) => {
      //          onUnselectAll();
      //          setLoading(false);
      //          window.open(`${APIServiceUrl}/excel/${link}`);
      //       },
      //       (data) => {
      //          onUnselectAll();
      //          setLoading(false);
      //          ToastList.show({
      //             message: data.error,
      //             type: 'error',
      //          });
      //       },
      //    );
      // }
   }

   function selectAll(): void {
      if (checkedAll) {
         if (Array.from(selected.keys()).length === resources.length) {
            onUnselectAll();
         } else {
            resources.forEach((item: any) => {
               onSelect(item.payment.id);
            });
         }
      } else {
         resources.forEach((item: any) => {
            onSelect(item.payment.id);
         });
      }
   }

   const checkedAll = Array.from(selected.keys()).length === resources.length;

   return {
      onSubmit,
      formRef,
      reloadPage,
      selectAll,
      checkedAll,
      starting,
      searching,
      errorLoadData,
      resources,
      setSort,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      sum,
      sumPage,
      loading,
      generateExcel,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
      showSeller,
      userRole,
   };
}
