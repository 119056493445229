import styled from 'styled-components';

const css = styled.div;

export const ProgressColor = css<any>`
   .progress-bar {
      background-color: ${(props) => props.color} !important;
   }
`;

export default css`
   .table thead th {
      background: ${(props) => props.theme.primary} !important;
      color: white;
   }
`;
