import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import * as _ from 'lodash';
import { ISwitchProps } from './Switch.interface';
import Style from './Switch.style';

export const Switch: React.FunctionComponent<ISwitchProps> = ({
   children,
   name,
   value = true,
   title,
   color,
   'data-cy': dataCy,
   ...rest
}) => {
   const inputRef = useRef(null);
   const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
   const defaultChecked: any = _.isEqual(defaultValue, value);
   const [checked, setChecked] = useState(defaultChecked);

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: inputRef.current,
         path: 'value' as any,
         setValue: (ref: any, _value: any) => {
            ref.checked = _.isEqual(_value, value);
            setChecked(ref.checked);
         },
         getValue: (ref: any) => {
            return ref.checked ? value : null;
         },
         clearValue(ref: any) {
            ref.checked = false;
            setChecked(false);
         },
      });
   }, [fieldName, registerField, value]);

   return (
      <Style color={color} checked={checked}>
         <div className="checkbox-container">
            <label data-cy={dataCy} className={'d-flex'}>
               <div className={`switch-btn-container ${rest.disabled && 'disabled'}`}>
                  <input
                     type="checkbox"
                     ref={inputRef}
                     defaultChecked={defaultChecked}
                     {...rest}
                     onChange={(ev) => {
                        setChecked(ev.target.checked);
                        if (error) clearError();
                     }}
                  />
                  <span
                     className={`switch-button ${error && 'has-error'} ${rest.disabled && 'disabled'}`}
                     data-testid="input-switch"
                  />
               </div>
               <span className={`title ${rest.disabled && 'disabled'}`}>{title}</span>
            </label>
         </div>

         {error && (
            <div data-cy={`input-error.${rest['data-cy'] ?? ''}`} className="error-input" data-testid="input-error">
               {error}
            </div>
         )}
      </Style>
   );
};
