import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { Button, CloseBtn, Modal, Select, ToastList, Textarea } from '@devesharp/react/web/index';
import { Form } from '@unform/web';
import { ProgressBar } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import Style from './ModalCancelBoleto.style';
import { fileDownloadGlobalState } from '~/recoil/file-donwload-global.state';
import { GetFileStatus } from '~/api';
import { LoadingPage } from '~/components';

export const ModalCancelBoleto: React.FunctionComponent<any> = (props: any) => {
   const formRef = useRef<FormHandles>(null);

   function forceSubmit(): void {
      props?.onCancel(formRef.current?.getData());
   }

   return (
      <>
         <Modal show={props.show} onHide={props.onRequestClose}>
            <div className="foreground" onClick={props.onRequestClose} />

            <Modal.Body className="p-4 text-left w-100">
               {props.loading && <LoadingPage />};
               <div className="close">
                  <CloseBtn onClick={props.onRequestClose} />
               </div>
               <div className="text-center">
                  <h4>Qual motivo do cancelamento?</h4>
               </div>
               <div className="pt-4" />
               <Form onSubmit={() => {}} ref={formRef}>
                  <Textarea name="cancel_reason" title="Motivo" />
               </Form>
               <br />
               <div className="text-center">
                  <Button variant="danger" onClick={forceSubmit}>
                     Cancelar boleto
                  </Button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};
