import React, { useEffect, useRef, useState } from 'react';
import { convertStringToDate, useViewForm } from '@devesharp/react/web';
import * as Yup from 'yup';
import { Observable } from 'rxjs';
import Datefns from 'date-fns';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { CustomersUpdateResolve, GroupsSearchResolve } from '~/api';
import { states } from '~/services/data';
import { OnlyNumbers } from '~/services';
import history from '~/config/history';

/**
 * Validação do Cartão
 */
const cardValidation = {
   type: Yup.string(),
   card_number: Yup.string().when('type', {
      is: 'credit',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('card-length', 'Digite o número correto do cartão!', (val: any) => {
            return OnlyNumbers(val).length === 16;
         }),
   }),
   holder_name: Yup.string().when('type', {
      is: 'credit',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   expiration_month: Yup.string().when('type', {
      is: 'credit',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   expiration_year: Yup.string().when('type', {
      is: 'credit',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   security_code: Yup.string().when('type', {
      is: 'credit',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('cvv-length', 'Digite o código de segurança completo.', (val: any) => {
            return OnlyNumbers(val).length === 3;
         }),
   }),
};

const boletoValidation = {
   expiration_date: Yup.string().when('type', {
      is: 'boleto',
      then: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('invalid-date', 'Data deve ser apartir de hoje', function (val: any): any {
            return Datefns.differenceInDays(convertStringToDate(val), new Date()) >= 0;
         }),
   }),
   interest_percent: Yup.string().when('type', {
      is: 'boleto',
      then: Yup.string().test('limit-percent', 'O limite da multa é 2%', (val: any): any => {
         const percent = Number(OnlyNumbers(val));
         return percent <= 2 * 100;
      }),
      // .test('limit-percent-min', 'A multa deve ser 0 ou maior que 0,30%', (val: any): any => {
      //    const percent = Number(OnlyNumbers(val));
      //    return percent >= 0.3 * 100 || !percent;
      // }),
   }),
   fine_percent: Yup.string().when('type', {
      is: 'boleto',
      then: Yup.string().test('limit-percent', 'O limite de juros é 1%', (val: any): any => {
         const percent = Number(OnlyNumbers(val));
         return percent <= 1 * 100;
      }),
   }),
   discount_value: Yup.string().when('type', {
      is: 'boleto',
      then: Yup.string().test('discount', 'O desconto não pode ser maior/igual ao valor do boleto', function (
         val: any,
      ): any {
         const amountDiscount = Number(OnlyNumbers(val));
         const amount = Number(OnlyNumbers((this as any).parent.amount));

         return amountDiscount === 0 || amount > amountDiscount;
      }),
      // .test('required-discount', 'Esse campo é obrigatório!', function (val: any): any {
      //    if ((this as any).parent.discount_date !== '') {
      //       return val !== '';
      //    }
      //
      //    return true;
      // }),
   }),
   discount_date: Yup.string().when('type', {
      is: 'boleto',
      then: Yup.string()
         .test('invalid-date', 'Data inválida', function (val: any): any {
            return val == null || val == '' || !!convertStringToDate(val);
         })
         .test('limit-percent', 'A data do desconto não pode ser após a data de vencimento', function (val: any): any {
            return (
               val == null ||
               val == '' ||
               Datefns.differenceInDays(
                  convertStringToDate((this as any).parent.expiration_date),
                  convertStringToDate(val),
               ) >= 0
            );
         })
         .test('required', 'Esse campo é obrigatório!', function (val: any): any {
            const discont_value_empty =
               (this as any).parent.discount_value == '' ||
               (this as any).parent.discount_value == null ||
               Number(OnlyNumbers((this as any).parent.discount_value)) == 0;

            return !(!discont_value_empty && !val);

            if (!discont_value_empty && !val) {
               return false;
            }

            return true;
         }),
   }),
};

const yupValidateSchema = Yup.object().shape({
   amount: Yup.string().required('Esse campo é obrigatório!'),
   description: Yup.string().required('Esse campo é obrigatório!'),
   ...boletoValidation,
});

export function usePaymentsCreateStep1({ onSubmit, initialType, onChangeType: onChangeTypeParent }: any): any {
   const { id } = useParams<any>();

   const [groups, setGroups] = useState<any[]>([]);
   const [typePayment, setTypePayment] = useState(initialType);
   const [booklet, setBooklet] = useState(false);

   useEffect(() => {
      onChangeTypeParent(typePayment);
   }, [typePayment]);

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      resolveCreate: (data) =>
         new Observable((obs) => {
            const newData = { ...data };

            if (newData.discount_value && Number(newData.discount_value) == 0) {
               newData.discount_value = null;
            }

            if (newData.interest_percent && Number(newData.interest_percent) == 0) {
               newData.interest_percent = null;
            }

            if (newData.fine_percent && Number(newData.fine_percent) == 0) {
               newData.fine_percent = null;
            }

            if (newData.discount_value && Number(newData.discount_value) > 0) {
               newData.discounts = [
                  {
                     type: 'MONEY',
                     date: newData.discount_date,
                     value: newData.discount_value,
                  },
               ];
            }

            if (newData.interest_percent) {
               newData.interest = {
                  type: 'MONTHLY_PERCENTAGE',
                  value: parseInt(newData.interest_percent, 10) / 100,
               };
            }

            if (newData.fine_percent) {
               newData.late_fee = {
                  type: 'PERCENTAGE',
                  value: parseInt(newData.fine_percent, 10) / 100,
               };
            }

            onSubmit(newData);

            obs.next({});
            obs.complete();
         }),
      resolveUpdate: (params) => CustomersUpdateResolve(id, params),
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         if (newForm.card) {
            newForm.card.type = newForm.type;
         }

         newForm.type = 'boleto';
         newForm.bookletEnabled = booklet;

         newForm.discount_date = formData?.discount_date?.dateYYYYMMDD;
         newForm.expiration_date = formData?.expiration_date?.dateYYYYMMDD;

         return newForm;
      },
      yupValidateSchema,
      onSuccess(creating) {
         console.log('onSuccess');
         // if (creating) {
         //    ToastList.show({
         //       message: 'Cliente adicionado com sucesso',
         //       type: 'success',
         //    });
         //    history.push('/customers');
         // } else {
         //    ToastList.show({
         //       message: 'Cliente editado com sucesso',
         //       type: 'success',
         //    });
         //    history.push('/customers');
         // }
      },
   });

   const initialData = {
      type: typePayment,
      bookletEnabled: booklet ? 'booklet' : 'unique',
   };

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }
   });

   const formInfoData = {
      groups: [
         {
            id: '',
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      expiration_month: [
         {
            id: 1,
            name: '1',
         },
         {
            id: 2,
            name: '2',
         },
         {
            id: 3,
            name: '3',
         },
         {
            id: 4,
            name: '4',
         },
         {
            id: 5,
            name: '5',
         },
         {
            id: 6,
            name: '6',
         },
         {
            id: 7,
            name: '7',
         },
         {
            id: 8,
            name: '8',
         },
         {
            id: 9,
            name: '9',
         },
         {
            id: 10,
            name: '10',
         },
         {
            id: 11,
            name: '11',
         },
         {
            id: 12,
            name: '12',
         },
      ],
      expiration_year: [
         {
            id: Datefns.getYear(new Date()),
            name: Datefns.getYear(new Date()),
         },
         {
            id: Datefns.getYear(new Date()) + 1,
            name: Datefns.getYear(new Date()) + 1,
         },
         {
            id: Datefns.getYear(new Date()) + 2,
            name: Datefns.getYear(new Date()) + 2,
         },
         {
            id: Datefns.getYear(new Date()) + 3,
            name: Datefns.getYear(new Date()) + 3,
         },
         {
            id: Datefns.getYear(new Date()) + 4,
            name: Datefns.getYear(new Date()) + 4,
         },
         {
            id: Datefns.getYear(new Date()) + 5,
            name: Datefns.getYear(new Date()) + 5,
         },
         {
            id: Datefns.getYear(new Date()) + 6,
            name: Datefns.getYear(new Date()) + 6,
         },
         {
            id: Datefns.getYear(new Date()) + 7,
            name: Datefns.getYear(new Date()) + 7,
         },
         {
            id: Datefns.getYear(new Date()) + 8,
            name: Datefns.getYear(new Date()) + 8,
         },
         {
            id: Datefns.getYear(new Date()) + 9,
            name: Datefns.getYear(new Date()) + 9,
         },
         {
            id: Datefns.getYear(new Date()) + 10,
            name: Datefns.getYear(new Date()) + 10,
         },
         {
            id: Datefns.getYear(new Date()) + 11,
            name: Datefns.getYear(new Date()) + 11,
         },
         {
            id: Datefns.getYear(new Date()) + 12,
            name: Datefns.getYear(new Date()) + 12,
         },
         {
            id: Datefns.getYear(new Date()) + 13,
            name: Datefns.getYear(new Date()) + 13,
         },
      ],
      installments: [
         {
            id: 30,
            name: '1 mês',
         },
         {
            id: 15,
            name: '15 dias',
         },
         {
            id: 40,
            name: '40 dias',
         },
      ],
      states,
   };

   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function openCreateGroup() {
      history.push('/groups/create');
   }

   function onChangeType() {
      setTypePayment(formRef.current.getFieldValue('type'));
   }

   function onChangeBooklet() {
      setBooklet(formRef.current.getFieldValue('bookletEnabled') === 'booklet');
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      openCreateGroup,
      onChangeType,
      onChangeBooklet,
      typePayment,
      booklet,
      formRef,
      reloadPage,
      initialData,
      starting,
      saving,
      errorLoadData,
      formInfoData,
   };
}
