import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faAngleDown,
   faArrowDown,
   faBarcode,
   faClock,
   faCopy,
   faDownload,
   faFileExcel,
   faFilePdf,
   faPaperPlane,
   faTimes,
   faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button, ToastList } from '@devesharp/react/web';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Style from './BookletsDetailPage.style';
import { usePaymentsDetailPage } from '~/pages/PaymentsDetail/PaymentsDetailPage.controller';
import { LoadingPage, PaymentOperationType, PaymentStatus } from '~/components';
import { PaymentType } from '~/components/PaymentType';
import { formaHour, formatBarCode, formatDate, formatPrice } from '~/services';
import { useBookletsDetailPage } from '~/pages/BookletsDetail/BookletsDetailPage.controller';
import history from '~/config/history';
import { BookletsBoletoStatus } from '~/components/PaymentStatus/BookletsBoletoStatus';
import {ModalCancelBoleto} from "~/components/ModalCancelBoleto";

export const BookletsDetailPage: React.FunctionComponent<any> = () => {
   const ctrl = useBookletsDetailPage();

   if (!ctrl.resource) {
      return (
         <>
            <LoadingPage />
         </>
      );
   }

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">{ctrl.resource.name}</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {(ctrl.starting || ctrl.loading) && <LoadingPage />};
         <Container>
            <div className="row">
               <div className="col-3">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`}>
                        <div className="d-flex">
                           <div>Valor</div>
                           <div className="flex-fill text-right">
                              <span>{formatPrice(ctrl.resource?.amount * 100 * ctrl.resource?.installments, 2)}</span>
                           </div>
                        </div>
                     </div>

                     <div className="p-4">
                        <h5>Informações da transação</h5>
                        <p />

                        <p>
                           Nome do carnê
                           <br />
                           <span>{ctrl.resource.name}</span>
                        </p>

                        <p>
                           Status da venda
                           <br />
                           <PaymentStatus status="pending" />
                        </p>

                        <p>
                           Valor das parcelas
                           <br />
                           <span>{formatPrice(ctrl.resource?.amount * 100, 2)}</span>
                        </p>

                        <p>
                           Quantidade de parcelas <br />
                           <b>{ctrl.resource?.installments || 1} boletos</b>
                        </p>

                        <p>
                           Descrição da venda
                           <br />
                           <b>{ctrl.resource.description ?? '--'}</b>
                        </p>

                        <p>
                           Criado em
                           <br />
                           <span>{formatDate(ctrl.resource.created_at)}</span>
                        </p>
                     </div>
                  </Card>

                  <Card className="p-4">
                     <h5>Informações do Pagador</h5>
                     <p />

                     <p>
                        Nome <br />
                        <b>
                           {!!ctrl.resource.customer?.id && (
                              <Link className="link_ref" to={`/customers/edit/${ctrl.resource.customer.id}`}>
                                 {ctrl.resource.customer?.full_name}
                              </Link>
                           )}
                        </b>
                     </p>

                     {!!ctrl.resource.customer?.group_id && (
                        <p>
                           Grupo <br />
                           <b>
                              <Link className="link_ref" to={`/groups/edit/${ctrl.resource.customer.group_id}`}>
                                 {ctrl.resource.customer?.group_name}
                              </Link>
                           </b>
                        </p>
                     )}
                     <p>
                        Email <br />
                        <b>{ctrl.resource.customer?.email}</b>
                     </p>
                     <p>
                        Endereço <br />
                        {ctrl.resource.customer?.address_address && (
                           <b>
                              {ctrl.resource.customer?.address_address} {ctrl.resource.customer?.address_number}{' '}
                              {ctrl.resource.customer?.address_complement} -{' '}
                              {ctrl.resource.customer?.address_neighborhood} - {ctrl.resource.customer?.address_city} -{' '}
                              {ctrl.resource.customer?.address_state} - CEP{' '}
                              {ctrl.resource.customer?.address_postal_code}
                           </b>
                        )}
                     </p>
                  </Card>
               </div>
               <div className="col-9">
                  <div className="d-flex pb-3">
                     <div className="flex-fill" />
                     <div>
                        <Button variant="danger" rounded outline onClick={ctrl.cancelBooklet}>
                           <FontAwesomeIcon icon={faTimes} />
                           &nbsp;&nbsp;CANCELAR CARNÊ
                        </Button>
                     </div>
                     &nbsp;&nbsp;
                     <div>
                        <Button
                           variant="info"
                           rounded
                           outline
                           onClick={ctrl.openUrlBooklet}
                           disabled={ctrl.resource.url_pdf == ''}
                        >
                           <FontAwesomeIcon icon={faFilePdf} />
                           &nbsp;&nbsp;ABRIR PDF CARNÊ
                        </Button>
                     </div>
                  </div>

                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`} />

                     <div className="p-4">
                        <h5>LISTA DE BOLETOS</h5>
                        <p />

                        <div className="d-flex pb-3">
                           <div className="flex-fill" />
                           <div>
                              {!!ctrl.selected.size && (
                                 <Button rounded outline variant="danger" onClick={ctrl.cancelMany}>
                                    <FontAwesomeIcon icon={faTimes} /> <b>Cancelar boletos</b>
                                 </Button>
                              )}
                              &nbsp;
                              <Button rounded outline disabled={!ctrl.selected.size} onClick={ctrl.sendSMS}>
                                 <FontAwesomeIcon icon={faPaperPlane} /> Enviar SMS
                              </Button>
                              &nbsp;
                              <Button rounded outline variant="success" onClick={ctrl.generateExcel}>
                                 <FontAwesomeIcon icon={faFileExcel} /> <b>Gerar Excel</b>
                              </Button>
                           </div>
                        </div>

                        <Table striped bordered hover className="listing">
                           <thead>
                              <tr>
                                 <th />
                                 <th>ID da transação</th>
                                 <th>Data da venda</th>
                                 <th>Pagador</th>
                                 <th>Data de vencimento</th>
                                 <th className="text-center">Status</th>
                                 <th className="text-right">Valor</th>
                                 <th className="text-right">Valor pago</th>
                                 <th className="text-right" />
                              </tr>
                           </thead>

                           <tbody>
                              {ctrl.resource.boletos &&
                                 ctrl.resource.boletos.map((transaction: any) => (
                                    <ItemListing
                                       key={transaction.boleto_provisional.id}
                                       transaction={transaction}
                                       selected={ctrl.checkSelected(transaction.id)}
                                       setSelected={ctrl.onSelect}
                                       sendSMS={ctrl.sendOne}
                                       cancelBoleto={ctrl.cancelBoleto}
                                    />
                                 ))}
                           </tbody>
                        </Table>
                     </div>
                  </Card>
               </div>
            </div>
         </Container>

         <ModalCancelBoleto
            show={ctrl.modalCancel}
            onRequestClose={() => ctrl.setModalCancel(false)}
            onCancel={ctrl.onCancelModal}
         />
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   transaction,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   cancelBoleto,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr onClick={openTransaction}>
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/payments/detail/${transaction.id}`);
   }

   if (transaction.boleto_provisional.status != 'created') {
      return (
         <Fade in={show} timeout={500}>
            <tr className={`${selected && 'selected'} cursor-pointer`}>
               <td className="text-center">
                  <div className="checkbox-btl">
                     <input type="checkbox" checked={false} />
                     <div className="squad" />
                  </div>
               </td>
               <td>
                  <div>
                     <b>{formatDate(transaction.boleto_provisional.created_at)}</b>
                     <br />
                     <small className="hour">
                        <FontAwesomeIcon icon={faClock} /> {formaHour(transaction.boleto_provisional.created_at)}
                     </small>
                  </div>
               </td>
               <td>
                  {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                  {!transaction.payment_method?.holder_name && (
                     <>
                        {transaction.customer?.first_name} {transaction.customer?.last_name}
                     </>
                  )}
               </td>

               <td>Gerando...</td>

               <td>
                  <b>{formatDate(transaction.boleto_provisional.expiration_date)}</b>
               </td>

               <td className="text-center">
                  <BookletsBoletoStatus status={transaction.boleto_provisional.status} />
               </td>

               <td className="text-right text-nowrap">
                  <b>{formatPrice(transaction.original_amount * 100)}</b>
               </td>

               <td className="text-right text-nowrap">--</td>
               <td className="invisible">
                  <span className="btn-icon ml-2">
                     <FontAwesomeIcon icon={faPaperPlane} />
                  </span>
                  <a className="btn-icon" target="_blank">
                     <FontAwesomeIcon icon={faBarcode} />
                  </a>
               </td>
            </tr>
         </Fade>
      );
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td className="text-center">
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     checked={selected}
                     onChange={(ev) => {
                        setSelected(transaction.id, ev.target.checked);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td className="text-nowrap">
               <CopyToClipboard
                  text={transaction.id}
                  onCopy={() =>
                     ToastList.show({
                        message: 'ID copiado com sucesso',
                        type: 'black',
                     })
                  }
               >
                  <span>
                     {transaction.id?.substr(0, 4)}...{transaction.id?.substr(-4)}
                     &nbsp;
                     <FontAwesomeIcon icon={faCopy} />
                  </span>
               </CopyToClipboard>
            </td>
            <td onClick={openTransaction}>
               <div>
                  <b>{formatDate(transaction.created_at)}</b>
                  <br />
                  <small className="hour">
                     <FontAwesomeIcon icon={faClock} /> {formaHour(transaction.created_at)}
                  </small>
               </div>
            </td>
            <td>
               {!!transaction?.customer?.id && (
                  <Link className="link_ref" to={`/customers/edit/${transaction.customer.id}`}>
                     {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                     {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </Link>
               )}

               {!transaction?.customer?.id && (
                  <>
                     {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                     {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </>
               )}
            </td>

            <td onClick={openTransaction}>
               <b>{formatDate(transaction.expiration_date)}</b>
            </td>
            <td className="text-center" onClick={openTransaction}>
               <PaymentStatus transaction={transaction} />
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{formatPrice(transaction.original_amount * 100)}</b>
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{transaction.amount_paid ? formatPrice(transaction.amount_paid * 100) : '--'}</b>
            </td>
            <td className="text-right text-nowrap">
               {!transaction.cancel && (
                  <span onClick={() => cancelBoleto(transaction.id, !transaction.cancel)} className="btn-icon ml-2">
                     <FontAwesomeIcon icon={faTimes} /> Cancelar
                  </span>
               )}

               <span onClick={() => sendSMS(transaction.id)} className="btn-icon ml-2">
                  <FontAwesomeIcon icon={faPaperPlane} />
               </span>
               <a href={transaction.url} className="btn-icon" target="_blank">
                  <FontAwesomeIcon icon={faBarcode} />
               </a>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);
