import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Style, { ProgressColor } from './PaymentsCancelPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { useGroupsCreatePage } from '~/pages/PaymentsCancel/PaymentsCancelPage.controller';
import { formatBarCode, formatDate, formatPrice } from '~/services';
import { PaymentType } from '~/components/PaymentType';

export const PaymentsCancelPage: React.FunctionComponent<any> = () => {
   const ctrl = useGroupsCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Estornar venda #{ctrl.id}</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            {ctrl.resource && (
               <Card>
                  <div className={`title-price title-${ctrl.resource.status}`}>
                     <div className="d-flex">
                        <div>Valor</div>
                        <div className="flex-fill text-right">
                           {ctrl.resource.original_amount === ctrl.resource.amount ? (
                              <span>{formatPrice(ctrl.resource.amount, 2)}</span>
                           ) : (
                              <>
                                 <span>
                                    <small className="text-decoration-line-through">
                                       {formatPrice(ctrl.resource.original_amount, 2)}
                                    </small>{' '}
                                    {formatPrice(ctrl.resource.amount, 2)}
                                 </span>
                              </>
                           )}
                        </div>
                     </div>
                  </div>
                  <div className="p-4">
                     <h5>Informações da transação</h5>
                     <p />

                     <div className="row">
                        <div className="col">
                           <p>
                              Status da venda
                              <br />
                              <PaymentStatus transaction={ctrl.resource} />
                           </p>
                        </div>

                        <div className="col">
                           <p>
                              Tipo da venda <br />
                              <b>
                                 <PaymentType transaction={ctrl.resource} />
                              </b>
                           </p>
                        </div>
                     </div>

                     <div className="row">
                        {ctrl.resource.payment_type == 'credit' && (
                           <div className="col">
                              <p>
                                 Parcelado em <br />
                                 <b>{ctrl.resource.installment_plan?.number_installments || 1} vezes</b>
                              </p>
                           </div>
                        )}
                        <div className="col">
                           <p>
                              ID da transação
                              <br />
                              <b>{ctrl.resource.id}</b>
                           </p>
                        </div>
                     </div>

                     <p>
                        Descrição
                        <br />
                        <b>{ctrl.resource.description}</b>
                     </p>
                  </div>
               </Card>
            )}
         </div>

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input
                              name="amount"
                              title="Valor a cancelar"
                              number
                              placeholder="0,00"
                              decimals={2}
                              prefix="R$ "
                              textRight
                           />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Estornar venda</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
