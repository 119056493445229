import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   white-space: nowrap;
   vertical-align: middle;

   .card {
      display: inline-block;
      height: 14px;
      width: 22px;
      margin-right: 5px;
   }
`;
