import React, { useEffect, useRef, useState } from 'react';
import dateFNS from 'date-fns';
import { convertStringToDate } from '@devesharp/react/web';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Style from './PaymentType.style';
import { BrandMasterCard, BrandVisa, Boleto, BrandElo, Pix } from '~/assets/images';

export function PaymentType({ transaction }: any): any {
   switch (transaction.payment_type) {
      case 'commission':
         return <Style>Comissão</Style>;
      case 'boleto':
         return (
            <Style>
               <img src={Boleto} className="card" /> Boleto
            </Style>
         );
      case 'debit':
         return (
            <Style>
               {transaction.payment_method.card_brand.toLowerCase() === 'mastercard' && (
                  <img src={BrandMasterCard} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'elo' && (
                  <img src={BrandElo} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'visa' && (
                  <img src={BrandVisa} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'visa electron' && (
                  <img src={BrandVisa} className="card" />
               )}
               Débito
            </Style>
         );
      case 'pix':
         return (
            <Style>
               <img src={Pix} className="card" />
               Pix
            </Style>
         );
      case 'credit':
         return (
            <Style>
               {transaction.payment_method.card_brand.toLowerCase() === 'mastercard' && (
                  <img src={BrandMasterCard} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'elo' && (
                  <img src={BrandElo} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'visa' && (
                  <img src={BrandVisa} className="card" />
               )}
               {transaction.payment_method.card_brand.toLowerCase() === 'visa electron' && (
                  <img src={BrandVisa} className="card" />
               )}

               {transaction?.installment_plan?.number_installments > 1
                  ? `Crédito parcelado ${transaction?.installment_plan?.number_installments}x`
                  : 'Crédito à vista'}
            </Style>
         );
   }

   return null;
}
