import React, { useEffect, useRef, useState } from 'react';
import { convertStringToDate, useViewForm } from '@devesharp/react/web';
import * as Yup from 'yup';
import { Observable } from 'rxjs';
import Datefns from 'date-fns';
import { map } from 'rxjs/operators';
import * as lodash from 'lodash';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { CustomersSearchResolve, CustomersUpdateResolve, GroupsSearchResolve } from '~/api';
import { states } from '~/services/data';
import { CPFValidation, OnlyNumbers, searchCEP } from '~/services';
import {sellerSelectState, sellerShowInfoState} from '~/recoil/seller-select.state';

/**
 * Validação do Cartão
 */
const yupValidateSchema = Yup.object().shape({
   first_name: Yup.string().when('person_type', {
      is: '1',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   last_name: Yup.string().when('person_type', {
      is: '1',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   business_name: Yup.string().when('person_type', {
      is: '2',
      then: Yup.string().required('Esse campo é obrigatório!'),
   }),
   taxpayer_id: Yup.string()
      .required('Esse campo é obrigatório!')
      .when('person_type', {
         is: '2',
         then: Yup.string().test('cpf-length', 'Digite o CNPJ corretamente', (val): any => {
            const newVal = val?.replace(/\D/g, '');

            return newVal?.length === 14;
         }),
      })
      .when('person_type', {
         is: '1',
         then: Yup.string().test('cpf-valid', 'Digite um CPF válido', CPFValidation),
      }),
   phone_number: Yup.string()
      .required('Esse campo é obrigatório!')
      .test('length', 'Digite o celular corretamente', (val): any => {
         const newVal = val?.replace(/\D/g, '');

         return newVal?.length === 10 || newVal?.length === 11;
      }),
   email: Yup.string().email('Email Inválido!').required('Esse campo é obrigatório!'),
   // socialName: Yup.string().when('person_type', {
   //    is: 2,
   //    then: Yup.string().required('Esse campo é obrigatório!'),
   // }),
   address_postal_code: Yup.string()
      .required('Esse campo é obrigatório!')
      .test('length', 'Digite o CEP corretamente', (val): any => {
         const newVal = val?.replace(/\D/g, '');

         return newVal?.length === 8;
      }),
   address_address: Yup.string().required('Esse campo é obrigatório!'),
   address_number: Yup.string().required('Esse campo é obrigatório!'),
   address_neighborhood: Yup.string().required('Esse campo é obrigatório!'),
   address_city: Yup.string().required('Esse campo é obrigatório!'),
   address_state: Yup.string().required('Esse campo é obrigatório!'),
});

export function usePaymentsCreateStep2({ onSubmit }: any): any {
   const { id } = useParams<any>();
   const resourceOriginal = useRef<any>({});

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      resolveCreate: (data) =>
         new Observable((obs) => {
            let changeCustomer = OnlyNumbers(data.taxpayer_id) != OnlyNumbers(resourceOriginal.current.taxpayer_id);
            changeCustomer = changeCustomer || data.first_name != resourceOriginal.current.first_name;
            changeCustomer = changeCustomer || data.last_name != resourceOriginal.current.last_name;
            changeCustomer = changeCustomer || data.business_name != resourceOriginal.current.business_name;

            // Caso mude algum dado crucial, deve criar outro contato, se não, apenas atualizar
            if (changeCustomer) {
               data.id = null;
            }

            const newData = {
               customer_id: data.id,
               customer: data,
            };

            onSubmit(newData);

            obs.next({});
            obs.complete();
         }),
      resolveUpdate: (params) => CustomersUpdateResolve(id, params),
      resolves: {
         groups: GroupsSearchResolve,
      },
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         if (newForm.card) {
            newForm.card.type = newForm.type;
         }

         if (newForm.card) {
            newForm.card.type = newForm.type;
         }

         newForm.discount_date = formData?.discount_date?.dateYYYYMMDD;
         newForm.expiration_date = formData?.expiration_date?.dateYYYYMMDD;

         if (formData?.group_id?.value != undefined) {
            newForm.group_id = formData.group_id?.value;
         }

         return newForm;
      },
      yupValidateSchema,
   });

   const [groups, setGroups] = useState<any[]>([]);
   const [personType, setPersonType] = useState(1);

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   const [sellerShowInfo, setShowInfoSeller] = useRecoilState(sellerShowInfoState);
   
   const initialData = {};

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }
   });

   const formInfoData = {
      groups: [
         {
            value: '',
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            value: group.id,
            name: group.name,
         })),
      ],
      person_type: [
         {
            value: 1,
            name: 'Pessoa física',
         },
         {
            value: 2,
            name: 'Pessoa júrica',
         },
      ],
      states: states.map((state) => ({ name: state.name, value: state.id })),
   };

   function groupResolve(name: string) {
      return GroupsSearchResolve({ limit: 5, name }).pipe(map((response) => response.results));
   }

   function customersResolve(customer_name: string): any {
      return CustomersSearchResolve({ limit: 5, customer_name }).pipe(map((response) => response.results));
   }

   function onChangePersonType() {
      setPersonType(formRef.current.getFieldValue('person_type'));
   }

   function getValueCustomers(customer: any) {
      setPersonType(customer.person_type);

      setTimeout(() => {
         formRef.current.setData(customer);
         resourceOriginal.current = customer;
      }, 100);
   }

   const [isLoadingCep, setIsLoadingCep] = useState(false);
   const ref = useRef('');

   function onChangeCep(): any {
      const cep = OnlyNumbers(formRef.current.getFieldValue('address_postal_code'));
      if (cep.length == 8 && ref.current !== cep) {
         setIsLoadingCep(true);
         formRef.current.setFieldError('address_postal_code', '');
         searchCEP(cep)
            .then((e: any) => {
               setIsLoadingCep(false);
               const address = (formRef.current.getData() as any).address ?? {};
               formRef.current.setData({
                  ...formRef.current.getData(),
                  address_address: e.data.street,
                  address_city: e.data.city,
                  address_neighborhood: e.data.neighborhood,
                  address_state: e.data.state,
               });
            })
            .catch(() => {
               setIsLoadingCep(false);
               formRef.current.setFieldError('address_postal_code', 'CEP não encontrado');
            });
      }
      ref.current = cep;
   }

   return {
      onSubmit: onSubmitForm,
      groupResolve,
      customersResolve,
      getValueCustomers,
      onChangePersonType,
      onChangeCep,
      personType,
      formRef,
      sellerShowInfo,
      reloadPage,
      initialData,
      starting,
      saving,
      errorLoadData,
      formInfoData,
      sellerSelect,
      isLoadingCep,
   };
}
