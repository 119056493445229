import { ITheme } from '@devesharp/react/dist/components1/AppProvider/ITheme';

export const ThemeVariables: Partial<ITheme & any> = {
   primary: '#29235C',
   secondary: '#35a8e0',
   header: {
      backgroundColor: '#29245c',
      textColor: 'white',
      textColorUsername: 'white',
      textColorMenu: 'white',
   },
};
