import styled from 'styled-components';

const css = styled.div;

export const ProgressColor = css<any>`
   .progress-bar {
      background-color: ${(props) => props.color} !important;
   }
`;

export default css`
   position: relative;
   
   canvas {
      z-index: 200;
      position: relative;
   }

   .value-inside-graph {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #3498db;
      font-weight: 600;
      white-space: nowrap;
      text-shadow: 1px 1px white, 0px 1px white, 1px 0px white, -1px 1px white, -1px -1px white, -1px -1px white;

      &.expired {
         color: #c22428;
      }
   }

   .bar-container {
      margin: 10px;

      .bar-name {
         color: #34495e;
         text-align: left;
         font-size: 12px;
      }

      .progress-container {
         display: flex;
         align-items: center;
         justify-content: center;

         .progress-bar-container {
            flex: 9;
         }

         .progress-value {
            flex: 1;
            padding-left: 10px;
            font-size: 12px;
            color: #6c6c6c;
         }
      }

      .progress {
         height: 0.5rem;
         background: #ddd;
      }
   }
`;
