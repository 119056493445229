import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   .badge {
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 200px;
      color: white;
      border: 1px solid #eee;
   }

   .undefined {
      border-color: #000;
      color: #000;
   }

   .pedding {
      border-color: #f9b955;
      color: #f9b955;
   }

   .badge-succeeded,
   .badge-paid {
      border-color: #56c470;
      color: #56c470;
   }

   .badge-deleted,
   .badge-canceled {
      border-color: #dc3545 !important;
      color: #dc3545 !important;
   }

   .badge-refunded {
      border-color: #222 !important;
      color: #222 !important;
   }

   .badge-created {
      border-color: #3caaff !important;
      color: #3caaff !important;
   }

   .badge-scheduled {
      border-color: #f9b955 !important;
      color: #f9b955 !important;
   }
`;
