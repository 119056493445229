export const limitsFeesData: any = {
   online: {
      'Débito - Banescard': {
         tarifa: '1,90%',
      },
      'Débito - Hiper': {
         tarifa: '1,90%',
      },
      'Débito - Diners Club': {
         tarifa: '1,90%',
      },
      'Débito - JCB': {
         tarifa: '1,90%',
      },
      'Débito - Discover': {
         tarifa: '1,90%',
      },
      'Débito - Maestro': {
         tarifa: '1,90%',
      },
      'Débito - Elo': {
         tarifa: '2,59%',
      },
      'Débito - Visa Electron': {
         tarifa: '1,90%',
      },
      'Débito - Aura': {
         tarifa: '1,90%',
      },
      'Débito - Hipercard': {
         tarifa: '1,90%',
      },
      'Crédito à vista - Diners Club': {
         tarifa: '5,00%',
      },
      'Crédito à vista - JCB': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Discover': {
         tarifa: '5,00%',
      },
      'Crédito à vista - MasterCard': {
         tarifa: '5,00%',
      },
      'Crédito à vista - American Express': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Elo': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Visa': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Aura': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Hipercard': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Banescard': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Hiper': {
         tarifa: '5,00%',
      },
      'Crédito (2x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (7x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Hiper': {
         tarifa: '10,00%',
      },
   },
   presencial: {
      'Débito - Banescard': {
         tarifa: '1,90%',
      },
      'Débito - Hiper': {
         tarifa: '1,90%',
      },
      'Débito - Diners Club': {
         tarifa: '1,90%',
      },
      'Débito - JCB': {
         tarifa: '1,90%',
      },
      'Débito - Discover': {
         tarifa: '1,90%',
      },
      'Débito - Maestro': {
         tarifa: '1,90%',
      },
      'Débito - Elo': {
         tarifa: '2,59%',
      },
      'Débito - Visa Electron': {
         tarifa: '1,90%',
      },
      'Débito - Aura': {
         tarifa: '1,90%',
      },
      'Débito - Hipercard': {
         tarifa: '1,90%',
      },
      'Crédito à vista - Hiper': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Diners Club': {
         tarifa: '5,00%',
      },
      'Crédito à vista - JCB': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Discover': {
         tarifa: '5,00%',
      },
      'Crédito à vista - MasterCard': {
         tarifa: '5,00%',
      },
      'Crédito à vista - American Express': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Elo': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Visa': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Aura': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Hipercard': {
         tarifa: '5,00%',
      },
      'Crédito à vista - Banescard': {
         tarifa: '5,00%',
      },
      'Crédito (2x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (2x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (3x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (4x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (5x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Banescard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Hiper': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Diners Club': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - JCB': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Discover': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - MasterCard': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - American Express': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Elo': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Visa': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Aura': {
         tarifa: '7,50%',
      },
      'Crédito (6x) - Hipercard': {
         tarifa: '7,50%',
      },
      'Crédito (7x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (7x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (8x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (9x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (10x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (11x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Banescard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Hiper': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Diners Club': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - JCB': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Discover': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - MasterCard': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - American Express': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Elo': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Visa': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Aura': {
         tarifa: '10,00%',
      },
      'Crédito (12x) - Hipercard': {
         tarifa: '10,00%',
      },
      'Boleto - Contrato Padrão': {
         tarifa: 'R$ 3,00',
      },
   },
};