import React from 'react';
import Style from './LoadingPage.style';

export const LoadingPage: React.FunctionComponent<{}> = () => {
   return (
      <Style>
         <div className="loading-icon">
            <div className="load">
               <div />
            </div>
         </div>
      </Style>
   );
};
