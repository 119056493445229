import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useLocation, useParams } from 'react-router-dom';
import {
   APIServiceUrl,
   DataDashResolve,
   GenerateExcel,
   ReceivablesSearchByDayResolve,
   ReceivablesSearchResolve,
   TransfersSearchResolve,
} from '~/api';

export function usePaymentsListingPage(): any {
   document.title = 'Lista de operações - XPAG Tecnologia';
   const location = useLocation();
   const { date: dateParam } = useParams() as any;

   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnSearch,
      registerOnInit,
      resources,
      resourcesTotal,
      skeletonResources,
      setFilters,
      setOffset,
      setPage,
      filters,
   } = useViewList({
      resolves: {
         items: ReceivablesSearchResolve,
      },
      filtersDefault: {
         date: dateParam,
      },
   });

   const [date, setDate] = useState(dateParam.replaceAll('-', '/'));

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();

   const [sum, setSum] = useState(0);
   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();

   useLayoutEffect(() => {
      return registerOnInit((v: any) => {
         setSum(v.items.sum);
      });
   }, []);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      // window.scrollTo(0, 0);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   const [loading, setLoading] = useState(false);

   const filtersData = {
      types: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: 'credit',
            name: 'Cartão de crédito',
         },
         {
            id: 'boleto',
            name: 'Boleto',
         },
      ],
      status: [
         {
            id: '',
            name: 'Todos',
         },
         { id: 'succeeded', name: 'Aprovada' },
         { id: 'canceled', name: 'Cancelada' },
         { id: 'failed', name: 'Falhada' },
         { id: 'pending', name: 'Pendente' },
      ],
   };

   function onSubmit(data: any): void {
      const params = data;
      params.created_at_gte = data?.created_at_gte?.dateYYYYMMDD;
      params.created_at_lte = data?.created_at_lte?.dateYYYYMMDD;
      params.transfer_date_gte = data?.transfer_date_gte?.dateYYYYMMDD;
      params.transfer_date_lte = data?.transfer_date_lte?.dateYYYYMMDD;
      params.pay_date_lte = data?.pay_date_lte?.dateYYYYMMDD;
      params.pay_date_gte = data?.pay_date_gte?.dateYYYYMMDD;

      setSum(0);
      setFilters(() => params);
   }

   function generateExcel(): void {
      setLoading(true);
      if (selected.size) {
         GenerateExcel({ ids: Array.from(selected.keys()) }, 'transfers').subscribe(
            (link) => {
               onUnselectAll();
               setLoading(false);
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               onUnselectAll();
               setLoading(false);
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcel(filters, 'transfers').subscribe(
            (link) => {
               onUnselectAll();
               setLoading(false);
               window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               onUnselectAll();
               setLoading(false);
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
   }

   return {
      date,
      onSubmit,
      formRef,
      reloadPage,
      starting,
      searching,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      sum,
      loading,
      generateExcel,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
   };
}
