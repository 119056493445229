import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Skeleton from 'react-loading-skeleton';
import Style, { ProgressColor } from './LimitsAndFeesPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { limitsFeesData } from '~/services/limitsfeesData';

export const LimitsAndFeesPage: React.FunctionComponent<any> = () => {
   document.title = 'Limites e taxas - XPAG Tecnologia';

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Limites e taxas</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="container m-auto">
            <Card className="p-4">
               <Table striped bordered hover className="listing">
                  <thead>
                     <tr>
                        <th>Taxa de saques</th>
                        <th className="text-right">Tarifa</th>
                     </tr>
                  </thead>
                  <tbody>
                     <td>Débito - Banescard</td>
                     <td className="text-right">1,90%</td>
                  </tbody>
               </Table>

               <br />

               <Table striped bordered hover className="listing">
                  <thead>
                     <tr>
                        <th />
                        <th>Tarifa Venda Online</th>
                        <th>Tarifa Venda Presencial</th>
                     </tr>
                  </thead>
                  <tbody>
                     {Object.entries(limitsFeesData.online).map(([name, data]: any) => (
                        <tr>
                           <td>{name}</td>
                           <td className="text-right">{data.tarifa}</td>
                           <td className="text-right">{limitsFeesData.presencial[name]?.tarifa ?? '--'}</td>
                        </tr>
                     ))}

                     {Object.entries(limitsFeesData.presencial).map(([name, data]: any) => (
                        <>
                           {!limitsFeesData.online[name] && (
                              <tr>
                                 <td>{name}</td>
                                 <td className="text-right">--</td>
                                 <td className="text-right">{data.tarifa}</td>
                              </tr>
                           )}
                        </>
                     ))}
                  </tbody>
               </Table>
            </Card>
         </div>
      </Style>
   );
};
