import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useView,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   useSelectGroup,
   ToastList,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { useDidUpdateDeepCompareEffect } from '@devesharp/react/dist/hooks/useDidUpdateDeepCompareEffect/index';
import { useRecoilState } from 'recoil';
import {
   APIServiceUrl,
   BoletoCancelManyResolve,
   BoletoCancelResolve,
   BookletsCancelResolve,
   BookletsGetResolve,
   GenerateExcel,
   SendSMSBoletoResolve,
} from '~/api';
import history from '~/config/history';
import { formatDateBar } from '~/services';
import { fileDownloadGlobalState } from '~/recoil/file-donwload-global.state';

export function useBookletsDetailPage(): any {
   const { id } = useParams() as any;

   const { starting, errorLoadData, reloadPage, registerResolveParams, registerOnInit } = useView({
      resolves: {
         resource: BookletsGetResolve,
      },
   });

   const [resource, setResource] = useState<any>();
   const formRef = useRef<FormHandles>();
   const [fileDownloadGlobal, setFileDownloadGlobal] = useRecoilState(fileDownloadGlobalState);

   registerResolveParams('resource', () => id);

   registerOnInit((res: any) => {
      setResource(res.resource);

      document.title = `${res.resource.name} - XPAG Tecnologia`;
   });

   useDeepCompareEffectNoCheck(() => {
      const interval = setInterval(() => {
         const notFinished = resource?.boletos?.some((i: any) => i.boleto_provisional.status != 'created');
         if (notFinished || !resource?.url_pdf) {
            BookletsGetResolve(id).subscribe((res: any) => {
               setResource(res);
            });
         }
      }, 2000);

      return () => clearInterval(interval);
   }, [resource]);

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();
   const [loading, setLoading] = useState(false);

   function sendSMS(): void {
      setLoading(true);

      SendSMSBoletoResolve(Array.from(selected.keys())).subscribe(
         () => {
            onUnselectAll();
            setLoading(false);
            ToastList.show({
               message: 'SMS enviados com sucesso',
            });
         },
         () => {
            setLoading(false);
            ToastList.show({
               message: 'Erro ao enviar SMS',
               type: 'error',
            });
         },
      );
   }

   function sendOne(id: string): void {
      setLoading(true);

      SendSMSBoletoResolve([id]).subscribe(
         () => {
            onUnselectAll();
            setLoading(false);
            ToastList.show({
               message: 'SMS enviado com sucesso',
            });
         },
         () => {
            setLoading(false);
            ToastList.show({
               message: 'Erro ao enviar SMS',
               type: 'error',
            });
         },
      );
   }

   function openUrlBooklet(): void {
      if (resource.url_pdf != '') {
         window.open(resource.url_pdf, '_blank');
      }
   }

   function cancelBooklet(): void {
      const confirmed = window.confirm('Deseja realmente cancelar o carnê? Todos os boletos serão cancelados');

      if (confirmed) {
         BookletsCancelResolve(id).subscribe(() => {
            ToastList.show({
               message: 'Carnê cancelado com sucesso',
               type: 'success',
            });

            history.replace(`/booklets`);
         });
      }
   }

   function generateExcel(): void {
      if (selected.size) {
         GenerateExcel({ ids: Array.from(selected.keys()) }, 'boletos').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
               // window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcel({ ids: resource?.boletos?.map((i: any) => i.id) }, 'boletos').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
   }

   const [modalCancel, setModalCancel] = useState(false);
   const idCancel = useRef<any>(0);

   function onCancelModal(data: any): void {
      if (!Array.isArray(idCancel.current)) {
         const confirm = window.confirm(`Tem certeza que deseja cancelar o boleto? Essa ação não poderá ser desfeita.`);

         if (!confirm) return;

         setLoading(true);
         BoletoCancelResolve(idCancel.current, data).subscribe(
            () => {
               onUnselectAll();
               reloadPage(false);
               setLoading(false);
               ToastList.show({
                  message: 'Boleto cancelado com sucesso!',
               });
               setModalCancel(false);
            },
            () => {
               setLoading(false);
               ToastList.show({
                  message: 'Erro ao cancelar boleto',
                  type: 'error',
               });
            },
         );
      } else {
         const confirm = window.confirm(
            `Tem certeza que deseja cancelar esses boletos? Essa ação não poderá ser desfeita.`,
         );

         if (!confirm) return;

         setLoading(true);

         const ids = Array.from(selected.keys());

         BoletoCancelManyResolve({
            ids,
            ...data,
         }).subscribe(
            () => {
               onUnselectAll();
               reloadPage(false);
               setLoading(false);
               setModalCancel(false);
               ToastList.show({
                  message: 'Boletos cancelados com sucesso!',
               });
            },
            () => {
               setLoading(false);
               ToastList.show({
                  message: 'Erro ao cancelar boletos',
                  type: 'error',
               });
            },
         );
      }
   }

   function cancelBoleto(_id: string, cancel: boolean): void {
      idCancel.current = _id;
      setModalCancel(true);
   }

   function cancelMany(_id: string, cancel: boolean): void {
      idCancel.current = Array.from(selected.keys());
      setModalCancel(true);
   }

   return {
      starting,
      loading,
      sendSMS,
      sendOne,
      cancelBoleto,
      cancelMany,
      errorLoadData,
      openUrlBooklet,
      cancelBooklet,
      resource,
      onSelect,
      onUnselectAll,
      selected,
      checkSelected,
      generateExcel,
      modalCancel,
      setModalCancel,
      onCancelModal,
   };
}
