import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   .badge {
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 200px;
      color: white;
   }

   .pedding {
      background: #f9b955;
   }

   .badge-succeeded {
      background: #56c470;
   }

   .badge-expired {
      background: #dc3545 !important;
   }

   .badge-pending {
      background: #f9b955 !important;
   }
`;
