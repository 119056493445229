import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faCalendarCheck,
   faBarcode,
   faClock,
   faLongArrowAltUp,
   faLongArrowAltDown,
   faArrowUp,
   faArrowDown,
   faAngleDown,
   faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   FlexContainer,
   FlexItem,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
} from '@devesharp/react/web';
import * as QueryString from 'querystring';
import { Form } from '@unform/web';
import { FormHelpers } from '@unform/core/typings/types';
import Style, { ProgressColor } from './ReceivablesListingPage.style';
import { PaymentStatus, Header } from '~/components';
import {CNPJAndCPFMask, formaHour, formatDate, formatDateBar, formatPrice} from '~/services';
import { usePaymentsListingPage } from './ReceivablesListingPage.controller';
import { PaymentType } from '~/components/PaymentType';
import history from '~/config/history';
import { TransferType } from '~/components/PaymentType/TransferType';
import { TransferStatus } from '~/components/PaymentStatus/TransferStatus';

export const ReceivablesListingPage: React.FunctionComponent<any> = () => {
   const ctrl = usePaymentsListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de Pagamentos futuros</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expected_date_gte" title="Data Previsão de" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expected_date_lte" title="Data Previsão até" />
                     </div>
                     
                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="pb-3 text-dark">
                           Mostrando <b>1</b>-<b>{ctrl.resourcesTotal < 20 ? ctrl.resourcesTotal : 20}</b> de <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div>
                     <Button rounded outline variant="success" onClick={ctrl.generateExcel}>
                        <FontAwesomeIcon icon={faFileExcel} /> <b>Gerar Excel</b>
                     </Button>
                  </div>
               </div>

               <div className="text-right pb-2">
                  {ctrl.sum ? (
                     <>
                        Total: <b className="price text-success">{formatPrice((ctrl.sum ?? 0) * 100)}</b>{' '}
                     </>
                  ) : (
                     <>--</>
                  )}
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th />
                           <th>
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Data previsão'}
                           </th>
                           {ctrl.showSeller && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? (
                                    <Skeleton height={20} width={100} />
                                 ) : (
                                    'Estabelecimento'
                                 )}
                              </th>
                           )}
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 'Qtd. de vendas'
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Taxa'}
                           </th>

                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Valor bruto'}
                           </th>

                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Valor final'}
                           </th>
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((transaction: any) => (
                           <ItemListing
                              key={transaction.id}
                              transaction={transaction}
                              selected={ctrl.checkSelected(transaction.id)}
                              setSelected={ctrl.onSelect}
                              showEstabelecimento={ctrl.showSeller}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({ transaction, skeleton, selected, setSelected, showEstabelecimento }: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr>
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
         </tr>
      );
   }

   if (transaction?.sum || transaction?.sum === 0) return null;

   function openTransaction(): void {
      history.push(`/receivables/date/${formatDateBar(transaction?.expected_on)}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`cursor-pointer ${selected && 'selected'}`}>
            <td className="text-center">
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     value={selected}
                     onChange={(ev) => {
                        setSelected(transaction.id, ev.target.checked);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td onClick={openTransaction}>
               <div>
                  <b>{formatDate(transaction?.expected_on)}</b>
                  <br />
                  <small className="hour">
                     {/* <FontAwesomeIcon icon={faClock} /> {formaHour(transaction.created_at)} */}
                  </small>
               </div>
            </td>

            {/* <td onClick={openTransaction}> */}
            {/*   <div> */}
            {/*      <b>{formatDate(transaction.transfer_date ?? transaction.created_at)}</b> */}
            {/*      <br /> */}
            {/*      <small className="hour"> */}
            {/*         <FontAwesomeIcon icon={faClock} />{' '} */}
            {/*         {formaHour(transaction.transfer_date ?? transaction.created_at)} */}
            {/*      </small> */}
            {/*   </div> */}
            {/* </td> */}
            {/* <td onClick={openTransaction}> */}
            {/*   <SellerSelect transaction={transaction.payment} /> */}
            {/* </td> */}
            {showEstabelecimento && (
               <td onClick={openTransaction}>
                  <div className="text-dark">
                     <b>{transaction.seller_name}</b>
                  </div>
                  <div>
                     {transaction?.seller_document?.length > 11 ? 'CNPJ' : 'CPF'}:{' '}
                     {CNPJAndCPFMask(transaction?.seller_document)}
                  </div>
               </td>
            )}
            
            <td className="text-center" onClick={openTransaction}>
               <b>{transaction.quantity}</b>
            </td>
            <td onClick={openTransaction} className="text-right text-nowrap">
               <b>{formatPrice((transaction.gross_amount - transaction.amount) * 100)}</b>
            </td>
            <td onClick={openTransaction} className="text-right text-nowrap">
               <b>{formatPrice(transaction.gross_amount * 100)}</b>
            </td>
            <td onClick={openTransaction} className="text-right text-nowrap">
               <b>{formatPrice(transaction.amount * 100)}</b>
               <span className="text-success">
                  <FontAwesomeIcon icon={faArrowUp} />
               </span>
            </td>
            {/* <td onClick={openTransaction} className="text-right"> */}
            {/*   <a href={transaction.} className="btn-icon"> */}
            {/*      PDF{'  '} */}
            {/*      <FontAwesomeIcon icon={faArrowDown} /> */}
            {/*   </a> */}
            {/* </td> */}
         </tr>
      </Fade>
   );
}, _.isEqual);
