import { IAction } from '~/redux/interfaces';
import { IUserReducer, typeUpdateUser } from '~/redux/modules/user/user.interface';

export function UserReducer(state = null, action: IAction<IUserReducer>): IUserReducer | null {
   switch (action.type) {
      case typeUpdateUser:
         return action.payload;
      case 'empty':
         return null;
      default:
         return state;
   }
}
