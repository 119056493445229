import React, { useEffect, useRef, useState } from 'react';
import DateFNS from 'date-fns';
import { convertStringToDate } from '@devesharp/react/web';
import Style from './PaymentStatus.style';

export function TransferStatus({ transaction, status }: any): any {
   let text = '';
   const currentStatus = transaction?.status || status;

   switch (currentStatus) {
      case 'succeeded':
         text = 'Aprovada';
         break;
      case 'canceled':
         text = 'Cancelada';
         break;
      case 'blocked':
         text = 'Bloqueada';
         break;
      case 'charged_back':
         text = 'Charge back';
         break;
      case 'dispute':
         text = 'Disputa';
         break;
      case 'failed':
         text = 'Falhada';
         break;
      case 'pending':
         text = 'Pendente';
         break;
      case 'reversed':
         text = 'Pré-autorizado';
         break;
      case 'refunded':
         text = 'Revertida';
         break;
   }

   return (
      <Style>
         <div className={`badge badge-${currentStatus}`}>{text}</div>
      </Style>
   );
}
