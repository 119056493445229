import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   position: relative;
   display: inline-flex;
   cursor: pointer;
   padding-bottom: 30px;

   .arrow {
      padding-left: 10px;
      padding-bottom: 2px;
   }

   .text-title {
      font-size: 12px;
      margin-bottom: -2px;
   }

   .selected2 {
      color: ${(props) => props.theme.secondary};
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 290px;
      width: 100%;
   }

   .input-border {
      max-width: 290px;
      width: 100%;
      min-height: 35px !important;
      padding: 0px 10px;
      display: flex;
      background: rgba(255, 255, 255, 0.2);
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .input-container--inner {
         flex: 1;
         height: 100%;
         display: flex;
         color: white;
         white-space: nowrap;
         overflow: hidden;
         font-size: 12px;
         align-items: center;

         &.place-holder {
            color: rgba(255, 255, 255, 0.5);
         }
      }

      .suffix {
         display: flex;
         align-items: center;
         color: white;
      }

      input {
         width: 100%;
         border: none;
         font-size: 12px !important;
      }
   }

   .dropdown {
      width: 100%;
      border-radius: 2px;
      margin-top: 0px;
      left: 0px;
      bottom: 0px;
      transform: translateY(100%);
      position: absolute;
      z-index: 1000;
      background: white;
      box-shadow: 0px 3px 11px rgb(0 0 0 / 24%);
      overflow: hidden;
      text-align: left;

      background-color: rgb(255, 255, 255);
      //box-shadow: rgb(235, 235, 235) 0px 3px 7px;
      border: 1px solid rgb(235, 235, 235);
      border-radius: 0px;

      .search {
         padding: 5px;

         .input-border {
            background: #eee;
            border: none;
            width: 100%;
            border: none !important;
            min-height: 35px !important;
         }
      }

      .empty {
         font-size: 12px;
         font-weight: 600;
         padding: 10px;
      }

      .loading-page {
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         background: rgba(255, 255, 255, 0.5);
         display: flex;
         align-items: center;
         justify-content: center;
         width: 100%;
         height: 100%;
         z-index: 10;
      }
      .listing {
         overflow: auto;
         max-height: 300px;
         position: relative;
      }

      .item {
         padding: 10px;
         border-left: 3px solid transparent;

         .name {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }

         .document {
            font-size: 12px;
         }

         &.active {
            background: #eee;

            .name {
               color: ${(props) => props.theme.secondary};
            }

            .document {
               color: #000;
            }
         }

         &:hover {
            border-left: 3px solid ${(props) => props.theme.secondary};
            cursor: pointer;

            .name {
               color: ${(props) => props.theme.secondary};
               font-weight: bold;
            }

            .document {
               //color: white;
            }
         }
      }

      .see-all {
         a {
            display: block;
            padding: 10px;
            font-weight: bold;
            text-align: center;
         }
      }
   }
`;
