import styled from 'styled-components';

const css = styled.div;

export default css`
   b {
      color: #111;
   }

   .hour {
      font-weight: 800;
      font-size: 12px;
      color: #349bd3;
   }

   .btn-comprovante {
      background: #29235c;
      color: white;
      padding: 7px 20px;
      border: none;
      border-radius: 100px;
   }

   .btn-sync {
      background: #f9b955;
      color: white;
      padding: 7px 20px;
      border: none;
      border-radius: 100px;
      margin-right: 10px;
   }

   .price {
      font-size: 18px;
      font-weight: bold;
   }

   .title-price {
      color: white;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
   }

   .title-black {
      background: black;
   }

   .title-succeeded {
      background: #56c470;
   }

   .title-canceled {
      background: #dc3545 !important;
   }

   .title-failed {
      background: #e35663 !important;
   }

   .title-blocked {
      background: #222 !important;
   }

   .title-charged_back {
      background: #888 !important;
   }

   .title-dispute {
      background: #3caaff !important;
   }

   .title-pending {
      background: #f9b955 !important;
   }

   .title-reversed {
      background: #8d55f9 !important;
   }

   .title-refunded {
      background: #55a6f9 !important;
   }
`;
