import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import {
   Button,
   Modal,
   Input,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
   SelectAutocomplete,
   Radio,
   Checkbox,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Style, { ProgressColor } from './UsersCreatePage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useUsersListingPage } from '~/pages/UsersListing/UsersListingPage.controller';
import { useUsersCreatePage } from '~/pages/UsersCreate/UsersCreatePage.controller';
import { GroupsSearchResolve } from '~/api';
import { Select } from '~/components/Customs/Select';
import { Switch } from '~/components/Customs/Switch';

export const UsersCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useUsersCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">{ctrl.editing ? `Editar usuário` : 'Novo usuário'}</h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={{ role: 'SIMPLE' }}>
               <Card className="p-n">
                  <UserForm ctrl={ctrl} />
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>{ctrl.editing ? 'Editar usuário' : 'Criar novo usuário'}</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};

function UserForm({ ctrl }: any) {
   return (
      <>
         <div className="form-padding ">
            <div className="form-title">Dados do usuário</div>

            <br />

            <>
               <div className="row field-box">
                  <div className="col-md-12">
                     <Switch name="blocked" title="Bloqueado" />
                  </div>
               </div>
            </>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="name" title="Nome do vendedor" />
               </div>

               <div className="col-md-6">
                  <Select title="Cargo" options={ctrl.formInfoData.roles} name="role" onChange={ctrl.setRole} />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="email" title="Email" />
               </div>

               <div className="col-md-6">
                  <Input name="phone" title="Telefone" phone />
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="login" title="Login do usuário" />
               </div>

               <div className="col-md-6">
                  <Input name="password" title="Senha do usuário" />
                  <div className="info">Caso não queira alterar a senha, deixe o campo em branco.</div>
               </div>
            </div>

            <div className="row field-box">
               <div className="col-md-6">
                  <Input name="document" title="RG do usuário" />
               </div>

               <div className="col-md-6">
                  <Input name="CPF" title="CPF do usuário" CPF />
               </div>
            </div>
            <br />
            <hr />
            <br />
            {ctrl.role != 'MASTER' && (
               <div className="pt-2">
                  <div className="py-2">
                     <b>Estabelecimentos PF/PJ</b>
                  </div>

                  {ctrl.sellers.map((seller: any) => (
                     <div className="pt-2">
                        <Switch
                           name={`seller_${seller.id}`}
                           title={
                              <>
                                 <div>
                                    <b>{seller.name}</b>
                                 </div>
                                 <div>{CNPJAndCPFMask(seller.document)}</div>
                              </>
                           }
                        />
                     </div>
                  ))}
               </div>
            )}
         </div>
      </>
   );
}
