import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faCalendarCheck,
   faBarcode,
   faClock,
   faLongArrowAltUp,
   faLongArrowAltDown,
   faRetweet,
   faArrowUp,
   faArrowDown,
   faAngleDown,
   faPaperPlane,
   faFileExcel,
   faCopy, faSortUp, faSortDown, faSort,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   FlexContainer,
   FlexItem,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   ToastList,
} from '@devesharp/react/web';
import * as QueryString from 'querystring';
import { Form } from '@unform/web';
import { FormHelpers } from '@unform/core/typings/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Style, { ProgressColor } from './PaymentsListingPage.style';
import { PaymentStatus, Header } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import { usePaymentsListingPage } from '~/pages/PaymentsListing/PaymentsListingPage.controller';
import { PaymentType } from '~/components/PaymentType';
import history from '~/config/history';
import { SelectMany } from '~/components/Customs/SelectMany';

export const PaymentsListingPage: React.FunctionComponent<any> = () => {
   const ctrl = usePaymentsListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-3">
                  <div className="row align-items-center">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           {ctrl.pathname == '/payments/credit' ? 'Lista de cartões' : 'Lista de operações'}
                        </h3>
                     </div>
                     <div className="col text-right">
                        <Button type="submit" size="lg" onClick={() => history.push('/payments/create')}>
                           Nova venda
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     {/* <div> */}
                     {/*   <b>Filtros</b> */}
                     {/* </div> */}
                     <div className="flex-grow-1 px-2">
                        <Input name="customer_name" title="Pagador" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_gte" title="Criado a partir" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_lte" title="Criado até" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="payment_type" title="Tipo" items={ctrl.filtersData.types} />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <SelectMany name="status_transaction_array" title="Status" items={ctrl.filtersData.status} />
                        {/* <Select name="status_transaction" title="Status" items={ctrl.filtersData.status} /> */}
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="group_id" title="Grupos" items={ctrl.filtersData.groups} />
                     </div>

                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="pb-3 text-dark">
                           Mostrando <b>1</b>-<b>{ctrl.resourcesTotal < 20 ? ctrl.resourcesTotal : 20}</b> de{' '}
                           <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div>
                     <Button rounded outline variant="success" onClick={ctrl.generateExcel}>
                        <FontAwesomeIcon icon={faFileExcel} /> <b>Gerar Excel</b>
                     </Button>
                  </div>
               </div>

               <div className="text-right pb-2">
                  {ctrl.sum ? (
                     <>
                        Total Página: <b className="price text-info">{formatPrice((ctrl.sumPage ?? 0) * 100)}</b> -{' '}
                        Total: <b className="price text-success">{formatPrice((ctrl.sum ?? 0) * 100)}</b>{' '}
                     </>
                  ) : (
                     <>--</>
                  )}
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={16} width={16} />
                              ) : (
                                 <div className="checkbox-btl">
                                    <input type="checkbox" checked={ctrl.checkedAll} onChange={ctrl.selectAll} />
                                    <div className="squad" />
                                 </div>
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Data da venda"
                                    sort="created_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 'ID da transação'
                              )}
                           </th>
                           {ctrl.showSeller && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? (
                                    <Skeleton height={20} width={100} />
                                 ) : (
                                    'Estabelecimento'
                                 )}
                              </th>
                           )}
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Pagador"
                                    sort="first_name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Tipo de venda'}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Status"
                                    sort="status"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Valor"
                                    sort="amount"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           {/* <th className="text-right" /> */}
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((transaction: any) => (
                           <ItemListing
                              key={transaction.id}
                              transaction={transaction}
                              selected={ctrl.checkSelected(transaction.id)}
                              setSelected={ctrl.onSelect}
                              showEstabelecimento={ctrl.showSeller}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   transaction,
   skeleton,
   selected,
   setSelected,
   showEstabelecimento,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr>
            <td className="text-center">
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            {/* <td className="text-right" /> */}
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/payments/detail/${transaction.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`cursor-pointer ${selected && 'selected'}`}>
            <td className="text-center">
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     checked={selected}
                     onChange={(ev) => {
                        setSelected(transaction.id, ev.target.checked);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td onClick={openTransaction} className="text-nowrap">
               <div>
                  <b>{formatDate(transaction.created_at)}</b>
                  <br />
                  {formaHour(transaction.created_at)}
               </div>
            </td>
            <td className="text-nowrap">
               <CopyToClipboard
                  text={transaction.id}
                  onCopy={() =>
                     ToastList.show({
                        message: 'ID copiado com sucesso',
                        type: 'black',
                     })
                  }
               >
                  <span>
                     {transaction.id?.substr(0, 4)}...{transaction.id?.substr(-4)}
                     &nbsp;
                     <FontAwesomeIcon icon={faCopy} />
                  </span>
               </CopyToClipboard>
            </td>
            {showEstabelecimento && (
               <td onClick={openTransaction}>
                  <div className="text-dark">
                     <b>{transaction.seller_name}</b>
                  </div>
                  <div>
                     {transaction?.seller_document?.length > 11 ? 'CNPJ' : 'CPF'}:{' '}
                     {CNPJAndCPFMask(transaction?.seller_document)}
                  </div>
               </td>
            )}
            <td>
               {!!transaction?.customer?.id && (
                  <Link className="link_ref" to={`/customers/edit/${transaction.customer.id}`}>
                     {transaction.customer?.full_name ?? transaction.payment_method.holder_name}
                     {/* {!!transaction.customer?.full_name && transaction.payment_method.holder_name} */}
                     {/* {!transaction.customer?.full_name && <>{transaction.customer?.full_name}</>} */}
                     {/* {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name} */}
                     {/* {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>} */}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </Link>
               )}

               {!transaction?.customer?.id && (
                  <>
                     {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                     {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </>
               )}
               {transaction.plan_id && (
                  <div>
                     Plano: <b>{transaction?.plan_name}</b>
                  </div>
               )}
            </td>
            <td onClick={openTransaction}>
               <PaymentType transaction={transaction} />
               {transaction.plan_id && (
                  <b>
                     <span className="text-primary">
                        <FontAwesomeIcon icon={faRetweet} />
                        &nbsp; {transaction?.plan_id && 'Recorrente'}
                     </span>
                  </b>
               )}
            </td>
            <td onClick={openTransaction} className="text-center">
               <PaymentStatus transaction={transaction} />
            </td>
            <td onClick={openTransaction} className="text-right text-nowrap">
               <b>{formatPrice(transaction.amount * 100)}</b>{' '}
               <span
                  className={`${transaction.amount > 0 && 'text-success'} ${transaction.amount <= 0 && 'text-danger'}`}
               >
                  {transaction.amount > 0 ? (
                     <FontAwesomeIcon icon={faArrowUp} />
                  ) : (
                     <FontAwesomeIcon icon={faArrowDown} />
                  )}
               </span>
            </td>
            {/* <td onClick={openTransaction} className="text-right"> */}
            {/*   <a href={transaction.} className="btn-icon"> */}
            {/*      PDF{'  '} */}
            {/*      <FontAwesomeIcon icon={faArrowDown} /> */}
            {/*   </a> */}
            {/* </td> */}
         </tr>
      </Fade>
   );
},
_.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);