import styled from 'styled-components';

const css = styled.div;

export const ProgressColor = css<any>`
   .progress-bar {
      background-color: ${(props) => props.color} !important;
   }
`;

export default css`
   .btn-search {
      padding-top: 23px;
   }

   .hour {
      font-weight: 800;
      font-size: 12px;
      color: #349bd3;
   }

   .selected {
      background: #d2ebf8 !important;
   }

   .checkbox-container .checkbox:after {
      border: none;
   }

   .status {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      
      &.pending {
         color: #f9b955 !important;
      }
      &.enabled {
         color: #56c470;
      }
      &.active {
         color: #56c470;
      }
      &.denied {
         color: #dc3545 !important;
      }
      &.deleted {
         color: #dc3545 !important;
      }
      &.disabled {
         color: #888 !important;
      }
   }
`;
