import { of, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import QueryString from 'query-string';
import { Http, Resolve, ToastList } from '@devesharp/react/web';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { sellerPageSelectState, sellerSelectState } from '~/recoil/seller-select.state';

export const APIServiceUrl =
   process.env.NODE_ENV === 'production' ? 'https://api.dev.xpag.com.br/v1' : 'http://localhost:8000/v1';
export const APIBBServiceUrl =
   process.env.NODE_ENV === 'production' ? 'https://api.dev.xpag.com.br/v1/bb' : 'http://localhost:8001/v1';

// export const APIServiceUrl = 'https://api.dev.xpag.com.br/v1';
// export const APIBBServiceUrl = 'https://api.dev.xpag.com.br/v1';

export function AuthAPI(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/auth/login`, data);
}

export function AuthPasswordRecoveryAPI(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/auth/password-recover`, data);
}

export function AuthRecoveryPasswordCheck(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/auth/verify-remember-token`, data);
}

export function AuthResetPasswordAPI(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/auth/password-reset`, data);
}

export function AuthMeAPI(): Observable<any> {
   return Http.get(`${APIServiceUrl}/users/me`).pipe(map((response: any) => response.data));
}

export function AuthRefresh(): Observable<any> {
   return Http.post(`${APIServiceUrl}/auth/refresh`);
}

export function DataDashResolve(): Observable<any> {
   return Http.post(
      `${APIServiceUrl}/analytics/resume-boletos`,
      {},
      {
         'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
      },
   );
}

export function PaymentsGetResolve(id: string): Observable<any> {
   return Http.get(`${APIServiceUrl}/payments/${id}`).pipe(map((response: any) => response.data));
}

export function SyncZoopResolve(id: string): Observable<any> {
   return Http.get(`${APIServiceUrl}/sync/transaction/${id}`).pipe(map((response: any) => response.data));
}

export const PaymentCreateResolve: Resolve = function PaymentCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/payments`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const PaymentBBCreateResolve: Resolve = function PaymentBBCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/bb/bank-slips`, data).pipe(map((response: any) => response.data));
};

export const PaymentCancelResolve: Resolve = function PaymentCancelResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/payments/${id}/cancel`, params);
};

export const PaymentCancelVoidResolve: Resolve = function PaymentCancelVoidResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/payments/${id}/void`, params, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export function PaymentsBBSearchResolve(filters: any): Observable<any> {
   filters = {
      query: {
         sort: filters?.sort ?? '-created_at',
         page: (filters?.offset ?? 0) / 20 + 1,
      },
      filters,
   };
   return Http.post(`${APIServiceUrl}/bb/bank-slips/search`, filters).pipe(map((response: any) => response.data));
}

export function RemessaBBSMSBoletoResolve(filters: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/bb/bank-slips/remessa`, filters);
}

export function RetornoBBSMSBoletoResolve(file: any): Observable<any> {
   const formData = new FormData();
   formData.append('file', file);

   return Http.post(`${APIServiceUrl}/bb/bank-slips/retorno`, formData);
}

export function PaymentsSearchResolve(filters: any): Observable<any> {
   filters = {
      query: {
         sort: filters?.sort ?? '-created_at',
         page: (filters?.offset ?? 0) / 20 + 1,
      },
      filters: {
         ...filters,
         all_sellers: !getRecoil(sellerPageSelectState)?.id,
      },
   };
   return Http.post(`${APIServiceUrl}/payments/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function TransfersSearchResolve(filters: any): Observable<any> {
   filters = {
      query: {
         sort: filters?.sort ?? '-transfer_date',
         page: (filters?.offset ?? 0) / 20 + 1,
      },
      filters: {
         ...filters,
         all_sellers: !getRecoil(sellerPageSelectState)?.id,
      },
   };
   return Http.post(`${APIServiceUrl}/transfers/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function ReceivablesSearchResolve(filters: any): Observable<any> {
   filters = {
      query: {
         sort: filters?.sort ?? 'expected_on',
         page: (filters?.offset ?? 0) / 20 + 1,
      },
      filters: { ...filters, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/receivables/search`, filters).pipe(map((response: any) => response.data));
}

export function ReceivablesSearchByDayResolve(filters: any): Observable<any> {
   filters = {
      query: {
         sort: filters?.sort ?? 'expected_on',
         page: (filters?.offset ?? 0) / 20 + 1,
      },
      filters: { ...filters, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/receivables/search/by-day`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function TransfersGetResolve(id: string): Observable<any> {
   return Http.get(`${APIServiceUrl}/transfers/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function PaymentLinksSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
      },
      filters: { ...filter, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/links-payments/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function PaymentLinksGetResolve(id: string): Observable<any> {
   return Http.get(`${APIServiceUrl}/links-payments/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function PaymentLinksPublicGetResolve(id: string): Observable<any> {
   return Http.get(`${APIServiceUrl}/public/links-payments/${id}`).pipe(map((response: any) => response.data));
}

export function PaymentLinksPublicPayResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/public/links-payments/${data.id}/pay`, data).pipe(
      map((response: any) => response.data),
   );
}

export function CustomersSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: { ...filter, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/customers/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const CustomersGetResolve: Resolve = function CustomersGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/customers/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const CustomersCreateResolve: Resolve = function CustomersCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/customers`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const CustomersUpdateResolve: Resolve = function CustomersUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/customers/${id}`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const CustomersManyUpdateResolve: Resolve = function CustomersManyUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/customers/many`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export function GroupsSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: { ...filter, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/customers-groups/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function MMCSearchResolve(filter: any): Observable<any> {
   const filters = {};
   return Http.post(`${APIServiceUrl}/sellers-mcc/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const GroupsGetResolve: Resolve = function GroupsGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/customers-groups/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const GroupsCreateResolve: Resolve = function GroupsCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/customers-groups`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const AuthMeUpdateAPI: Resolve = function AuthMeUpdateAPI(data: any) {
   return Http.post(`${APIServiceUrl}/users/me`, data, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const ChangeMyPasswordAPI: Resolve = function ChangeMyPasswordAPI(data: any) {
   return Http.post(`${APIServiceUrl}/users/change-password`, data, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const SMSUpdateAPI: Resolve = function SMSUpdateAPI(data: any) {
   return Http.post(`${APIServiceUrl}/sms/message`, data, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const GetSellersSettings: Resolve = function GetSellersSettings(data: any) {
   return Http.get(`${APIServiceUrl}/sellers-settings`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export function SellersSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/sellers/search`, filters).pipe(map((response: any) => response.data));
}

export function SellersGetResolve(id: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/sellers/${id}`).pipe(map((response: any) => response.data));
}

export function SellersAllSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/sellers/all`, filters).pipe(map((response: any) => response.data));
}

export const UpdateSellersSettings: Resolve = function UpdateSellersSettings(data: any) {
   return Http.post(`${APIServiceUrl}/sellers-settings`, data);
};

export function BookletsGetResolve(id: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/booklets-boleto/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function BookletsCancelResolve(id: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/booklets-boleto/${id}/cancel`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function BookletsSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
      },
      filters: { ...filter, all_sellers: !getRecoil(sellerPageSelectState)?.id },
   };
   return Http.post(`${APIServiceUrl}/booklets-boleto/search`, filters).pipe(map((response: any) => response.data));
}

export function FavoritesSearchResolve(filter: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/ted/favorites?${QueryString.stringify(filter)}`);
}

export const GroupsUpdateResolve: Resolve = function GroupsUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/customers-groups/${id}`, params);
};

export function BoletosSearchResolve(filter: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/boletos/search?${QueryString.stringify(filter)}`);
}

export function BoletoCreateResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/payment`, data);
}

export function BoletoGetResolve(id: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/boletos/${id}`);
}

export function LinkPaymentGetResolve(id: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/payment_link/${id}`);
}

export function LinkPaymentPayResolve(params: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/payment_link/${params.id}/pay`, params.data);
}

export function SendTEDResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/ted`, data);
}

export function SendBoletoResolveEmpty(data: any): Observable<any> {
   return of(true);
}

export function getOperatorsResolve(): Observable<any> {
   return Http.get(`${APIServiceUrl}/phone/operators`);
}

export function SendInternalTransferResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/transfer-internal`, data);
}

export function BoletoInfoResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/boletos/info`, data);
}

export function BoletoCancelResolve(id: any, data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/payments/${id}/boleto/cancel`, data, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
}

export function SubscriptionsDeleteResolve(id: any): Observable<any> {
   return Http.delete(`${APIServiceUrl}/subscription/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export function BoletoCancelManyResolve(body: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/payments/boleto/cancel/many`, body, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
}

export function PayBoletoResolve(data: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/boletos/pay`, data);
}

export function SendSMSLinkResolve(filters: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/sms/links`, filters);
}

export function SendSMSBoletoResolve(ids: any): Observable<any> {
   return Http.post(`${APIServiceUrl}/sms/send/boleto`, { ids });
}

export function GetFileStatus(id: any): Observable<any> {
   return Http.get(`${APIServiceUrl}/files-queue-manager/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
}

export function GenerateExcel(filter: any, type: string): Observable<any> {
   if (type == 'transfers') {
      return Http.post(
         `${APIServiceUrl}/transfers/excel`,
         {
            filters: filter,
         },
         {
            'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
         },
      ).pipe(map((response: any) => response.data));
   }

   return Http.post(
      type == 'payments'
         ? `${APIServiceUrl}/payments/exports/excel/all`
         : `${APIServiceUrl}/payments/exports/excel/boletos`,
      {
         filters: filter,
      },
      {
         'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
      },
   ).pipe(map((response: any) => response.data));
}

export function GenerateExcelCustomers(filter: any): Observable<any> {
   return Http.post(
      `${APIServiceUrl}/customers/excel`,
      {
         filters: filter,
      },
      {
         'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
      },
   ).pipe(map((response: any) => response.data));
}

export function GenerateExcelPlans(filter: any): Observable<any> {
   return Http.post(
      `${APIServiceUrl}/plans/excel`,
      {
         filters: filter,
      },
      {
         'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
      },
   ).pipe(map((response: any) => response.data));
}

export function GenerateExcelSubscriptions(filter: any): Observable<any> {
   return Http.post(
      `${APIServiceUrl}/subscriptions/excel`,
      {
         filters: filter,
      },
      {
         'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
      },
   ).pipe(map((response: any) => response.data));
}

export function BankAccountsSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/bank-accounts/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const BankAccountsCreateResolve: Resolve = function BankAccountsCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/bank-accounts`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export function BankAccountsDeleteResolve(id: any): Observable<any> {
   return Http.delete(
      `${APIServiceUrl}/bank-accounts/${id}`,
      {},
      {
         'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
      },
   ).pipe(map((response: any) => response.data));
}

export const ExternalTransactionResolve: Resolve = function ExternalTransactionResolve(data: any) {
   return Http.post(`${APIServiceUrl}/transfers`, data).pipe(map((response: any) => response.data));
};

export const BankAccountsGetResolve: Resolve = function BankAccountsGetResolve(data: any) {
   // TODO: Criar uma rota para pegar conta
   return of(true);
};

export const AccountUpdateResolve: Resolve = function AccountUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/bank-accounts/${params.id}`, params.data);
};
export const UpdateAccountGetResolve: Resolve = function UpdateAccountGetResolve(params: any) {
   return Http.post(`${APIServiceUrl}/auth/update`, params);
};

export function TransferSearchResolve(filter: any): Observable<any> {
   // TODO: Colocar a rota de transferencias
   return Http.get(`${APIServiceUrl}/statement/search?${QueryString.stringify(filter)}`);
}

export function EstablishmentSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/sellers/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const EstablishmentGetResolve: Resolve = function EstablishmentGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/sellers/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const EstablishmentCreateResolve: Resolve = function EstablishmentCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/sellers`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const EstablishmentUpdateResolve: Resolve = function EstablishmentUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/sellers/${id}`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const EstablishmentManyUpdateResolve: Resolve = function EstablishmentManyUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/sellers/many`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export function UsersSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/users/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const UsersGetResolve: Resolve = function UsersGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/users/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const UsersCreateResolve: Resolve = function UsersCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/users`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const UsersUpdateResolve: Resolve = function UsersUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/users/${id}`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export const UsersManyUpdateResolve: Resolve = function UsersManyUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/users/many`, params, {
      'Custom-Seller-Id': getRecoil(sellerPageSelectState)?.id ?? 0,
   });
};

export function PlansSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/plans/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const PlansGetResolve: Resolve = function PlansGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/plans/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const PlansCreateResolve: Resolve = function PlansCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/plans`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const PlansUpdateResolve: Resolve = function PlansUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/plans/${id}`, params, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const PlansManyUpdateResolve: Resolve = function PlansManyUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/plans/many`, params, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export function SubscriptionsSearchResolve(filter: any): Observable<any> {
   const filters = {
      query: {
         sort: filter?.sort ?? '-created_at',
         page: (filter?.offset ?? 0) / 20 + 1,
         limit: filter?.limit ?? 20,
      },
      filters: filter,
   };
   return Http.post(`${APIServiceUrl}/subscription/search`, filters, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
}

export const SubscriptionsGetResolve: Resolve = function SubscriptionsGetResolve(id: any) {
   return Http.get(`${APIServiceUrl}/subscription/${id}`, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   }).pipe(map((response: any) => response.data));
};

export const SubscriptionsCreateResolve: Resolve = function SubscriptionsCreateResolve(data: any) {
   return Http.post(`${APIServiceUrl}/subscription`, data, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const SubscriptionsUpdateResolve: Resolve = function SubscriptionsUpdateResolve(id, params: any) {
   return Http.post(`${APIServiceUrl}/subscription/${id}`, params, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};

export const SubscriptionsManyUpdateResolve: Resolve = function SubscriptionsManyUpdateResolve(params: any) {
   return Http.post(`${APIServiceUrl}/subscription/many`, params, {
      'Custom-Seller-Id': getRecoil(sellerSelectState)?.id ?? 0,
   });
};
