import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faBarcode,
   faClock,
   faPaperPlane,
   faTimes,
   faSort,
   faSortDown,
   faSortUp,
   faDownload,
   faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   FlexItem,
   FlexContainer,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import QueryString from 'querystring';
import Style, { ProgressColor } from './BoletosBBListingPage.style';
import { PaymentStatus, LoadingPage } from '~/components';
import { formaHour, formatDate, formatPrice } from '~/services';
import { useBoletosListingPage } from './BoletosBBListingPage.controller';
import history from '~/config/history';

export const BoletosBBListingPage: React.FunctionComponent<any> = () => {
   const ctrl = useBoletosListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de boletos Banco do Brasil</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {ctrl.loading && <LoadingPage />};
         <Container>
            <div className="text-right pb-4">
               <Button type="submit" size="lg" onClick={() => history.push('/payments/bb/create')}>
                  Criar novo boleto
               </Button>
            </div>

            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     {/* <div> */}
                     {/*   <b>Filtros</b> */}
                     {/* </div> */}
                     <div className="flex-grow-1 px-2">
                        <Input name="customer_name" title="Pagador" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expiration_date_gte" title="A partir de (vencimento)" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expiration_date_lte" title="Até (vencimento)" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="group_id" title="Grupos" items={ctrl.filtersData.groups} />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="status_group" title="Status" items={ctrl.filtersData.status} />
                     </div>

                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="text-dark">
                           Mostrando <b>1</b>-<b>{ctrl.resourcesTotal < 20 ? ctrl.resourcesTotal : 20}</b> de <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div>
                     <Button rounded outline onClick={ctrl.sendSMS}>
                        <FontAwesomeIcon icon={faDownload} /> Download
                     </Button>
                  </div>
                  <div className="pl-2">
                     <Button rounded outline onClick={() => ctrl.fileInput.current.click()} variant="success">
                        <FontAwesomeIcon icon={faUpload} /> Upload de retorno
                        <input
                           ref={ctrl.fileInput}
                           type="file"
                           style={{ display: 'none' }}
                           onChange={ctrl.uploadRetorno}
                        />
                     </Button>
                  </div>
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th />
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Data da venda"
                                    sort="created_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Pagador"
                                    sort="first_name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 'ID da transação'
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Data de vencimento"
                                    sort="expiration_date"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Status"
                                    sort="status_group"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Valor"
                                    sort="amount"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Valor pago'}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((transaction: any) => (
                           <ItemListing
                              key={transaction.id}
                              transaction={transaction}
                              selected={ctrl.checkSelected(transaction.id)}
                              setSelected={ctrl.onSelect}
                              sendSMS={ctrl.sendOne}
                              cancelBoleto={ctrl.cancelBoleto}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   transaction,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   cancelBoleto,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr onClick={openTransaction}>
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      // history.push(`/payments/detail/${transaction.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td className="text-center">
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     checked={selected}
                     onChange={(ev) => {
                        setSelected(transaction.id, ev.target.checked);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td onClick={openTransaction}>
               <div>
                  <b>{formatDate(transaction.created_at)}</b>
                  <br />
                  <small className="hour">
                     <FontAwesomeIcon icon={faClock} /> {formaHour(transaction.created_at)}
                  </small>
               </div>
            </td>
            <td onClick={openTransaction}>
               {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
               {!transaction.payment_method?.holder_name && (
                  <>
                     {transaction.customer_first_name} {transaction.customer_last_name}
                  </>
               )}
            </td>
            <td onClick={openTransaction}>{transaction.id}</td>
            <td onClick={openTransaction}>
               <b>{formatDate(transaction.expiration_date)}</b>
            </td>
            <td className="text-center" onClick={openTransaction}>
               <div className={`badge badge-${transaction.status}`}>{transaction.status_name}</div>
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{formatPrice(transaction.amount)}</b>
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{transaction.amount_paid ? formatPrice(transaction.amount_paid) : '--'}</b>
            </td>
            <td className="text-right text-nowrap">
               {/* {!transaction.cancel && ( */}
               {/*   <span onClick={() => cancelBoleto(transaction.id, !transaction.cancel)} className="btn-icon ml-2"> */}
               {/*      <FontAwesomeIcon icon={faTimes} /> Cancelar */}
               {/*   </span> */}
               {/* )} */}
               {/* {!!transaction.cancel && ( */}
               {/*   <span */}
               {/*      onClick={() => cancelBoleto(transaction.id, !transaction.cancel)} */}
               {/*      className="btn-icon ml-2 bg-danger text-white" */}
               {/*   > */}
               {/*      <FontAwesomeIcon icon={faTimes} /> Descancelar */}
               {/*   </span> */}
               {/* )} */}
               {/* <span onClick={() => sendSMS(transaction.id)} className="btn-icon ml-2"> */}
               {/*   <FontAwesomeIcon icon={faPaperPlane} /> */}
               {/* </span> */}
               <a href={transaction.url} className="btn-icon" target="_blank">
                  <FontAwesomeIcon icon={faBarcode} />
               </a>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);

function ProgressBarInfo({ data, total }: any) {
   return (
      <div className="bars-container">
         <div className="bar-container">
            <Link to={`/boletos?${QueryString.stringify(data.url)}`}>
               <div className="bar-name">{data.name}</div>
               <div className="progress-container">
                  <div className="progress-bar-container">
                     <ProgressColor color={data.color}>
                        <div className="progress">
                           <div
                              className="progress-bar"
                              style={{ width: `${parseInt(((data.count / total) * 100) as any, 10)}%` }}
                           />
                        </div>
                     </ProgressColor>
                  </div>

                  <span className="progress-value">
                     {data.count}/{total}
                  </span>
               </div>
            </Link>
         </div>
      </div>
   );
}
