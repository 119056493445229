import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
   useView,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';

import {
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GetSellersSettings,
   GroupsCreateResolve,
   GroupsSearchResolve,
   PaymentCreateResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';

export function usePaymentsCreatePage(): any {
   const { id } = useParams<any>();
   const [resource, setResource] = useImmer<any>({});
   const paymentData = useRef<any>({});
   const [initialType, setInitialType] = useState('boleto');
   const [settings, setSettings] = useState({});
   document.title = `${
      initialType == 'payment_link'
         ? 'Link de pagamento'
         : initialType == 'boleto'
         ? 'Boleto Bancário'
         : initialType == 'recurrence'
         ? 'Recorrência'
         : initialType == 'booklets_boleto'
         ? 'Carnê'
         : 'Cartão de crédito'
   } - XPAG Tecnologia`;

   const { starting, saving, errorLoadData, reloadPage, formRef, onSubmitForm, registerOnInit } = useViewForm({
      // yupValidateSchema,
      resolves: {
         settings: GetSellersSettings,
      },
      handleFormData() {
         let newData = { ...paymentData.current };
         newData.amount /= 100;
         if (newData.type == 'booklets_boleto') {
            newData.type = 'boleto';
         }

         if (newData.type == 'recurrence') {
            newData = {
               ...newData,
               ...newData.recurrence,
            };
         }

         newData.email_send_on_create_boleto = newData.EMAIL_SEND_ON_CREATE_BOLETO;
         newData.email_send_on_2_before_expired_boleto = newData.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO;
         newData.email_send_on_day_expired_boleto = newData.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO;
         newData.email_send_after_1_day_expired_boleto = newData.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO;

         newData.sms_send_on_create_boleto = newData.SMS_SEND_ON_CREATE_BOLETO;
         newData.sms_send_on_2_before_expired_boleto = newData.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO;
         newData.sms_send_on_day_expired_boleto = newData.SMS_SEND_ON_DAY_EXPIRED_BOLETO;
         newData.sms_send_after_1_day_expired_boleto = newData.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO;

         return newData;
      },
      resolveCreate: PaymentCreateResolve,
      onSuccess(creating, resp) {
         switch (paymentData.current.type) {
            case 'booklets_boleto':
               history.push(`/booklets/detail/${resp.id}`);

               ToastList.show({
                  message: 'Carnê criado com sucesso!',
                  type: 'success',
               });
               break;
            case 'boleto':
               history.push(`/payments/detail/${resp.id}`);

               ToastList.show({
                  message: 'Boleto emitido com sucesso!',
                  type: 'success',
               });
               break;
            case 'card':
               history.push(`/payments/detail/${resp.id}`);
               ToastList.show({
                  message: 'Pagamento realizado com sucesso!',
                  type: 'success',
               });
               break;
            case 'payment_link':
               history.push(`/link-payments/detail/${resp.id}`);
               ToastList.show({
                  message: 'Link de pagamento realizado sucesso!',
                  type: 'success',
               });
               break;
            case 'recurrence':
               history.push(`/subscriptions/detail/${resp.id}`);
               ToastList.show({
                  message: 'Recorrência realizada sucesso!',
                  type: 'success',
               });
               break;
         }
      },
      onFailed(creating, resp) {
         ToastList.show({
            message: resp.data.error,
            type: 'error',
         });
      },
   });

   useLayoutEffect(() => {
      return registerOnInit((r: any) => {
         const dataSettings = { ...r.settings.data };
         dataSettings.EMAIL_SEND_ON_CREATE_BOLETO = dataSettings.EMAIL_SEND_ON_CREATE_BOLETO == '1';
         dataSettings.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO = dataSettings.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO == '1';
         dataSettings.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO = dataSettings.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO == '1';
         dataSettings.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO = dataSettings.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO == '1';

         dataSettings.SMS_SEND_ON_CREATE_BOLETO = dataSettings.SMS_SEND_ON_CREATE_BOLETO == '1';
         dataSettings.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO = dataSettings.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO == '1';
         dataSettings.SMS_SEND_ON_DAY_EXPIRED_BOLETO = dataSettings.SMS_SEND_ON_DAY_EXPIRED_BOLETO == '1';
         dataSettings.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO = dataSettings.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO == '1';

         setSettings(dataSettings);
      });
   }, []);

   const [step, setStep] = useState(1);

   function onSubmitStep1(v: any): void {
      window.scrollTo(0, 0);
      setStep(2);
      setResource((draft) => ({ ...draft, ...v }));
   }

   function backStep1(): void {
      window.scrollTo(0, 0);
      setStep(1);
   }
   function backStep2(): void {
      window.scrollTo(0, 0);
      setStep(2);
   }

   function onSubmitStep2(v: any): void {
      window.scrollTo(0, 0);
      setResource((draft) => ({ ...draft, ...v }));
      setStep(3);
   }

   function finish(): void {
      const data = { ...resource };

      if (!data?.bookletEnabled) {
         data.booklet = null;
      }

      paymentData.current = data;

      formRef.current.submitForm();
   }

   function onChangeType(type: string): any {
      setInitialType(type);
   }

   return {
      reloadPage,
      starting,
      saving,
      formRef,
      initialType,
      onSubmitForm,
      errorLoadData,
      onChangeType,
      onSubmitStep1,
      backStep1,
      onSubmitStep2,
      backStep2,
      finish,
      resource,
      step,
      setStep,
      settings,
   };
}
