import styled from 'styled-components';

const css = styled.div;

export default css`
   z-index: 10;
   position: fixed;
   transform: translate3d(0, 0, 0);
   left: 0px;
   top: 0px;
   width: 100%;
   height: 100%;
   position: absolute;

   .loading-icon {
      display: block;
      position: absolute;
      z-index: 100;
      background: rgba(255, 255, 255, 0.4);
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      .load {
         display: inline-block;
         position: absolute;
         width: 64px;
         height: 64px;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
      }
      .load div {
         position: absolute;
         border: 4px solid ${(props) => props.theme.primary};
         opacity: 1;
         border-radius: 50%;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         animation: load 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .load div:nth-child(2) {
         animation-delay: -0.5s;
      }
      @keyframes load {
         0% {
            //top: 28px;
            //left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
         }
         100% {
            //top: -1px;
            //left: -1px;
            width: 58px;
            height: 58px;
            opacity: 0;
         }
      }
   }
`;
