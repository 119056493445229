import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const fileDownloadGlobalState = atom<{
   created_at: string;
   id: string;
   name: string;
   process: number;
   status: string;
   updated_at: string;
   url: string;
   user_id: number;
} | null>({
   key: '@xpag/file-donwload-global',
   default: null,
   effects_UNSTABLE: [persistAtom],
});
