import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Checkbox, convertStringToDate, Input } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Skeleton from 'react-loading-skeleton';
import { PhoneMask } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import DateFNS from 'date-fns';
import { CNPJAndCPFMask, formatDate } from '~/services';
import history from '~/config/history';
import { useGroupsCreatePage } from '~/pages/GroupsCreate/GroupsCreatePage.controller';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import Style, { ProgressColor } from './GroupsCreatePage.style';
import { SellerSelectInput } from '~/components/SellerSelectInput';

export const GroupsCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useGroupsCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">{ctrl.editing ? `Editar grupo #${ctrl.id}` : 'Novo grupo'}</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <SellerSelectInput />

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="name" title="Nome do Grupo" />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>{ctrl.editing ? 'Editar grupo' : 'Criar novo grupo'}</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>

            {(ctrl.customers.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
               <Card className="mb-5">
                  <Table striped bordered hover className="listing">
                     <thead>
                        <tr>
                           <th>{ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Nome'}</th>
                           <th>CPF/CNPJ</th>

                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Telefone'}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Criado em'}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                     <tbody>
                        {!ctrl.starting &&
                           !ctrl.searching &&
                           !ctrl.errorLoadData &&
                           ctrl.customers &&
                           ctrl.customers.map((customer: any) => (
                              <ItemListing key={customer.id} customer={customer} removeGroup={ctrl.removeGroup} />
                           ))}
                     </tbody>
                  </Table>
               </Card>
            )}
         </div>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({ customer, skeleton, selected, removeGroup, sendSMS }: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr>
            <td />
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/link-payments/detail/${customer.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td>{customer.full_name}</td>
            <td>{CNPJAndCPFMask(customer.taxpayer_id)}</td>
            <td className="text-right">{PhoneMask(customer.phone_number)}</td>
            <td className="text-center text-nowrap">
               <b>{formatDate(customer.created_at)}</b>
            </td>
            <td className="text-right text-nowrap">
               <Button onClick={() => removeGroup(customer.id)} variant="danger" size="sm">
                  Remover do grupo
               </Button>
            </td>
         </tr>
      </Fade>
   );
}, _.isEqual);
