import React, { useState } from 'react';
import { Container, Table, Card } from 'react-bootstrap';
import { FlexContainer, FlexItem } from '@devesharp/react/web';
import Style, { ProgressColor } from './ComingSoon.style';
import { Header } from '~/components';

export const ComingSoon: React.FunctionComponent<any> = () => {

   document.title = 'Em breve - XPAG Tecnologia';
   
   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Página em construção</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Container>
            <FlexContainer>
               <FlexItem flex={1}>
                  <Card className="p-4">
                     <h3>Em breve</h3>
                  </Card>
               </FlexItem>
            </FlexContainer>
         </Container>
      </Style>
   );
};
