import React, { useState } from 'react';
import Style from './Input.style';

interface IPropsInputPure extends React.ComponentProps<any> {
   inputRef?: any;
   defaultValueInput?: any;
   prefix?: any;
   suffix?: any;
   textRight?: boolean;
   textCenter?: boolean;
   loading?: boolean;
   error?: string;
}

export const InputPure = function InputPure(props: IPropsInputPure): any {
   const { textRight, prefix, error, textCenter, loading, suffix, inputRef, defaultValueInput, ...rest } = props;
   const [focus, setFocus] = useState(false);

   return (
      <>
         <div
            className={`input-border ${textRight && ' text-right'} ${
               rest.type && rest.type === 'hidden' && ' input-hidden'
            } ${error && 'has-error'} ${focus && 'focus'}`}
         >
            {prefix && <div className="prefix">{prefix}</div>}
            <input
               ref={inputRef}
               defaultValue={defaultValueInput}
               {...rest}
               onBlur={(ev) => {
                  if (rest.onBlur) rest.onBlur(ev);
                  setFocus(false);
               }}
               onFocus={(ev) => {
                  if (rest.onFocus) rest.onFocus(ev);
                  setFocus(true);
               }}
               className={`${textCenter && ' text-center'}`}
            />
            {loading && (
               <div className="suffix suffix-loading">
                  <i className="fas fa-spinner fa-spin fa-fw" />
               </div>
            )}
            {suffix && <div className="suffix">{suffix}</div>}
         </div>

         {error && (
            <div data-cy={`input-error.${rest['data-cy'] ?? ''}`} className="error-input" data-testid="input-error">
               {error}
            </div>
         )}
      </>
   );
};
