import React, { useRef, useEffect, useState, useImperativeHandle, useContext } from 'react';
import { useField } from '@unform/core';
// import { useEventListener } from '../../../hooks/useEventListener';
// import { OnlyNumbers } from '../../../services/utils.service';
// import AppContext from '../../AppProvider/AppContext';
// import { maskValue } from '../../MaskText/mask.utils';
// import { IPropsInput } from '../input.interface';
import { useEventListener } from '@devesharp/react/web';
// import { maskValue } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import { IPropsInput } from '@devesharp/react/dist/components1/Input/input.interface';
import {
   CEPMask,
   CNPJMask,
   CPFMask,
   NoSpaceMask,
   NumberMask,
   PhoneMask,
   RGMask,
} from '@devesharp/react/dist/components1/MaskText/mask.utils';
import Style from './Input.style';
import { InputPure } from './InputPure';
import { OnlyNumbers } from '~/services';

export const DateMask = (_value: string): string => {
   const value = _value || '';

   if (value === '') return value;

   let newVal = OnlyNumbers(value);

   newVal = newVal.substring(0, 8);
   if (newVal.length > 2 && newVal.length < 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
   }else if (newVal.length >= 5) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
   }

   return newVal;
};

export function maskValue(
   value = '',
   { number, CEP, CNPJ, CPF, RG, phone, date, noSpaces, decimals, withoutDotNumber }: any,
): string {
   if (noSpaces) value = NoSpaceMask(value);
   if (number) return !withoutDotNumber ? NumberMask(value, decimals) : NumberMask(value, decimals).replace(/\./g, '');
   if (CEP) return CEPMask(value);
   if (CNPJ) return CNPJMask(value);
   if (CPF) return CPFMask(value);
   if (RG) return RGMask(value);
   if (phone) return PhoneMask(value);
   if (date) return DateMask(value);

   return value;
}

interface IPropsInputWeb extends IPropsInput {
   theme?: string;
   onEnter?: () => any;
   date?: boolean;
}

export const Input = React.forwardRef<any, IPropsInputWeb>(function Input(props: IPropsInputWeb, ref: any) {
   props = { decimals: 0, ...props };

   const {
      name,
      title,
      textRight = false,
      textCenter = false,
      prefix,
      decimals = 0,
      theme: themeInput,
      suffix,
      loading = false,
      onEnter = () => {},
      number = false,
      withoutDotNumber = false,
      CEP = false,
      CNPJ = false,
      CPF = false,
      RG = false,
      date = false,
      phone = false,
      noSpaces = false,
      as = InputPure,
      ...rest
   } = props;

   // Custom component
   // const { pureComponents: { Input: InputComponent = null } = {} } = useContext(AppContext);
   const Component = as;

   const inputRef = useRef<any>();

   useImperativeHandle(ref, () => inputRef.current, [inputRef]);

   // Se Input está em focus
   const [focus, setFocus] = useState(false);

   // Unform
   const { registerField, defaultValue = '', error, fieldName, clearError } = useField(name);
   const defaultValueInput = maskValue(defaultValue, props);

   // onEnter
   useEventListener('keydown.enter', () => onEnter(), inputRef);

   // Event: Input
   useEventListener(
      'input',
      (evt) => {
         inputRef.current.value = maskValue(inputRef.current.value, props);
         clearError();
      },
      inputRef,
   );

   useEffect(() => {
      registerField({
         name: fieldName,
         ref: inputRef.current,
         path: 'value' as any,
         setValue(_ref: any, value: string) {
            inputRef.current.value = maskValue(value, props);
         },
         getValue(_ref: any) {
            if (number) return OnlyNumbers(_ref.value);
            if (CEP) return OnlyNumbers(_ref.value);
            return _ref.value;
         },
      });
   }, [fieldName, registerField]);

   return (
      <Style themeInput={themeInput}>
         <div className={`input-title ${focus && 'focus'}`}>{title}</div>

         <Component
            inputRef={inputRef}
            textRight={textRight}
            error={error}
            prefix={prefix}
            textCenter={textCenter}
            loading={loading}
            suffix={suffix}
            defaultValueInput={defaultValueInput}
            {...rest}
         />
      </Style>
   );
});
