import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBan, faBarcode, faClock, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastList } from '@devesharp/react/web/index';
import Style from './TransfersDetailPage.style';
import { usePaymentsDetailPage } from './TransfersDetailPage.controller';
import { PaymentStatus } from '~/components';
import { PaymentType } from '~/components/PaymentType';
import { CNPJAndCPFMask, formaHour, formatBarCode, formatDate, formatPrice } from '~/services';
import { useUser } from '~/hooks';
import { TransferStatus } from '~/components/PaymentStatus/TransferStatus';
import { TransferType } from '~/components/PaymentType/TransferType';
import history from '~/config/history';

export const TransfersDetailPage: React.FunctionComponent<any> = () => {
   const ctrl = usePaymentsDetailPage();
   const user = useUser();

   if (!ctrl.resource) {
      return null;
   }

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Detalhes da transferência - {formatDate(ctrl.resource.created_at)}</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Container>
            <div className="row">
               <div className="col-3">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`}>
                        <div className="d-flex">
                           <div>Valor</div>
                           <div className="flex-fill text-right">
                              <span>{formatPrice(ctrl.resource.amount * 100)}</span>
                           </div>
                        </div>
                     </div>
                     <div className="p-4">
                        <h5>Informações da transação</h5>
                        <p />

                        <p>
                           Status da transação
                           <br />
                           <TransferStatus transaction={ctrl.resource} />
                        </p>

                        <p>
                           Tipo da venda <br />
                           <b>
                              <TransferType transaction={ctrl.resource} />
                           </b>
                        </p>
                        <p>
                           ID da transferência
                           <br />
                           <b>{ctrl.resource.id}</b>
                        </p>
                        <p>
                           Data da transferência
                           <br />
                           <b>
                              {formatDate(ctrl.resource?.created_at)} - {formaHour(ctrl.resource?.created_at)}
                           </b>
                        </p>
                        <p>
                           Quantidade de vendas
                           <br />
                           <b>{ctrl.resource?.payments.length ?? 0} vendas</b>
                        </p>
                        <p>
                           Descrição
                           <br />
                           <b>{ctrl.resource.description ?? '--'}</b>
                        </p>
                     </div>
                  </Card>
                  <Card className="p-4">
                     <h5>Favorecidos</h5>
                     <p />

                     <p>
                        Nome <br />
                        <b>{ctrl.resource.bank_account.holder_name}</b>
                     </p>
                     <p>
                        CNPJ/CPF <br />
                        <b>{user.document ? CNPJAndCPFMask(user.document) : '--'}</b>
                     </p>
                     <p>
                        Banco <br />
                        <b>{ctrl.resource.bank_account.bank_name}</b>
                     </p>
                     <p>
                        Agência <br />
                        <b>{ctrl.resource.bank_account.routing_number}</b>
                     </p>
                     <p>
                        Conta <br />
                        <b>{ctrl.resource.bank_account.account_number}</b>
                     </p>
                  </Card>
                  <Card className="p-4">
                     <h5>Recebedor</h5>
                     <p />
                     <p>
                        {ctrl.resource.bank_account.holder_name}
                        <br />
                        <b>{CNPJAndCPFMask(user.document)}</b>
                     </p>
                  </Card>
               </div>

               <div className="col">
                  <Card>
                     <div className={`title-price title-${ctrl.resource.status}`} />

                     <div className="p-4">
                        <h5>Vendas dessa transferência - {formatDate(ctrl.resource.created_at)}</h5>
                        <p />

                        <Table striped bordered hover className="listing">
                           <thead>
                              <tr>
                                 <th>ID</th>
                                 <th>Data da venda</th>
                                 <th>Pagador</th>
                                 <th>Descrição</th>
                                 <th>Taxa</th>
                                 <th className="text-right">Valor</th>
                              </tr>
                           </thead>
                           <tbody>
                              {ctrl.resource.payments.map((transaction: any) => (
                                 <tr>
                                    <td className="text-nowrap">
                                       <CopyToClipboard
                                          text={transaction.id}
                                          onCopy={() =>
                                             ToastList.show({
                                                message: 'ID copiado com sucesso',
                                                type: 'black',
                                             })
                                          }
                                       >
                                          <span>
                                             {transaction.id?.substr(0, 4)}...
                                             {transaction.id?.substr(-4)}
                                             &nbsp;
                                             <FontAwesomeIcon icon={faCopy} />
                                          </span>
                                       </CopyToClipboard>
                                    </td>
                                    <td onClick={() => history.push(`/payments/detail/${transaction.id}`)}>
                                       <b>{formatDate(transaction.payment?.created_at)}</b>
                                       <br />

                                       {formaHour(transaction.payment?.created_at)}
                                    </td>
                                    <td onClick={() => history.push(`/payments/detail/${transaction.id}`)}>
                                       {!!transaction.payment_method?.holder_name &&
                                          transaction.payment_method.holder_name}
                                       {!transaction.payment_method?.holder_name && (
                                          <>
                                             {transaction.customer_first_name} {transaction.customer_last_name}
                                          </>
                                       )}
                                    </td>
                                    <td onClick={() => history.push(`/payments/detail/${transaction.id}`)}>
                                       {transaction.description}
                                    </td>
                                    <td onClick={() => history.push(`/payments/detail/${transaction.id}`)}>
                                       {formatPrice(transaction.fees, 0)}
                                    </td>
                                    <td
                                       onClick={() => history.push(`/payments/detail/${transaction.id}`)}
                                       className="text-right"
                                    >
                                       <b>{formatPrice(transaction.amount, 0)}</b>{' '}
                                       <span
                                          className={`${transaction.amount > 0 && 'text-success'} ${
                                             transaction.amount <= 0 && 'text-danger'
                                          }`}
                                       >
                                          {transaction.amount > 0 ? (
                                             <FontAwesomeIcon icon={faArrowUp} />
                                          ) : (
                                             <FontAwesomeIcon icon={faArrowDown} />
                                          )}
                                       </span>
                                    </td>
                                 </tr>
                              ))}

                              <tr className="border-top">
                                 <td>
                                    <b>
                                       {ctrl.resource.payment_type == 'commission' && 'Total'}
                                       {ctrl.resource.payment_type != 'commission' && 'Status atual'}
                                    </b>
                                 </td>
                                 <td />
                                 <td />
                                 <td>
                                    <PaymentStatus transaction={ctrl.resource} />
                                 </td>
                                 <td>
                                    <span className="price">{formatPrice(ctrl.resource.payments?.reduce((a: any, b: any) => a + b.fees, 0) * 100)}</span>
                                 </td>
                                 <td className="text-right">
                                    <span className="price">{formatPrice(ctrl.resource.amount * 100)}</span>
                                 </td>
                              </tr>
                           </tbody>
                        </Table>
                     </div>
                  </Card>
               </div>
            </div>
         </Container>
      </Style>
   );
};
