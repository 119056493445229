import { collect } from 'collect.js';
import moment from 'moment';
import * as Yup from 'yup';
import Axios from 'axios';

/**
 * Retorna todos os itens do objeto, exceto aqueles com as chaves especificadas
 *
 * @param object
 * @param excepts
 */
export function objectExcept(object: any, excepts: any[]): any {
   return collect(object).sortKeys().except(excepts).all();
}
/**
 * numberFormat
 * @param number
 * @param {number} decimals
 * @param {string} decPoint
 * @param {string} thousandsSep
 * @returns {string}
 */
export function NumberFormat(number: any, decimals = 2, decPoint = ',', thousandsSep = '.'): string {
   number = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
   const n = !Number.isFinite(+number) ? 0 : +number;
   const prec = !Number.isFinite(+decimals) ? 2 : Math.abs(decimals);
   const sep = typeof thousandsSep === 'undefined' ? '.' : thousandsSep;
   const dec = typeof decPoint === 'undefined' ? ',' : decPoint;

   const toFixedFix = (_n: any, _prec: any): string => {
      const k = 10 ** _prec;
      return `${(Math.round(_n * k) / k).toFixed(_prec)}`;
   };

   const s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');

   if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
   }

   if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
   }

   return s.join(dec);
}

/**
 *
 * @param string
 */
export function OnlyNumbers(string: string): string {
   if (!string) {
      return '';
   }
   string = string.toString();
   return string.replace(/[^0-9]/g, '');
}

/**
 *
 * @param end
 * @param start
 */
export function CreateRange(end: number, start = 0): number[] {
   const array = [];
   for (let i = start; i < end; i += 1) {
      array.push(i);
   }
   return array;
}

/**
 *
 * @param object
 */
export function deleteEmpty(object: object): any {
   const newObject = { ...object };

   return Object.fromEntries(
      Object.entries(newObject)
         .filter(
            ([key, value]) =>
               value !== '' &&
               value !== undefined &&
               value !== null &&
               (value instanceof Array ? value.length !== 0 : true),
         )
         .map(([key, value]) => [key, value]),
   );
}

export async function setErrorUnFormWithYup(
   formRef: any,
   data: any,
   schema: Yup.ObjectSchema<any, any, any>,
): Promise<boolean> {
   try {
      // Remove all previous errors
      formRef.current.setErrors({});

      /**
       * Validate
       */
      await schema.validate(data, {
         abortEarly: false,
      });
   } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
         err.inner.forEach((yupError) => {
            console.log(yupError?.path, yupError.message);
            // @ts-ignore
            validationErrors[yupError?.path] = yupError.message;
         });
         formRef.current.setErrors(validationErrors);
      } else {
         // console.log(err);
      }

      return false;
   }

   return true;
}

/**
 *
 * @param mixedVar
 */
export function isEmpty(mixedVar: any): boolean {
   //  discuss at: http://locutus.io/php/empty/
   // original by: Philippe Baumann
   //    input by: Onno Marsman (https://twitter.com/onnomarsman)
   //    input by: LH
   //    input by: Stoyan Kyosev (http://www.svest.org/)
   // bugfixed by: Kevin van Zonneveld (http://kvz.io)
   // improved by: Onno Marsman (https://twitter.com/onnomarsman)
   // improved by: Francesco
   // improved by: Marc Jansen
   // improved by: Rafał Kukawski (http://blog.kukawski.pl)
   //   example 1: empty(null)
   //   returns 1: true
   //   example 2: empty(undefined)
   //   returns 2: true
   //   example 3: empty([])
   //   returns 3: true
   //   example 4: empty({})
   //   returns 4: true
   //   example 5: empty({'aFunc' : function () { alert('humpty'); } })
   //   returns 5: false

   let undef;
   let key;
   let i;
   let len;
   const emptyValues = [undef, null, false, 0, '', '0'];

   for (i = 0, len = emptyValues.length; i < len; i += 1) {
      if (mixedVar === emptyValues[i]) {
         return true;
      }
   }

   if (typeof mixedVar === 'object') {
      // eslint-disable-next-line no-restricted-syntax
      for (key in mixedVar) {
         // eslint-disable-next-line no-prototype-builtins
         if (mixedVar.hasOwnProperty(key)) {
            return false;
         }
      }
      return true;
   }

   return false;
}

export function setValueInResults(array: any[], key: string | number, value: any, putValue: any): any[] {
   return array.map((el) => {
      if (el[key] === value) {
         el = { ...el, ...putValue };
      }

      return el;
   });
}

export function formatMoney(amount: any, decimalCount = 2, decimal = ',', thousands = '.'): any {
   try {
      // eslint-disable-next-line no-param-reassign
      decimalCount = Math.abs(decimalCount);
      // eslint-disable-next-line no-restricted-globals,no-param-reassign
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      // eslint-disable-next-line radix,no-param-reassign
      const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      return (
         negativeSign +
         (j ? i.substr(0, j) + thousands : '') +
         i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
         (decimalCount
            ? decimal +
              // @ts-ignore
              Math.abs(amount - i)
                 .toFixed(decimalCount)
                 .slice(2)
            : '')
      );
   } catch (e) {
      console.log(e);
   }
}

export function formatPrice(value: any, decimal = 2): string {
   return `R$ ${formatMoney(value / 10 ** decimal)}`;
}

export function formatDate(date: string): string {
   return moment(date).format('DD/MM/YYYY');
}

export function formatDateBar(date: string): string {
   return moment(date).format('DD-MM-YYYY');
}

export function formaHour(date: string, gmt = false): string {
   return moment(date)
      .subtract(gmt ? 3 : 0, 'hours')
      .format('hh:mm:ss');
}

export function formatBarCode(_value: string): string {
   const value = _value || '';

   if (value === '') return value;

   let newVal = OnlyNumbers(value);

   if (value[0] === '8') {
      newVal = newVal.substring(0, 48);

      if (newVal.length === 0) {
         newVal = '';
      } else if (newVal.length <= 11) {
         newVal = newVal.replace(/^(\d{0,11})/, '$1');
      } else if (newVal.length <= 12) {
         newVal = newVal.replace(/^(\d{0,11})(\d{0,1})/, '$1-$2');
      } else if (newVal.length <= 23) {
         newVal = newVal.replace(/^(\d{0,11})(\d{0,1})(\d{0,11})/, '$1-$2 $3');
      } else if (newVal.length <= 24) {
         newVal = newVal.replace(/^(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})/, '$1-$2 $3-$4');
      } else if (newVal.length <= 35) {
         newVal = newVal.replace(/^(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})/, '$1-$2 $3-$4 $5');
      } else if (newVal.length <= 36) {
         newVal = newVal.replace(/^(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})/, '$1-$2 $3-$4 $5-$6');
      } else if (newVal.length <= 47) {
         newVal = newVal.replace(
            /^(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})/,
            '$1-$2 $3-$4 $5-$6 $7',
         );
      } else if (newVal.length <= 48) {
         newVal = newVal.replace(
            /^(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})(\d{0,11})(\d{0,1})/,
            '$1-$2 $3-$4 $5-$6 $7-$8',
         );
      }
   } else {
      newVal = newVal.substring(0, 47);

      if (newVal.length === 0) {
         newVal = '';
      } else if (newVal.length <= 5) {
         newVal = newVal.replace(/^(\d{0,5})/, '$1');
      } else if (newVal.length <= 10) {
         newVal = newVal.replace(/^(\d{0,5})(\d{0,5})/, '$1.$2');
      } else if (newVal.length <= 15) {
         newVal = newVal.replace(/^(\d{0,5})(\d{0,5})(\d{0,5})/, '$1.$2 $3');
      } else if (newVal.length <= 21) {
         newVal = newVal.replace(/^(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,6})/, '$1.$2 $3.$4');
      } else if (newVal.length <= 26) {
         newVal = newVal.replace(/^(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,6})(\d{0,5})/, '$1.$2 $3.$4 $5');
      } else if (newVal.length <= 31) {
         newVal = newVal.replace(/^(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,6})(\d{0,5})(\d{0,6})/, '$1.$2 $3.$4 $5.$6');
      } else if (newVal.length <= 32) {
         newVal = newVal.replace(
            /^(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,6})(\d{0,5})(\d{0,6})(\d{0,1})/,
            '$1.$2 $3.$4 $5.$6 $7',
         );
      } else if (newVal.length <= 47) {
         newVal = newVal.replace(
            /^(\d{0,5})(\d{0,5})(\d{0,5})(\d{0,6})(\d{0,5})(\d{0,6})(\d{0,1})(\d)/,
            '$1.$2 $3.$4 $5.$6 $7 $8',
         );
      }
   }

   return newVal;
}

export const CPFValidation = (cpf: any): any => {
   if (typeof cpf !== 'string') return false;
   const newCpf = cpf.replace(/[\s.-]*/gim, '');
   if (
      !newCpf ||
      newCpf.length !== 11 ||
      newCpf === '00000000000' ||
      newCpf === '11111111111' ||
      newCpf === '22222222222' ||
      newCpf === '33333333333' ||
      newCpf === '44444444444' ||
      newCpf === '55555555555' ||
      newCpf === '66666666666' ||
      newCpf === '77777777777' ||
      newCpf === '88888888888' ||
      newCpf === '99999999999'
   ) {
      return false;
   }
   let soma = 0;
   let resto;
   // eslint-disable-next-line radix
   for (let i = 1; i <= 9; i += 1) soma += parseInt(newCpf.substring(i - 1, i)) * (11 - i);
   resto = (soma * 10) % 11;
   if (resto === 10 || resto === 11) resto = 0;
   // eslint-disable-next-line radix
   if (resto !== parseInt(newCpf.substring(9, 10))) return false;
   soma = 0;
   // eslint-disable-next-line radix
   for (let i = 1; i <= 10; i += 1) soma += parseInt(newCpf.substring(i - 1, i)) * (12 - i);
   resto = (soma * 10) % 11;
   if (resto === 10 || resto === 11) resto = 0;
   // eslint-disable-next-line radix
   if (resto !== parseInt(newCpf.substring(10, 11))) return false;

   return true;
};

export const CPFMask = (_value: string): string => {
   const value = _value || '';

   if (value === '') return value;

   let newVal = OnlyNumbers(value);

   if (newVal.length === 0) {
      newVal = '';
   } else if (newVal.length < 4) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
   } else if (newVal.length < 7) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
   } else if (newVal.length < 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
   } else {
      newVal = newVal.substring(0, 11);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
   }

   return newVal;
};

export const CNPJMask = (_value: string): string => {
   const value = _value || '';

   if (value === '') return value;

   let newVal = OnlyNumbers(value);

   if (newVal.length === 0) {
      newVal = '';
   } else if (newVal.length < 3) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1');
   } else if (newVal.length < 6) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1.$2');
   } else if (newVal.length < 9) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
   } else if (newVal.length < 13) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1.$2.$3/$4');
   } else {
      newVal = newVal.substring(0, 14);
      newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
   }

   return newVal;
};

export const CNPJAndCPFMask = (_value: string): string => {
   const value = _value || '';
   if (value === '') return value;

   const newVal = OnlyNumbers(value);

   if (newVal.length <= 11) {
      return CPFMask(_value);
   }
   return CNPJMask(_value);
};

export function searchCEP(cep: string): any{
   return Axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
}
