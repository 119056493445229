import styled from 'styled-components';

const css = styled.div;

export default css`
   position: relative;
   b {
      color: #111;
   }

   .hour {
      font-weight: 800;
      font-size: 12px;
      color: #349bd3;
   }

   .selected {
      background: #d2ebf8 !important;
   }

   .title-price {
      color: white;
      padding: 10px;
      font-weight: bold;
      font-size: 16px;
   }

   .title-black {
      background: black;
   }

   .title-succeeded {
      background: #56c470;
   }

   .title-pending {
      background: #f9b955 !important;
   }

   .title-canceled {
      background: #dc3545 !important;
   }

   .title-failed {
      background: #e35663 !important;
   }

   .title-blocked {
      background: #222 !important;
   }
`;
