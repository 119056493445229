import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Form } from '@unform/web';
import Cards from 'react-credit-cards';
import { Button, Input, Select } from '@devesharp/react/web/index';
import { PhoneMask } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import Style from './LinkPaymentsPayPage.style';
import { useLinkPaymentsPayPage } from '~/pages/LinkPaymentsPay/LinkPaymentsPayPage.controller';
import { LoadingPage, PaymentStatus } from '~/components';
import { CNPJAndCPFMask, formatPrice } from '~/services';
import { Logo } from '~/assets/images';
import { LoginScreen } from '~/pages/LoginScreen';

export const LinkPaymentsPayPage: React.FunctionComponent<any> = () => {
   const ctrl = useLinkPaymentsPayPage();

   if (ctrl.starting) {
      return <LoginScreen />;
   }

   if (ctrl.succeeded) {
      return (
         <Style>
            <div className="text-center p-5">
               <img src={Logo} className="logo" />
            </div>

            <Container>
               <br />
               <br />
               <h1 className="text-white text-center">Obrigado! Link de pagamento pago com sucesso!</h1>
            </Container>

            <br />
         </Style>
      );
   }

   if (ctrl.errorLoadData || ctrl.resource?.status === 'succeeded') {
      return (
         <Style>
            <div className="text-center p-5">
               <img src={Logo} className="logo" />
            </div>

            <Container>
               <br />
               <br />
               <h1 className="text-white text-center">Link de pagamento pago ou expirado.</h1>
            </Container>

            <br />
         </Style>
      );
   }

   console.log(ctrl.resource);
   if (!ctrl.resource) {
      return null;
   }

   return (
      <Style>
         <div className="text-center p-5">
            <img src={Logo} className="logo" />
         </div>

         {ctrl.saving && <LoadingPage />}

         <br />

         <Container>
            <div className="text-white text-center">
               Os dados do cartão de crédito inseridos no formulário serão usados apenas para o pagamento e não serão
               salvos.
            </div>

            <div className="row">
               <div className="col">
                  <Card>
                     <div className="form-padding ">
                        <h5>Dados da compra</h5>
                        <br />
                        <p>
                           Valor
                           <br />
                           <h3>{formatPrice(ctrl.resource.amount * 100)}</h3>
                        </p>
                        <br />
                        <p>
                           Nome do estabelecimento
                           <br />
                           <b className="text-secondary">{ctrl.resource.owner.name}</b>
                        </p>
                        <p>
                           CPF/CNPJ
                           <br />
                           <b className="text-secondary">{CNPJAndCPFMask(ctrl.resource.owner.document)}</b>
                        </p>
                        <p>
                           Telefone
                           <br />
                           <b className="text-secondary">{PhoneMask(ctrl.resource.owner.phone)}</b>
                        </p>
                        <p>
                           Email
                           <br />
                           <b className="text-secondary">{ctrl.resource.owner.email}</b>
                        </p>
                        <p>
                           Descrição
                           <br />
                           <b className="text-secondary">{ctrl.resource.description}</b>
                        </p>

                        <p>
                           Endereço
                           <br />
                           <b className="text-secondary">
                              {ctrl.resource.owner?.street}, {ctrl.resource.owner?.number}{' '}
                              {ctrl.resource.owner?.complement} - {ctrl.resource.owner?.cep} -{' '}
                              {ctrl.resource.owner?.district} - {ctrl.resource.owner?.city} -{' '}
                              {ctrl.resource.owner?.state}
                           </b>
                        </p>
                     </div>
                  </Card>
               </div>

               <div className="col-md-8 position-relative">
                  <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmitForm}>
                     <Card>
                        <div className="form-padding ">
                           <h5>Dados do cartão</h5>

                           <br />

                           <div className="d-none">
                              <Input name="type" value="card" />
                           </div>

                           <div className="p-4">
                              <Cards
                                 placeholders={{
                                    name: 'NOME COMPLETO',
                                 }}
                                 locale={{
                                    valid: 'exp.',
                                 }}
                                 cvc={ctrl.creditCard.cvc}
                                 expiry={ctrl.creditCard.expiry}
                                 focused={ctrl.creditCard.focus}
                                 name={ctrl.creditCard.name}
                                 number={ctrl.creditCard.number}
                              />

                              <br />
                              <br />
                              <div className="row">
                                 <div className="col-md-8 field-box">
                                    <Input
                                       name="card.card_number"
                                       title="Número do cartão"
                                       placeholder="xxxx xxxx xxxx xxxx"
                                       maxLength={16}
                                       onInput={(e: any) => {
                                          ctrl.setCreditCard((draft: any) => ({ ...draft, number: e.target.value }));
                                       }}
                                    />
                                 </div>

                                 <div className="col-md-4 field-box">
                                    <div className="input-title false">Vencimento</div>
                                    <div className="row">
                                       <div className="col">
                                          <Select
                                             name="card.expiration_month"
                                             items={ctrl.formInfoData.expiration_month}
                                             onChange={(e: any) => {
                                                ctrl.setCreditCard((draft: any) => ({
                                                   ...draft,
                                                   expiry: `${ctrl.formRef.current?.getFieldValue(
                                                      'card.expiration_month',
                                                   )}/${ctrl.formRef.current?.getFieldValue('card.expiration_year')}`,
                                                }));
                                             }}
                                          />
                                       </div>
                                       <div className="col">
                                          <Select
                                             name="card.expiration_year"
                                             items={ctrl.formInfoData.expiration_year}
                                             onChange={(e: any) => {
                                                ctrl.setCreditCard((draft: any) => ({
                                                   ...draft,
                                                   expiry: `${ctrl.formRef.current?.getFieldValue(
                                                      'card.expiration_month',
                                                   )}/${ctrl.formRef.current?.getFieldValue('card.expiration_year')}`,
                                                }));
                                             }}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div className="row ">
                                 <div className="col-md-8 field-box">
                                    <Input
                                       name="card.holder_name"
                                       title="Nome no cartão"
                                       onInput={(e: any) => {
                                          ctrl.setCreditCard((draft: any) => ({ ...draft, name: e.target.value }));
                                       }}
                                    />
                                 </div>

                                 <div className="col-md-2 field-box">
                                    <Input
                                       name="card.security_code"
                                       title="CVV"
                                       maxLength={3}
                                       placeholder="xxx"
                                       onInput={(e: any) => {
                                          ctrl.setCreditCard((draft: any) => ({ ...draft, cvc: e.target.value }));
                                       }}
                                       onFocus={(e: any) => {
                                          ctrl.setCreditCard((draft: any) => ({ ...draft, focus: 'cvc' }));
                                       }}
                                       onBlur={(e: any) => {
                                          ctrl.setCreditCard((draft: any) => ({ ...draft, focus: 'name' }));
                                       }}
                                    />
                                 </div>

                                 <div className="col-md-2 field-box">
                                    <div className="input-title false">Parcelar</div>
                                    <Select name="installments" items={ctrl.formInfoData.installments} />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Card>
                     <div className="text-right pt-2">
                        <Button size="lg" type="submit">
                           <b>Realizar Pagar</b>
                        </Button>
                     </div>
                  </Form>
                  <br />
                  <br />
               </div>
            </div>
         </Container>
      </Style>
   );
};
