import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   useSelectGroup,
   ToastList,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   APIServiceUrl,
   DataDashResolve,
   GenerateExcel,
   GroupsSearchResolve,
   PaymentsSearchResolve,
   SendSMSBoletoResolve,
} from '~/api';
import {sellerSelectState, sellerShowInfoState} from '~/recoil/seller-select.state';
import { useUser } from '~/hooks';
import { fileDownloadGlobalState } from '~/recoil/file-donwload-global.state';
import { useViewList } from '~/hooks/useViewList';

export function usePaymentsListingPage(): any {
   document.title = 'Lista de operações - XPAG Tecnologia';
   const location = useLocation();

   const [showSeller] = useRecoilState(sellerShowInfoState);
   
   const {
      starting,
      searching,
      errorLoadData,
      reloadPage,
      registerResolveParams,
      registerOnAfterSearch,
      registerOnInit,
      resources,
      setSort,
      resourcesTotal,
      skeletonResources,
      setFilters,
      setOffset,
      setPage,
      filters,
   } = useViewList({
      resolves: {
         items: PaymentsSearchResolve,
         groups: GroupsSearchResolve,
      },
      filtersDefault: {
         payment_type: location.pathname === '/payments/credit' ? 'credit' : '',
      },
      handleQueryToFilter: (query: any) => {
         if (query.status_transaction_array) {
            if (
               typeof query.status_transaction_array !== 'object'
            ) {
               query.status_transaction_array = [query.status_transaction_array];
            }
         }
         return query;
      },
   });

   const [onSelect, onUnselectAll, selected, checkSelected] = useSelectGroup();
   const [fileDownloadGlobal, setFileDownloadGlobal] = useRecoilState(fileDownloadGlobalState);

   const [currentPage, setCurrentPage] = useState(parseInt(((filters?.offset ?? 0) / 20) as any, 10) + 1);
   const formRef = useRef<FormHandles>();
   const [groups, setGroups] = useState<any[]>([]);

   const [sum, setSum] = useState(0);
   const [sumPage, setSumPage] = useState(0);

   useLayoutEffect(() => {
      return registerOnInit((v: any) => {
         setSum(v.items.sum);
         setSumPage(v.items.sum_page);
      });
   }, []);

   useLayoutEffect(() => {
      return registerOnAfterSearch((v: any) => {
         setSum(v.sum);
         setSumPage(v.sum_page);
      });
   }, []);

   useDidUpdateEffect(() => {
      setPage(currentPage);
   }, [currentPage]);

   useLayoutEffect(() => {
      return registerOnInit((v: any) => {
         if (v.groups) {
            setGroups(v.groups.results);
         }
      });
   }, []);

   useEffect(() => {
      if (formRef.current) {
         formRef.current.setData(filters);
      }
      // window.scrollTo(0, 0);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
   }, [filters]);

   /**
    * Seller Select
    */
   const user = useUser();
   const userRole = user.role;

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      reloadPage(true);
   }, [sellerSelect]);

   const filtersData = {
      types: [
         {
            id: '',
            name: 'Todos',
         },
         {
            id: 'credit',
            name: 'Cartão de crédito',
         },
         {
            id: 'boleto',
            name: 'Boleto',
         },
         {
            name: 'Pix',
            id: 'pix',
         },
         {
            name: 'Recorrência',
            id: 'recurrence',
         },
      ],
      groups: [
         {
            id: '',
            name: 'Todos',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      status: [
         // {
         //    id: '',
         //    name: 'Todos',
         // },
         { id: 'succeeded', name: 'Aprovada' },
         { id: 'canceled', name: 'Cancelada' },
         { id: 'blocked', name: 'Bloqueada' },
         { id: 'charged_back', name: 'Charged back' },
         { id: 'dispute', name: 'Disputa' },
         { id: 'failed', name: 'Falhada' },
         { id: 'pending', name: 'Pendente' },
         { id: 'reversed', name: 'Pré-autorizado' },
         { id: 'refunded', name: 'Revertida' },
      ],
   };

   function onSubmit(data: any): void {
      const params = data;
      params.created_at_gte = data?.created_at_gte?.dateYYYYMMDD;
      params.created_at_lte = data?.created_at_lte?.dateYYYYMMDD;

      setFilters(() => params);
   }

   function generateExcel(): void {
      if (selected.size) {
         GenerateExcel({ ids: Array.from(selected.keys()) }, 'payments').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
               // window.open(`${APIServiceUrl}/excel/${link}`);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      } else {
         GenerateExcel(filters, 'payments').subscribe(
            (link) => {
               setFileDownloadGlobal(link);
            },
            (data) => {
               ToastList.show({
                  message: data.error,
                  type: 'error',
               });
            },
         );
      }
   }

   function selectAll(): void {
      if (checkedAll) {
         if (Array.from(selected.keys()).length === resources.length) {
            onUnselectAll();
         } else {
            resources.forEach((item) => {
               onSelect(item.id);
            });
         }
      } else {
         resources.forEach((item) => {
            onSelect(item.id);
         });
      }
   }

   const checkedAll = Array.from(selected.keys()).length === resources.length;

   return {
      onSubmit,
      formRef,
      checkedAll,
      generateExcel,
      reloadPage,
      setSort,
      starting,
      searching,
      errorLoadData,
      resources,
      resourcesTotal,
      skeletonResources,
      filters,
      filtersData,
      currentPage,
      setCurrentPage,
      checkSelected,
      onSelect,
      onUnselectAll,
      sum,
      sumPage,
      selected,
      userRole,
      selectAll,
      showSeller,
      pathname: location.pathname
   };
}
