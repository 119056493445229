import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   AuthMeAPI,
   AuthMeUpdateAPI,
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GetSellersSettings,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentLinksSearchResolve,
   SMSUpdateAPI,
   UpdateSellersSettings,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';

const yupValidateSchema = Yup.object().shape({
   name: Yup.string().required('Esse campo é obrigatório!'),
});

export function useSMSMessagePage(): any {
   const { id } = useParams<any>();
   document.title = 'Mensagem SMS - XPAG Tecnologia';

   const {
      starting,
      saving,
      resource,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id: 1,
      resolveGet: GetSellersSettings,
      resolveUpdate: UpdateSellersSettings,
      handleFormData: (d) => {
         const data = { ...d };

         data.EMAIL_SEND_ON_CREATE_BOLETO = data.EMAIL_SEND_ON_CREATE_BOLETO ? '1' : '0';
         data.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO = data.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO ? '1' : '0';
         data.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO = data.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO ? '1' : '0';
         data.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO = data.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO ? '1' : '0';

         data.SMS_SEND_ON_CREATE_BOLETO = data.SMS_SEND_ON_CREATE_BOLETO ? '1' : '0';
         data.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO = data.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO ? '1' : '0';
         data.SMS_SEND_ON_DAY_EXPIRED_BOLETO = data.SMS_SEND_ON_DAY_EXPIRED_BOLETO ? '1' : '0';
         data.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO = data.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO ? '1' : '0';

         return data;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao atualizar dados',
            type: 'error',
         });
      },
      onSuccess() {
         ToastList.show({
            message: 'Dados atualizados',
            type: 'success',
         });
      },
   });

   function onChangeMessage(v: any) {
      console.log(v.target.value);
   }

   // useLayoutEffect(() => {
   //    return registerOnInit((resources: any) => {
   //
   //       if (resources.resource) {
   //          const item = { ...resources.resource };
   //          formRef.current.setData(item);
   //       }
   //    });
   // });

   useDidUpdateEffect(() => {
      const data = { ...resource.data };
      data.EMAIL_SEND_ON_CREATE_BOLETO = data.EMAIL_SEND_ON_CREATE_BOLETO == '1';
      data.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO = data.EMAIL_SEND_ON_2_BEFORE_EXPIRED_BOLETO == '1';
      data.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO = data.EMAIL_SEND_ON_DAY_EXPIRED_BOLETO == '1';
      data.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO = data.EMAIL_SEND_AFTER_1_DAY_EXPIRED_BOLETO == '1';

      data.SMS_SEND_ON_CREATE_BOLETO = data.SMS_SEND_ON_CREATE_BOLETO == '1';
      data.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO = data.SMS_SEND_ON_2_BEFORE_EXPIRED_BOLETO == '1';
      data.SMS_SEND_ON_DAY_EXPIRED_BOLETO = data.SMS_SEND_ON_DAY_EXPIRED_BOLETO == '1';
      data.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO = data.SMS_SEND_AFTER_1_DAY_EXPIRED_BOLETO == '1';

      formRef.current.setData(data);
   }, [resource]);

   return {
      onSubmit: onSubmitForm,
      id,
      formRef,
      reloadPage,
      starting,
      saving,
      errorLoadData,
      onChangeMessage,
   };
}
