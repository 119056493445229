import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faCalendarCheck,
   faBarcode,
   faClock,
   faPaperPlane,
   faLongArrowAltUp,
   faLongArrowAltDown,
   faArrowUp,
   faArrowDown,
   faAngleDown,
   faSort,
   faSortDown,
   faSortUp,
   faLink,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   FlexContainer,
   FlexItem,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import Style, { ProgressColor } from './LinkPaymentsListingPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useLinkPaymentsListingPage } from '~/pages/LinkPaymentsListing/LinkPaymentsListingPage.controller';
import { TransferStatus } from '~/components/PaymentStatus/TransferStatus';

export const LinkPaymentsListingPage: React.FunctionComponent<any> = () => {
   const ctrl = useLinkPaymentsListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-3">
                  <div className="row align-items-center">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de Links de pagamento</h3>
                     </div>
                     <div className="col text-right">
                        <Button type="submit" size="lg" onClick={() => history.push('/payments/create/link-payments')}>
                           Nova venda
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {ctrl.loading && <LoadingPage />};
         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     {/* <div> */}
                     {/*   <b>Filtros</b> */}
                     {/* </div> */}
                     <div className="flex-grow-1 px-2">
                        <Input name="customer_name" title="Pagador" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_gte" title="Criado a partir" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_lte" title="Criado até" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="customer_group_id" title="Grupos" items={ctrl.filtersData.groups} />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="status" title="Status" items={ctrl.filtersData.status} />
                     </div>

                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="text-dark">
                           Mostrando <b>{ctrl.pageCountStart}</b>-<b>{ctrl.pageCountEnd}</b> de{' '}
                           <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div />
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           {/* <th> */}
                           {/*   {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'ID'} */}
                           {/* </th> */}
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Data da venda"
                                    sort="created_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           {ctrl.showSeller && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? (
                                    <Skeleton height={20} width={100} />
                                 ) : (
                                    'Estabelecimento'
                                 )}
                              </th>
                           )}
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Pagador"
                                    sort="first_name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Status"
                                    sort="status"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Valor"
                                    sort="amount"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Expira em"
                                    sort="expired_on"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Pago em"
                                    sort="payed_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((transaction: any) => (
                           <ItemListing
                              key={transaction.id}
                              transaction={transaction}
                              selected={ctrl.checkSelected(transaction.id)}
                              setSelected={ctrl.onSelect}
                              sendSMS={ctrl.sendOne}
                              showEstabelecimento={ctrl.showSeller}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   transaction,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   showEstabelecimento,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr onClick={openTransaction}>
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/link-payments/detail/${transaction.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            {/* <td onClick={openTransaction}>{transaction.id}</td> */}
            <td onClick={openTransaction}>
               <div>
                  <b>{formatDate(transaction.created_at)}</b>
                  <br />
                  {formaHour(transaction.created_at)}
               </div>
            </td>
            {showEstabelecimento && (
               <td onClick={openTransaction}>
                  <div className="text-dark">
                     <b>{transaction.seller_name}</b>
                  </div>
                  <div>
                     {transaction?.seller_document?.length > 11 ? 'CNPJ' : 'CPF'}:{' '}
                     {CNPJAndCPFMask(transaction?.seller_document)}
                  </div>
               </td>
            )}
            <td onClick={openTransaction}>
               <Link className="link_ref" to={`/customers/edit/${transaction.customer.id}`}>
                  {transaction.customer?.full_name}
               </Link>
            </td>
            <td className="text-center" onClick={openTransaction}>
               <TransferStatus transaction={transaction} />
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{formatPrice(transaction.amount * 100)}</b>
            </td>
            <td onClick={openTransaction} className="text-right text-nowrap">
               <ExpiredOn transaction={transaction} />
            </td>
            <td className="text-right text-nowrap">
               <b>{transaction.payed_at ? formatDate(transaction.payed_at) : '--'}</b>
            </td>
            <td className="text-right text-nowrap">
               <a href={transaction.url} className="btn-icon" target="_blank">
                  <FontAwesomeIcon icon={faLink} />
               </a>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);

const ExpiredOn = React.memo(function ExpiredOn({ transaction }: any): any {
   if (!transaction.expired_on) return <b>Não expira</b>;

   const diff = DateFNS.differenceInDays(DateFNS.format(convertStringToDate(transaction.expired_on)), new Date());

   if (diff <= 0) {
      return <b className="text-danger">{formatDate(transaction.expired_on)}</b>;
   }

   return <b className="text-success">{formatDate(transaction.expired_on)}</b>;
}, _.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);
