import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as _ from 'lodash';
import {
   faBarcode,
   faClock,
   faPaperPlane,
   faTimes,
   faSort,
   faSortDown,
   faSortUp,
   faFileExcel,
   faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   Button,
   Datepicker,
   Input,
   Select,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   FlexItem,
   FlexContainer,
   ToastList,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import QueryString from 'querystring';
import Style, { ProgressColor } from './BoletosListingPage.style';
import { PaymentStatus, LoadingPage } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import { useBoletosListingPage } from '~/pages/BoletosListing/BoletosListingPage.controller';
import history from '~/config/history';
import { ModalCancelBoleto } from '~/components/ModalCancelBoleto';
import { SelectMany } from '~/components/Customs/SelectMany';

export const BoletosListingPage: React.FunctionComponent<any> = () => {
   const ctrl = useBoletosListingPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="py-3">
                  <div className="row align-items-center">
                     <div className="col">
                        <h3 className="m-0 mb-1">Lista de boletos</h3>
                     </div>
                     <div className="col text-right">
                        <Button type="submit" size="lg" onClick={() => history.push('/payments/create/boleto')}>
                           Novo boleto
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {ctrl.loading && <LoadingPage />};
         <Container>
            <Card className="p-4">
               <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.filters}>
                  <h4>Filtros</h4>
                  <div className="filters d-flex">
                     {/* <div> */}
                     {/*   <b>Filtros</b> */}
                     {/* </div> */}
                     <div className="flex-grow-1 px-2">
                        <Input name="customer_name" title="Pagador" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expiration_date_gte" title="A partir de (vencimento)" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="expiration_date_lte" title="Até (vencimento)" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        {/* <Select name="status_group" title="Status" items={ctrl.filtersData.status} /> */}
                        <SelectMany name="status_group_array" title="Status" items={ctrl.filtersData.status} />
                     </div>
                  </div>
                  <div className="filters d-flex">
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_gte" title="Criado a partir" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Datepicker name="created_at_lte" title="Criado até" />
                     </div>
                     <div className="flex-grow-1 px-2">
                        <Select name="group_id" title="Grupos" items={ctrl.filtersData.groups} />
                     </div>

                     <div className="flex-fill px-2 btn-search">
                        <Button type="submit">Buscar</Button>
                     </div>
                  </div>
               </Form>
            </Card>

            {/* <FlexContainer> */}
            {/*   <FlexItem flex={1}> */}
            {/*      <Card className="p-4"> */}
            {/*         <h4 className="pb-2">Boletos vencidos</h4> */}
            {/*         <div className="row"> */}
            {/*            <div className="col-6"> */}
            {/*               <div className="position-relative p-3"> */}
            {/*                  <Doughnut {...ctrl.expiredGraphProps} /> */}
            {/*                  <div className="value-inside-graph expired"> */}
            {/*                     {formatPrice(ctrl.resource?.losers.price, 0)} */}
            {/*                  </div> */}
            {/*               </div> */}
            {/*            </div> */}
            {/*            <div className="col-6"> */}
            {/*               {ctrl.losersBar.map((data: any, i: number) => ( */}
            {/*                  <ProgressBarInfo */}
            {/*                     key={`loser-bar-${i}`} */}
            {/*                     data={data} */}
            {/*                     total={ctrl.resource?.losers?.total} */}
            {/*                  /> */}
            {/*               ))} */}
            {/*            </div> */}
            {/*         </div> */}
            {/*      </Card> */}
            {/*   </FlexItem> */}

            {/*   <FlexItem className="m-2" /> */}

            {/*   <FlexItem flex={1}> */}
            {/*      <Card className="p-4"> */}
            {/*         <h4 className="pb-2">Boletos a Vencer</h4> */}
            {/*         <div className="row"> */}
            {/*            <div className="col-6"> */}
            {/*               <div className="position-relative p-3"> */}
            {/*                  <Doughnut {...ctrl.toExpireGraphProps} /> */}
            {/*                  <div className="value-inside-graph">{formatPrice(ctrl.resource?.to_win.price, 0)}</div> */}
            {/*               </div> */}
            {/*            </div> */}
            {/*            <div className="col-6"> */}
            {/*               {ctrl.toWinBar.map((data: any, i: number) => ( */}
            {/*                  <ProgressBarInfo */}
            {/*                     key={`loser-bar-${i}`} */}
            {/*                     data={data} */}
            {/*                     total={ctrl.resource?.to_win?.total} */}
            {/*                  /> */}
            {/*               ))} */}
            {/*            </div> */}
            {/*         </div> */}
            {/*      </Card> */}
            {/*   </FlexItem> */}
            {/* </FlexContainer> */}

            <Card className="p-4">
               {ctrl.errorLoadData && <PageErrorLoad retry={() => ctrl.reloadPage()} nonFullscreen />}

               {!ctrl.resources.length && !ctrl.searching && !ctrl.starting && !ctrl.errorLoadData && (
                  <PageListingNotFound />
               )}

               <div className="d-flex pb-3">
                  <div className="flex-fill">
                     {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                        <div className="text-dark">
                           Mostrando <b>1</b>-<b>{ctrl.resourcesTotal < 20 ? ctrl.resourcesTotal : 20}</b> de{' '}
                           <b>{ctrl.resourcesTotal}</b> resultados
                        </div>
                     )}
                  </div>
                  <div>
                     {!!ctrl.selected.size && (
                        <Button rounded outline variant="danger" onClick={ctrl.cancelMany}>
                           <FontAwesomeIcon icon={faTimes} /> <b>Cancelar boletos</b>
                        </Button>
                     )}
                     &nbsp;
                     <Button rounded outline disabled={!ctrl.selected.size} onClick={ctrl.sendSMS}>
                        <FontAwesomeIcon icon={faPaperPlane} /> Enviar SMS
                     </Button>
                     &nbsp;
                     <Button rounded outline variant="success" onClick={ctrl.generateExcel}>
                        <FontAwesomeIcon icon={faFileExcel} /> <b>Gerar Excel</b>
                     </Button>
                  </div>
               </div>

               <div className="text-right pb-2">
                  {ctrl.sum ? (
                     <>
                        Total Página: <b className="price text-info">{formatPrice((ctrl.sumPage ?? 0) * 100)}</b> -{' '}
                        Total: <b className="price text-success">{formatPrice((ctrl.sum ?? 0) * 100)}</b>{' '}
                     </>
                  ) : (
                     <>--</>
                  )}
               </div>

               <Table striped bordered hover className="listing">
                  {(ctrl.resources.length || ctrl.searching || ctrl.starting) && !ctrl.errorLoadData && (
                     <thead>
                        <tr>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={16} width={16} />
                              ) : (
                                 <div className="checkbox-btl">
                                    <input type="checkbox" checked={ctrl.checkedAll} onChange={ctrl.selectAll} />
                                    <div className="squad" />
                                 </div>
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 'ID da transação'
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Data da venda"
                                    sort="created_at"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           {ctrl.showSeller && (
                              <th>
                                 {ctrl.starting || ctrl.searching ? (
                                    <Skeleton height={20} width={100} />
                                 ) : (
                                    'Estabelecimento'
                                 )}
                              </th>
                           )}
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Pagador"
                                    sort="first_name"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th>
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Vencimento"
                                    sort="expiration_date"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-center">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Status"
                                    sort="status_group"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? (
                                 <Skeleton height={20} width={100} />
                              ) : (
                                 <SortTitle
                                    currentSort={ctrl.filters.sort}
                                    title="Valor"
                                    sort="amount"
                                    setSort={ctrl.setSort}
                                 />
                              )}
                           </th>
                           <th className="text-right">
                              {ctrl.starting || ctrl.searching ? <Skeleton height={20} width={100} /> : 'Valor pago'}
                           </th>
                           <th className="text-right" />
                        </tr>
                     </thead>
                  )}
                  <tbody>
                     {!ctrl.starting &&
                        !ctrl.searching &&
                        !ctrl.errorLoadData &&
                        ctrl.resources &&
                        ctrl.resources.map((transaction: any) => (
                           <ItemListing
                              key={transaction.id}
                              transaction={transaction}
                              selected={ctrl.checkSelected(transaction.id)}
                              setSelected={ctrl.onSelect}
                              sendSMS={ctrl.sendOne}
                              cancelBoleto={ctrl.cancelBoleto}
                              showEstabelecimento={ctrl.showSeller}
                           />
                        ))}

                     {(ctrl.starting || ctrl.searching) &&
                        ctrl.skeletonResources.map((transaction: any) => <ItemListing key={transaction} skeleton />)}
                  </tbody>
               </Table>

               <ModalCancelBoleto
                  show={ctrl.modalCancel}
                  loading={ctrl.loading}
                  onRequestClose={() => ctrl.setModalCancel(false)}
                  onCancel={ctrl.onCancelModal}
               />

               <br />
               <br />
               {!ctrl.errorLoadData && (
                  <Pagination
                     total={ctrl.resourcesTotal}
                     perPage={20}
                     currentPage={ctrl.currentPage}
                     changePage={(page: number) => {
                        ctrl.setCurrentPage(page);
                     }}
                  />
               )}
            </Card>
         </Container>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({
   transaction,
   skeleton,
   selected,
   setSelected,
   sendSMS,
   cancelBoleto,
   showEstabelecimento,
}: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr onClick={openTransaction}>
            <td className="text-center">
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/payments/detail/${transaction.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td className="text-center">
               <div className="checkbox-btl">
                  <input
                     type="checkbox"
                     checked={selected}
                     onChange={(ev) => {
                        setSelected(transaction.id, ev.target.checked);
                     }}
                  />
                  <div className="squad" />
               </div>
            </td>
            <td className="text-nowrap">
               <CopyToClipboard
                  text={transaction.id}
                  onCopy={() =>
                     ToastList.show({
                        message: 'ID copiado com sucesso',
                        type: 'black',
                     })
                  }
               >
                  <span>
                     {transaction.id?.substr(0, 4)}...{transaction.id?.substr(-4)}
                     &nbsp;
                     <FontAwesomeIcon icon={faCopy} />
                  </span>
               </CopyToClipboard>
            </td>
            <td onClick={openTransaction}>
               <div>
                  <b>{formatDate(transaction.created_at)}</b>
                  <br />
                  {formaHour(transaction.created_at)}
               </div>
            </td>
            {showEstabelecimento && (
               <td onClick={openTransaction}>
                  <div className="text-dark">
                     <b>{transaction.seller_name}</b>
                  </div>
                  <div>
                     {transaction?.seller_document?.length > 11 ? 'CNPJ' : 'CPF'}:{' '}
                     {CNPJAndCPFMask(transaction?.seller_document)}
                  </div>
               </td>
            )}
            <td>
               {!!transaction?.customer?.id && (
                  <Link className="link_ref" to={`/customers/edit/${transaction.customer.id}`}>
                     {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                     {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </Link>
               )}

               {!transaction?.customer?.id && (
                  <>
                     {!!transaction.payment_method?.holder_name && transaction.payment_method.holder_name}
                     {!transaction.payment_method?.holder_name && <>{transaction.customer?.full_name}</>}
                     {transaction.payment_type == 'commission' && <>{transaction.seller_name}</>}
                     {transaction.payment_type !== 'commission' &&
                        !transaction.payment_method?.holder_name &&
                        !transaction.customer?.full_name && <>--</>}
                  </>
               )}
            </td>
            <td onClick={openTransaction}>
               <b>{formatDate(transaction.expiration_date)}</b>
            </td>
            <td className="text-center" onClick={openTransaction}>
               <PaymentStatus transaction={transaction} />
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{formatPrice(transaction.original_amount * 100)}</b>
            </td>
            <td className="text-right text-nowrap" onClick={openTransaction}>
               <b>{transaction.amount_paid ? formatPrice(transaction.amount_paid * 100) : '--'}</b>
            </td>
            <td className="text-right text-nowrap">
               {!transaction.cancel && (
                  <span onClick={() => cancelBoleto(transaction.id, !transaction.cancel)} className="btn-icon ml-2">
                     <FontAwesomeIcon icon={faTimes} /> Cancelar
                  </span>
               )}
               {/* {!!transaction.cancel && ( */}
               {/*   <span */}
               {/*      onClick={() => cancelBoleto(transaction.id, !transaction.cancel)} */}
               {/*      className="btn-icon ml-2 bg-danger text-white" */}
               {/*   > */}
               {/*      <FontAwesomeIcon icon={faTimes} /> Descancelar */}
               {/*   </span> */}
               {/* )} */}
               <span onClick={() => sendSMS(transaction.id)} className="btn-icon ml-2">
                  <FontAwesomeIcon icon={faPaperPlane} />
               </span>
               <a href={transaction.url} className="btn-icon" target="_blank">
                  <FontAwesomeIcon icon={faBarcode} />
               </a>
            </td>
         </tr>
      </Fade>
   );
},
_.isEqual);

const SortTitle = React.memo(function SortTitle({ title, sort, currentSort, setSort }: any): any {
   function updateSort() {
      if (currentSort !== sort && currentSort !== `-${sort}`) {
         setSort(sort);
      } else if (currentSort === sort) {
         setSort(`-${sort}`);
      } else {
         setSort('');
      }
   }

   return (
      <div className="text-nowrap pointer-event" onClick={updateSort}>
         {title}
         <span className="pl-2">
            {currentSort === sort && <FontAwesomeIcon icon={faSortUp} className="text-primary" />}
            {currentSort === `-${sort}` && <FontAwesomeIcon icon={faSortDown} className="text-primary" />}
            {currentSort !== sort && currentSort !== `-${sort}` && (
               <FontAwesomeIcon icon={faSort} className="text-light" />
            )}
         </span>
      </div>
   );
}, _.isEqual);

function ProgressBarInfo({ data, total }: any) {
   return (
      <div className="bars-container">
         <div className="bar-container">
            <Link to={`/boletos?${QueryString.stringify(data.url)}`}>
               <div className="bar-name">{data.name}</div>
               <div className="progress-container">
                  <div className="progress-bar-container">
                     <ProgressColor color={data.color}>
                        <div className="progress">
                           <div
                              className="progress-bar"
                              style={{ width: `${parseInt(((data.count / total) * 100) as any, 10)}%` }}
                           />
                        </div>
                     </ProgressColor>
                  </div>

                  <span className="progress-value">
                     {data.count}/{total}
                  </span>
               </div>
            </Link>
         </div>
      </div>
   );
}
