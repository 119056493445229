import { IAction } from '~/redux/interfaces';
import { IAuthReducer, typeAuthLogout, typeSetAuth } from '~/redux/modules/auth/auth.interface';

export function actionSetAuth(isLogged: boolean, token = null): IAction<IAuthReducer> {
   return {
      type: typeSetAuth,
      payload: {
         token,
         isLogged,
      },
   };
}
export function actionSetLogout(): IAction<any> {
   return {
      type: typeAuthLogout,
      payload: null,
   };
}
