import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import {
   Button,
   Modal,
   Input,
   Pagination,
   PageErrorLoad,
   PageListingNotFound,
   convertStringToDate,
   SelectAutocomplete,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import DateFNS from 'date-fns';
import QueryString from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Style, { ProgressColor } from './CustomersCreatePage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { CNPJAndCPFMask, formaHour, formatDate, formatPrice } from '~/services';
import history from '~/config/history';
import { useCustomersListingPage } from '~/pages/CustomersListing/CustomersListingPage.controller';
import { useCustomersCreatePage } from '~/pages/CustomersCreate/CustomersCreatePage.controller';
import { GroupsSearchResolve } from '~/api';
import {Select} from "~/components/Customs/Select";
import {SellerSelectInput} from "~/components/SellerSelectInput";

export const CustomersCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useCustomersCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">{ctrl.editing ? `Editar cliente` : 'Novo cliente'}</h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={{ group_id: '' }}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Dados do contato</div>

                     <br />

                     <SellerSelectInput />
                     
                     <div className="row field-box">
                        <div className="col">
                           <Select title="Grupo" options={ctrl.formInfoData.groups} name="group_id" />
                        </div>
                        <div className="col">
                           <div className="btn-search">
                              <Button onClick={ctrl.openCreateGroup}>Criar novo grupo</Button>
                           </div>
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col">
                           <Select
                              name="person_type"
                              title="Tipo de Pessoa"
                              options={ctrl.formInfoData.person_type}
                              onChange={ctrl.onChangePersonType}
                              disabled={ctrl.editing}
                           />
                        </div>
                     </div>

                     {ctrl.personType == 1 && (
                        <>
                           <div className="row field-box">
                              <div className="col-md-6">
                                 <Input name="taxpayer_id" title="CPF" CPF disabled={ctrl.editing} />
                              </div>
                           </div>

                           <div className="row field-box">
                              <div className="col-md-6">
                                 <Input name="first_name" title="Nome" disabled={ctrl.editing} />
                              </div>

                              <div className="col-md-6">
                                 <Input name="last_name" title="Sobrenome" disabled={ctrl.editing} />
                              </div>
                           </div>
                        </>
                     )}

                     {ctrl.personType == 2 && (
                        <>
                           <div className="row field-box">
                              <div className="col-md-6">
                                 <Input name="taxpayer_id" title="CNPJ" CNPJ disabled={ctrl.editing} />
                              </div>
                           </div>

                           <div className="row field-box">
                              <div className="col-md-6">
                                 <Input name="business_name" title="Nome fantasia" disabled={ctrl.editing} />
                              </div>
                           </div>
                        </>
                     )}

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="email" title="Email" />
                        </div>

                        <div className="col-md-6">
                           <Input name="phone_number" title="Celular" phone />
                        </div>
                     </div>
                  </div>
                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">Endereço</div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="address_postal_code" title="CEP" CEP onInput={ctrl.onChangeCep} loading={ctrl.isLoadingCep} />
                        </div>

                        <div className="col-md-6" />
                     </div>

                     <div className="row field-box">
                        <div className="col-md-9">
                           <Input name="address_address" title="Endereço" />
                        </div>

                        <div className="col-md-3">
                           <Input name="address_number" title="Número" number />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="address_neighborhood" title="Bairro" />
                        </div>

                        <div className="col-md-5">
                           <Input name="address_city" title="Cidade" />
                        </div>

                        <div className="col-md-3">
                           <Select name="address_state" title="Estado" options={ctrl.formInfoData.states} />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>{ctrl.editing ? 'Editar cliente' : 'Criar novo cliente'}</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
