import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useView,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useHistory, useParams } from 'react-router-dom';
import {
   DataDashResolve,
   PaymentsGetResolve,
   PaymentsSearchResolve,
   SubscriptionsDeleteResolve,
   SyncZoopResolve,
} from '~/api';

export function usePaymentsDetailPage(): any {
   const { id } = useParams() as any;
   document.title = 'Detalhes da venda - XPAG Tecnologia';
   const history = useHistory();

   const { starting, errorLoadData, reloadPage, registerResolveParams, registerOnInit, setStatusInfo } = useView({
      resolves: {
         resource: PaymentsGetResolve,
      },
   });

   const [resource, setResource] = useState();
   const formRef = useRef<FormHandles>();

   registerResolveParams('resource', () => id);

   registerOnInit((res: any) => {
      // colocar original_amount = amount pois mostra saldo da conta o inves do valor "original"
      if (res.resource.payment_type == 'commission') {
         res.resource.original_amount = res.resource.amount;
      }
      setResource(res.resource);
   });

   function goBack() {
      history.goBack();
   }

   function onSync() {
      setStatusInfo(() => ({
         loading: true,
      }));
      SyncZoopResolve(id).subscribe(
         () => {
            setStatusInfo(() => ({
               loading: false,
            }));
            ToastList.show({
               message: 'Boleto sincronizado com sucesso!',
               type: 'success',
            });
            reloadPage(false);
         },
         () => {
            setStatusInfo(() => ({
               loading: false,
            }));
         },
      );
   }

   function cancelSubscription(id: any) {
      const confirm = window.confirm(
         `Tem certeza que deseja cancelar essa assinatura? Essa ação não poderá ser desfeita.`,
      );

      if (!confirm) return;

      setStatusInfo(() => ({
         loading: true,
      }));
      SubscriptionsDeleteResolve(id).subscribe(
         () => {
            reloadPage(false);
            setStatusInfo(() => ({
               loading: false,
            }));
            ToastList.show({
               message: 'Assinatura cancelada com sucesso!',
            });
         },
         () => {
            setStatusInfo(() => ({
               loading: false,
            }));
            ToastList.show({
               message: 'Erro ao cancelar assinatura',
               type: 'error',
            });
         },
      );
   }

   // useDidUpdateEffect(() => {
   //    // setPage(currentPage);
   // }, [currentPage]);

   return {
      onSync,
      starting,
      errorLoadData,
      resource,
      goBack,
      cancelSubscription,
   };
}
