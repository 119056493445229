import styled from 'styled-components';

const css = styled.div;

export const FormStyle = css<any>`
   .input-title {
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 3px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
      transition: 0.2s all;

      &.focus {
         transition: 0.2s all;
      }
   }

   .input-border {
      display: flex;
      align-content: center;
      align-items: center;
      width: 100%;
      background: white;
      border: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: #444;
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      transition: 0.2s all;
      cursor: pointer;

      ${(props) =>
         props.themeInput === 'gray' &&
         `background: #eee;
            border-color: #eee;
        `}

      .prefix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-left: 10px;
         font-size: 14px;
         align-items: center;
         justify-content: center;
         display: flex;
      }

      .suffix {
         flex: 0;
         vertical-align: middle;
         height: 100%;
         padding-right: 10px;
         font-size: 14px;
         align-items: center;
         justify-content: center;
         display: flex;
      }

      &.has-error {
      }

      input {
         padding: 7px 10px;
         width: 100%;
         border: none;
         flex: 1;
         background: transparent;
         font-size: 14px;
         font-weight: 500;
      }

      .prefix ~ input {
         padding-left: 10px;
      }

      &.text-right {
         input {
            text-align: right;
         }
      }

      &.input-hidden {
         border: none;
      }

      &.focus {
         transition: 0.2s all;
      }
   }

   .error-input {
      text-align: left;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      font-size: 13px;
      margin-top: 4px;
      margin-bottom: 10px;
   }

   &.hide {
      display: none;
   }
`;

export const Container = css<any>`
   position: relative;

   .input-border {
      height: 40px !important;
      width: 100% !important;
   }
   
   .remove {
      display: inline-flex;
      height: 23px;
      width: 23px;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
   }

   .text-selected {
      color: #007bff;
      display: flex;
   }
   
   .flex-1 {
      flex: 1;
   }

   .dropdrown {
      position: absolute;
      min-width: 100%;
      background: white;
      z-index: 3;
      border: 1px solid #ddd;
      border-radius: 3px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

      .item {
         padding: 3px 10px;
         font-size: 14px;
         white-space: nowrap;
         display: flex;
         align-items: center;
         cursor: pointer;

         &:hover {
            background: #eee;
         }

         .checkbox {
            margin-right: 10px;
            border: 1px solid #ddd;
            border-radius: 3px;
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
               display: none;
            }
         }

         &.active {
            display: flex;
            align-items: center;
            cursor: pointer;

            .checkbox {
               .icon {
                  font-size: 9px;
                  color: #007bff;
                  display: block;
               }
            }
         }
      }
   }
`;
