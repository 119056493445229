import React, { InputHTMLAttributes, useImperativeHandle, useRef, useState } from 'react';
import { LoaderIcon, useClickOutside } from '@devesharp/react/web/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useImmer } from 'use-immer';
import { ISelectProps } from './Select.interface';
import * as S from './Select.style';
import { FormStyle } from './Select.style';
import { UnformInput } from '~/components/Customs/unform-input';

export const SelectMany = React.forwardRef<InputHTMLAttributes<any>, ISelectProps>(function SelectMany(
   props,
   inputRef: any,
) {
   const { title } = props;
   const [focus, setFocus] = useState();
   const [items, setItems] = useImmer<any>([]);

   const [open, setOpen] = useState(false);

   const dropDownRef = useRef<any>(null);

   useClickOutside(() => {
      setOpen(false);
   }, dropDownRef);

   function onSelectedChange(value: any): any {
      setItems((v: any) => {
         if (!v) {
            v = [];
         }

         if (v.find((x: any) => x === value)) {
            return v.filter((x: any) => x !== value);
         }
         v.push(value);

         return v;
      });
   }

   function removeAll() {
      setItems(() => []);
   }

   return (
      <FormStyle className="select-input-container">
         <UnformInput name={props.name} value={items} onChange={(d) => setItems(() => d)} />
         <S.Container>
            {title && <div className={`input-title ${focus && 'focus'}`}>{title}</div>}

            <div className="select-box" ref={dropDownRef}>
               <span className="input-border" onClick={() => setOpen(!open)}>
                  {items?.length == 0 && 'Selecionar'}
                  <span className="text-selected">
                     <div className="flex-1">
                        {items?.length > 0 && props.items?.find((i: any) => i.id == items[0])?.name}
                        {items?.length > 1 && ` +${items?.length - 1}`}
                     </div>

                     {items?.length > 0 && (
                        <div className="remove" onClick={removeAll}>
                           <div>
                              <FontAwesomeIcon icon={faTimes} />
                           </div>
                        </div>
                     )}
                  </span>
               </span>

               {open && (
                  <div className="dropdrown">
                     {props.items.map((item) => (
                        <Item
                           key={item.name}
                           name={item.name}
                           value={(item as any).id}
                           onSelectedChange={onSelectedChange}
                           isActive={!!items?.find((i: any) => i == (item as any).id)}
                        />
                     ))}
                  </div>
               )}
            </div>
         </S.Container>
      </FormStyle>
   );
});

function Item({ name, value, isActive, onSelectedChange }: any): any {
   return (
      <div className={`item ${isActive && 'active'}`} onClick={() => onSelectedChange(value)}>
         <div>
            <div className="checkbox">
               <span className="icon">
                  <FontAwesomeIcon icon={faCheck} />
               </span>
            </div>
         </div>
         <div>{name}</div>
      </div>
   );
}
