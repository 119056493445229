import React, { useEffect, useRef, useState } from 'react';
import DateFNS from 'date-fns';
import { convertStringToDate, LoaderIcon } from '@devesharp/react/web';
import Style from './PaymentStatus.style';

export function BookletsBoletoStatus({ status }: any): any {
   let text = '';
   const currentStatus = status;

   switch (currentStatus) {
      case 'queue':
         text = 'Na Fila';
         break;
      case 'creating':
         text = 'Criando';
         break;
      case 'error':
         text = 'Erro';
         break;
   }

   return (
      <Style>
         <div className={`badge badge-${currentStatus}`}>
            {text} {currentStatus != 'error' && <LoaderIcon size={12} color="white" />}
         </div>
      </Style>
   );
}
