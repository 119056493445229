import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import {
   Button,
   Input,
   Select,
   Radio,
   Textarea,
   Datepicker,
   SelectAutocomplete,
   NumberFormat,
} from '@devesharp/react/web';
import { Form } from '@unform/web';
import { faBarcode, faCreditCard, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PhoneMask } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import Style, { ProgressColor } from './PaymentsCreateStep3.style';
import { usePaymentsCreateStep1 } from '~/pages/PaymentsCreate/Step1/PaymentsCreateStep1.controller';
import { usePaymentsCreateStep2 } from '~/pages/PaymentsCreate/Step2/PaymentsCreateStep2.controller';
import { CNPJAndCPFMask, formatDate, formatPrice } from '~/services';

export const PaymentsCreateStep3: React.FunctionComponent<any> = ({ backStep, onSubmit, data }: any) => {
   return (
      <Style>
         <Card className="p-n">
            <div className="form-padding ">
               <div className="form-title">Dados da venda</div>

               <br />

               <div className="row field-box">
                  <div className="col-md-6">
                     <div>
                        <div className="input-title false">Valor da venda</div>
                        <b>{formatPrice(data.amount)}</b>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div>
                        <div className="input-title false">Forma de pagamento</div>
                        <b>
                           {data.type == 'credit'
                              ? 'Cartão de crédito'
                              : data.type == 'boleto'
                              ? 'Boleto'
                              : 'Link de pagamento'}
                        </b>
                     </div>
                  </div>
               </div>

               <div className="row field-box">
                  <div className="col">
                     <div>
                        <div className="input-title false">Descrição</div>
                        <b>{data.description}</b>
                     </div>
                  </div>
               </div>

               {data.type == 'boleto' && (
                  <>
                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Vencimento da Primeira Parcela</div>
                              <b>{formatDate(data.expiration_date)}</b>
                           </div>
                        </div>

                        <div className="col-md-6" />
                     </div>

                     {data.fine_percent && data.interest_percent && (
                        <div className="row field-box">
                           {data.fine_percent && (
                              <div className="col-md-6">
                                 <div>
                                    <div className="input-title false">Multa em Porcentagem (Máximo 2%)</div>
                                    <b>{NumberFormat(data.fine_percent / 100)}</b>
                                 </div>
                              </div>
                           )}

                           {data.interest_percent && (
                              <div className="col-md-6">
                                 <div>
                                    <div className="input-title false">Juros diário (Máximo 3,3%)</div>
                                    <b>{NumberFormat(data.interest_percent / 100)}</b>
                                 </div>
                              </div>
                           )}
                        </div>
                     )}

                     {data.discount_value && data.discount_date && (
                        <div className="row field-box">
                           {data.discount_value && (
                              <div className="col-md-6">
                                 <div>
                                    <div className="input-title false">Valor do desconto</div>
                                    <b>{formatPrice(data.discount_value)}</b>
                                 </div>
                              </div>
                           )}

                           {data.discount_date && (
                              <div className="col-md-6">
                                 <div>
                                    <div className="input-title false">Vencimento da desconto</div>
                                    <b>{formatDate(data.discount_date)}</b>
                                 </div>
                              </div>
                           )}
                        </div>
                     )}
                  </>
               )}
            </div>

            {data.type == 'credit' && (
               <>
                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">
                        <span className="text-primary">
                           <FontAwesomeIcon icon={faCreditCard} />
                        </span>{' '}
                        Dados do Cartão
                     </div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Nome no cartão</div>
                              <b>{data.card.holder_name}</b>
                           </div>
                        </div>

                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Número do cartão</div>
                              <b>{data.card.card_number}</b>
                           </div>
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Vencimento</div>
                              {data.card.expiration_month}/{data.card.expiration_year}
                           </div>
                        </div>

                        <div className="col-md-4">
                           <div>
                              <div className="input-title false">CVV</div>
                              <b>{data.card.security_code}</b>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            )}

            {data.bookletEnabled && (
               <>
                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">
                        <span className="text-primary">
                           <FontAwesomeIcon icon={faTicketAlt} />
                        </span>{' '}
                        Dados do Carnê
                     </div>
                     <br />

                     <div className="row field-box">
                        <div className="col-md-8">
                           <div>
                              <div className="input-title false">Nome do Carnê</div>
                              <b>{data.booklet.name}</b>
                           </div>
                        </div>

                        <div className="col-md-6" />
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Numero de parcelas</div>
                              <b>{data.booklet.installments}</b>
                           </div>
                        </div>

                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Intervalos do Carnê</div>
                              <b>{data.booklet.installments_interval} dias</b>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            )}

            <hr />

            <div className="form-padding ">
               <div className="form-title">Dados do cliente</div>

               <br />

               {data.customer.person_type == 1 && (
                  <>
                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Tipo de pessoa</div>
                              <b>Física</b>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">CPF</div>
                              <b>{CNPJAndCPFMask(data.customer.taxpayer_id)}</b>
                           </div>
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Nome</div>
                              <b>{data.customer.first_name}</b>
                           </div>
                        </div>

                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Sobrenome</div>
                              <b>{data.customer.last_name}</b>
                           </div>
                        </div>
                     </div>
                  </>
               )}

               {data.customer.person_type == 2 && (
                  <>
                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Tipo de pessoa</div>
                              <b>Júrica</b>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">CNPJ</div>
                              <b>{CNPJAndCPFMask(data.customer.taxpayer_id)}</b>
                           </div>
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <div>
                              <div className="input-title false">Nome fantasia</div>
                              <b>{CNPJAndCPFMask(data.customer.business_name)}</b>
                           </div>
                        </div>
                     </div>
                  </>
               )}

               <div className="row field-box">
                  <div className="col-md-6">
                     <div>
                        <div className="input-title false">Email</div>
                        <b>{data.customer.email}</b>
                     </div>
                  </div>

                  <div className="col-md-6">
                     <div>
                        <div className="input-title false">Celular</div>
                        <b>{PhoneMask(data.customer.phone_number)}</b>
                     </div>
                  </div>
               </div>

               <div className="row field-box">
                  <div className="col-md-4">
                     <div>
                        <div className="input-title false">CEP</div>
                        <b>{data.customer.address.postal_code}</b>
                     </div>
                  </div>

                  <div className="col-md-6" />
               </div>

               <div className="row field-box">
                  <div className="col-md-9">
                     <div>
                        <div className="input-title false">Endereço</div>
                        <b>{data.customer.address.line1}</b>
                     </div>
                  </div>

                  <div className="col-md-3">
                     <div>
                        <div className="input-title false">Número</div>
                        <b>{data.customer.address.line2}</b>
                     </div>
                  </div>
               </div>

               <div className="row field-box">
                  <div className="col-md-4">
                     <div>
                        <div className="input-title false">Bairro</div>
                        <b>{data.customer.address.neighborhood}</b>
                     </div>
                  </div>

                  <div className="col-md-5">
                     <div>
                        <div className="input-title false">Cidade</div>
                        <b>{data.customer.address.city}</b>
                     </div>
                  </div>

                  <div className="col-md-3">
                     <div>
                        <div className="input-title false">Estado</div>
                        <b>{data.customer.address.state}</b>
                     </div>
                  </div>
               </div>
            </div>

            {/* <hr /> */}
         </Card>
         <div className="text-right pt-2">
            <span className="text-right pr-2">
               <Button size="lg" onClick={backStep} variant="light">
                  <b>Voltar</b>
               </Button>
            </span>
            <Button size="lg" onClick={onSubmit}>
               <b>Avançar</b>
            </Button>
         </div>
         <br />
         <br />
      </Style>
   );
};
