import React, { useRef, useEffect, useImperativeHandle, useContext } from 'react';
import { FormContext, useField } from '@unform/core';
import { useDidUpdateEffect } from '@devesharp/react/web';
import { IUnformInputProps, IUnformInputRef } from './unform-input.interface';

export const UnformInput = React.forwardRef<IUnformInputRef, IUnformInputProps>(function UnformInput(
   props,
   inputRef: any,
) {
   // const {
   //    initialData
   // } = useContext(FormContext)

   // console.log(initialData, props.name);

   const { fieldName, registerField, defaultValue = null, error, clearError } = useField(props.name);

   const ref = useRef<any>({
      value: defaultValue ?? null,
   });
   useImperativeHandle(inputRef, () => ref.current, []);

   useEffect(() => {
      if (props.onError) props.onError(error as any);
   }, [error]);

   useEffect(() => {
      (props as any).onChange?.(defaultValue);
   }, []);

   // Não remove erro inicial
   useDidUpdateEffect(() => {
      if (error) {
         clearError();
      }
   }, [props.value]);

   useEffect(() => {
      if (props.value !== undefined) {
         ref.current.value = props.value;
      }
   }, [props.value]);

   useEffect(() => {
      if (props.value !== undefined) {
         ref.current.value = props.value;
      }
   }, []);

   useEffect(() => {
      registerField({
         name: fieldName,
         ref,
         path: 'current' as any,
         clearValue(_ref) {
            ref.current.value = null;
            if (props.onChange) props.onChange(null);
         },
         setValue(_ref: any, value: string) {
            ref.current.value = value;
            if (props.onChange) props.onChange(value);
         },
         getValue(_ref: any) {
            return props.getValue ? props.getValue() : ref.current.value;
         },
      });
   }, [fieldName, registerField, props.getValue]);

   return null;
});
