import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   .checkbox-container {
      cursor: pointer;
      display: flex;
      flex-direction: row;
   }

   .switch-btn-container {
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 40px;
      height: 20px;
      background: grey;
      background: ${(props) => (props.checked ? props.color ?? props.theme.primary : '#ddd')};
      border-radius: 40px;
      position: relative;
      transition: background-color 0.2s;
      margin-right: 5px;

      .switch-button {
         content: '';
         position: absolute;
         top: 2px;
         left: 2px;
         width: 16px;
         height: 16px;
         border-radius: 20px;
         transition: 0.2s;
         background: #fff;
         box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
      }

      input {
         display: none;

         &:checked + .switch-button {
            left: calc(100% - 2px);
            transform: translateX(-100%);
         }
      }

      &.disabled {
         opacity: 0.7;

         &:hover {
            cursor: not-allowed !important;
         }
      }
   }

   .title {
      align-self: center;
      padding-left: 5px;
      cursor: pointer;

      &.disabled {
         opacity: 0.6;
         cursor: not-allowed !important;

         &:hover {
            cursor: not-allowed !important;
         }
      }
   }

   .has-error {
      border-color: ${(props) => props.theme.red} !important;
   }

   .error-input {
      color: ${(props) => props.theme.red};
      text-align: left;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      font-size: 13px;
      margin-top: 2px;
      margin-bottom: 10px;
   }
`;
