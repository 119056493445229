import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete } from '@devesharp/react/web';
import { Form } from '@unform/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Style, { ProgressColor } from './BanksAccountsCreatePage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { useBanksAccountsCreatePage } from '~/pages/BanksAccountsCreate/BanksAccountsCreatePage.controller';
import { accountType, bankCodes } from '~/services/data';

export const BanksAccountsCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useBanksAccountsCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">
                           <h3 className="m-0 mb-1">Alterar conta bancária</h3>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={ctrl.initialData}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Dados do dono da conta</div>

                     <br />

                     {/* messagege alert */}

                     <div className="alert alert-danger" role="alert">
                        Atenção! Para realizar a transferência bancária, é necessário que o titular da conta seja o
                        mesmo CPF/CNPJ cadastrado na plataforma.
                     </div>
                     <br />

                     <div className="row field-box">
                        <div className="col">
                           <Select
                              name="person_type"
                              title="Tipo de Pessoa"
                              items={ctrl.formInfoData.person_type}
                              onChange={ctrl.onChangePersonType}
                              disabled
                           />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input
                              name="holder_name"
                              disabled
                              title={ctrl.personType == 1 ? 'Nome completo' : 'Nome da empresa'}
                           />
                        </div>

                        {ctrl.personType == 1 && (
                           <>
                              <div className="col-md-6">
                                 <Input name="taxpayer_id" title="CPF" CPF disabled />
                              </div>
                           </>
                        )}

                        {ctrl.personType == 2 && (
                           <>
                              <div className="col-md-6">
                                 <Input name="taxpayer_id" title="CNPJ" CNPJ disabled />
                              </div>
                           </>
                        )}
                     </div>

                     <div className="d-none">
                        <Input name="customer_id" />
                     </div>
                  </div>
                  <hr />
                  <div className="form-padding ">
                     <div className="form-title">Dados da conta</div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-7">
                           <Select name="type" title="Tipo de Conta" items={ctrl.formInfoData.accountType} />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-7">
                           <Select name="bank_code" title="Banco" items={ctrl.formInfoData.bankCodes} />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-4">
                           <Input name="routing_number" title="Agência da Conta" />
                        </div>

                        <div className="col-md-3">
                           <Input name="account_number" title="Conta Bancária" />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Criar conta</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
