import React, { useLayoutEffect, useState } from 'react';
import { Tabs, useView, useDidUpdateEffect } from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { useRecoilState } from 'recoil';
import { DataDashResolve } from '~/api';
import { formatPrice } from '~/services';
import { sellerSelectState } from '~/recoil/seller-select.state';

const grahSettings = {
   data: {
      labels: [''],
      datasets: [
         {
            label: '# of Votes',
            data: [100],
            backgroundColor: ['#eee'],
         },
      ],
   },
   options: {
      cutoutPercentage: 75,
      legend: {
         display: false,
      },
      tooltips: {
         callbacks: {
            title(tooltipItem: any, data: any) {
               return data.labels[tooltipItem[0].index];
            },
            label() {
               return '';
            },
            afterLabel(tooltipItem: any, data: any) {
               return formatPrice(data?.prices?.[tooltipItem?.index], 0);
            },
         },
      },
   },
   height: 250,
};

export function useHomePage(): any {
   document.title = 'Dashboard - XPAG Tecnologia';

   const [resource, setResource] = useState<any>();
   const { starting, registerResolveParams, registerOnInit, reloadPage } = useView({
      resolves: {
         resource: DataDashResolve,
      },
   });

   /**
    *
    */
   const [expiredGraphProps, setExpiredGraphProps] = useState<any>(grahSettings);
   const [toExpireGraphProps, setToExpireGraphProps] = useState<any>(grahSettings);
   const [losersBar, setLosersBar] = useState<any[]>([]);
   const [toWinBar, setToWinBar] = useState<any[]>([]);
   const [statement, setStatement] = useState([]);

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   useDidUpdateEffect(() => {
      setExpiredGraphProps(grahSettings);
      setToExpireGraphProps(grahSettings);
      setLosersBar([]);
      setToWinBar([]);
      setStatement([]);
      reloadPage(true);
   }, [sellerSelect]);

   useLayoutEffect(() => {
      return registerOnInit((v: any) => {
         setResource(v.resource.data);

         setStatement(v.resource.data.next);

         setToExpireGraphProps({
            ...expiredGraphProps,
            data: {
               labels: [
                  'A vencer até 30 dias',
                  'A vencer em de 31 a 60 dias',
                  'A vencer em de 61 a 90 dias',
                  'A vencer em de 91 a 120 dias',
                  'A vencer em de 121 a 180 dias',
                  'Acima de 180 dias',
               ],
               prices: [
                  (v.resource.data as any).to_win['0_30'].sum,
                  (v.resource.data as any).to_win['30_60'].sum,
                  (v.resource.data as any).to_win['60_90'].sum,
                  (v.resource.data as any).to_win['90_120'].sum,
                  (v.resource.data as any).to_win['120_180'].sum,
                  (v.resource.data as any).to_win['180'].sum,
               ],
               datasets: [
                  {
                     label: '# of Votes',
                     data: [
                        (v.resource.data as any).to_win['0_30'].count,
                        (v.resource.data as any).to_win['30_60'].count,
                        (v.resource.data as any).to_win['60_90'].count,
                        (v.resource.data as any).to_win['90_120'].count,
                        (v.resource.data as any).to_win['120_180'].count,
                        (v.resource.data as any).to_win['180'].count,
                     ],
                     backgroundColor: ['#35a8e0', '#3097ca', '#2a86b3', '#25769d', '#206586', '#1b5470'],
                  },
               ],
            },
         });
         const to_win: any[] = [];
         to_win.push({
            ...v.resource.data.to_win['0_30'],
            name: 'A vencer até 30 dias',
            color: '#35a8e0',
            urlData: {
               initialDate: dateFNS.format(new Date(), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.addDays(new Date(), 30), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(new Date(), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 30), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         to_win.push({
            ...v.resource.data.to_win['30_60'],
            name: 'A vencer em de 31 a 60 dias',
            color: '#3097ca',
            urlData: {
               initialDate: dateFNS.format(dateFNS.addDays(new Date(), 31), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.addDays(new Date(), 60), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 31), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 60), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         to_win.push({
            ...v.resource.data.to_win['60_90'],
            name: 'A vencer em de 61 a 90 dias',
            color: '#2a86b3',
            urlData: {
               initialDate: dateFNS.format(dateFNS.addDays(new Date(), 61), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.addDays(new Date(), 90), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 61), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 90), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         to_win.push({
            ...v.resource.data.to_win['90_120'],
            name: 'A vencer em de 91 a 120 dias',
            color: '#25769d',
            urlData: {
               initialDate: dateFNS.format(dateFNS.addDays(new Date(), 91), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.addDays(new Date(), 120), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 91), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 120), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         to_win.push({
            ...v.resource.data.to_win['120_180'],
            name: 'A vencer em de 121 a 180 dias',
            color: '#206586',
            urlData: {
               initialDate: dateFNS.format(dateFNS.addDays(new Date(), 121), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.addDays(new Date(), 180), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 121), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.addDays(new Date(), 180), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         to_win.push({
            ...v.resource.data.to_win['180'],
            name: 'Acima de 180 dias',
            color: '#1b5470',
            urlData: {
               initialDate: dateFNS.format(dateFNS.addDays(new Date(), 180), 'DD/MM/YYYY'),
               status: 'PENDING',
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.addDays(new Date(), 180), 'YYYYMMDD'),
               status_group: 'next',
            },
         });
         setToWinBar(to_win);

         setExpiredGraphProps({
            ...expiredGraphProps,
            data: {
               labels: [
                  'Vencidos a 30 dias',
                  'Vencidos de 31 a 60 dias',
                  'Vencidos de 61 a 90 dias',
                  'Vencidos de 91 a 120 dias',
                  'Vencidos de 121 a 180 dias',
                  'Vencidos a mais de 180 dias',
               ],
               prices: [
                  (v.resource.data as any).losers['0_30'].sum,
                  (v.resource.data as any).losers['30_60'].sum,
                  (v.resource.data as any).losers['60_90'].sum,
                  (v.resource.data as any).losers['90_120'].sum,
                  (v.resource.data as any).losers['120_180'].sum,
                  (v.resource.data as any).losers['180'].sum,
               ],
               datasets: [
                  {
                     data: [
                        (v.resource.data as any).losers['0_30'].count,
                        (v.resource.data as any).losers['30_60'].count,
                        (v.resource.data as any).losers['60_90'].count,
                        (v.resource.data as any).losers['90_120'].count,
                        (v.resource.data as any).losers['120_180'].count,
                        (v.resource.data as any).losers['180'].count,
                     ],
                     backgroundColor: ['#f32d32', '#db292d', '#c22428', '#aa1f23', '#921b1e', '#7a1719'],
                  },
               ],
            },
         });

         const losers = [];
         losers.push({
            ...v.resource.data.losers['0_30'],
            name: 'Vencidos a 30 dias',
            color: '#f32d32',
            urlData: {
               initialDate: dateFNS.format(dateFNS.subDays(new Date(), 30), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(new Date(), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 30), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(new Date(), 'YYYYMMDD'),
               status_group: 'expired',
            },
         });
         losers.push({
            ...v.resource.data.losers['30_60'],
            name: 'Vencidos de 31 a 60 dias',
            color: '#db292d',
            urlData: {
               initialDate: dateFNS.format(dateFNS.subDays(new Date(), 60), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.subDays(new Date(), 30), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 60), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 30), 'YYYYMMDD'),
               status_group: 'expired',
            },
         });
         losers.push({
            ...v.resource.data.losers['60_90'],
            name: 'Vencidos de 61 a 90 dias',
            color: '#c22428',
            urlData: {
               initialDate: dateFNS.format(dateFNS.subDays(new Date(), 90), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.subDays(new Date(), 60), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 90), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 60), 'YYYYMMDD'),
               status_group: 'expired',
            },
         });
         losers.push({
            ...v.resource.data.losers['90_120'],
            name: 'Vencidos de 91 a 120 dias',
            color: '#aa1f23',
            urlData: {
               initialDate: dateFNS.format(dateFNS.subDays(new Date(), 120), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.subDays(new Date(), 91), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 120), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 91), 'YYYYMMDD'),
               status_group: 'expired',
            },
         });
         losers.push({
            ...v.resource.data.losers['120_180'],
            name: 'Vencidos de 121 a 180 dias',
            color: '#921b1e',
            urlData: {
               initialDate: dateFNS.format(dateFNS.subDays(new Date(), 180), 'DD/MM/YYYY'),
               finalDate: dateFNS.format(dateFNS.subDays(new Date(), 121), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_gte: dateFNS.format(dateFNS.subDays(new Date(), 180), 'YYYYMMDD'),
               expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 121), 'YYYYMMDD'),
               status_group: 'expired',
            },
         });
         losers.push({
            ...v.resource.data.losers['180'],
            name: 'Vencidos a mais de 180 dias',
            color: '#7a1719',
            urlData: {
               finalDate: dateFNS.format(dateFNS.subDays(new Date(), 181), 'DD/MM/YYYY'),
            },
            url: {
               expiration_date_lte: dateFNS.format(dateFNS.subDays(new Date(), 181), 'YYYY-MM-DD'),
               status_group: 'expired',
            },
         });
         setLosersBar(losers);
      });
   });

   return {
      starting,
      resource,
      losersBar,
      toWinBar,
      expiredGraphProps,
      toExpireGraphProps,
      statement,
   };
}
