import styled from 'styled-components';

const css = styled.div;

export default css<any>`
   background: ${(props) => props.theme.primary};

   .user-box {
      position: relative;
   }

   .username-info {
      padding: 10px;
      &:hover {
         background: #ffffff22;
         cursor: pointer;
      }
   }

   .logout {
      border-top: 1px solid #eee;
      margin-top: 10px;
      padding-top: 10px;

      a {
         color: #222;
      }
   }

   .dropdown-user {
      position: absolute;
      min-width: 220px;
      max-width: 290px;
      padding: 20px 10px;
      bottom: -10px;
      right: 0px;
      transform: translateY(100%);
      z-index: 1;
      background: white;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 10px;
      color: black;
      text-align: center;

      .email {
         font-size: 12px;
         color: #666;
      }
   }

   .seller-info-box {
      display: flex;
      padding: 18px 20px;
      background: #00000033;
      color: white;

      .seller-info {
         flex: 1;

         .descrip {
            font-size: 12px;
            margin-bottom: -5px;
         }

         .cnpj {
            font-size: 13px;
         }

         .name {
            font-size: 19px;
            color: ${(props) => props.theme.secondary};
         }
      }

      .balance-box {
         display: flex;
         white-space: nowrap;
         background: #ffffff22;
         border-radius: 5px;
         padding: 10px;

         .price {
            font-size: 16px;
         }

         .text-success {
            color: #0dff65 !important;
            font-family: 'Poppins', sans-serif;
         }

         .text-orange {
            color: rgb(255, 160, 17);
            font-family: 'Poppins', sans-serif;
         }

         .title {
            opacity: 0.9;
            font-size: 13px;
            margin-bottom: -4px;
         }

         .col:last-child {
            border-left: 1px solid #00000011;
         }
      }
   }

   .menu {
      padding: 5px 10px;
      display: flex;
      background: ${(props) => props.theme.primary};

      .menu-item {
         padding: 10px;
         position: relative;
      }

      .menu-links {
         background: white;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 7px;
         position: absolute;
         transform: translateY(100%);
         bottom: 3px;
         width: 250px;
         z-index: 220;

         .sub-links {
            display: block;
            padding: 8px 10px;
            font-size: 13px;
            color: #222;
            border-left: 2px solid transparent;

            &:hover {
               color: ${(props) => props.theme.secondary};
               border-left: 2px solid ${(props) => props.theme.secondary};
               font-weight: bold;
               text-decoration: none;
            }
         }
      }

      .menu-title {
         text-transform: uppercase;
         font-size: 13px;
         color: white;
         font-weight: bold;

         &:hover {
            cursor: pointer;
         }
      }
   }

   .username {
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
   .seller-setting {
      display: flex;
      padding: 10px 20px;
      background: ${(props) => props.theme.header.backgroundColor};
      color: white;
      align-items: center;

      .logo {
         padding-right: 20px;
         img {
            width: 100px;
         }
      }
   }

   .username-container-popup {
      .ds-item {
         color: white !important;
      }
   }

   .ds-header {
      background: ${(props) => props.theme.primary};
      border-radius: 0px 0px 30px 30px;
   }

   .ds-logo img {
      height: 32px !important;
   }

   .ds-item {
      color: #222 !important;

      &.active {
         background: #35a8e0 !important;
         color: white !important;
         font-weight: bold;
      }
   }

   .ds-header .ds-header-bottom .ds-menu .ds-menu-category .ds-menu-subcategory {
      z-index: 1000 !important;
   }
`;
