import React, { useEffect, useRef, useState } from 'react';
import DateFNS from 'date-fns';
import { convertStringToDate } from '@devesharp/react/web';
import Style from './PaymentStatus.style';

export function PaymentStatus({ transaction, status }: any): any {
   if (
      transaction &&
      transaction.payment_type == 'boleto' &&
      (!!transaction.payment_method?.status || transaction.cancel)
   ) {
      if (transaction.cancel) {
         return (
            <Style>
               <div className="badge badge-error">
                  Cancelado
                  {/*{transaction.cancel && <small>&nbsp;(Manual)</small>}*/}
               </div>
            </Style>
         );
      }

      switch (transaction.payment_method.status) {
         case 'paid':
         case 'over_paid':
         case 'under_paid':
            return (
               <Style>
                  <div className="badge badge-succeeded">Pago</div>
               </Style>
            );
      }

      const diff = DateFNS.differenceInDays(
         DateFNS.format(convertStringToDate(transaction.expiration_date)),
         new Date(),
      );

      if (diff < 0 || transaction?.status == 'canceled') {
         return (
            <Style>
               <div className="badge badge-error">Vencido</div>
            </Style>
         );
      }

      switch (transaction.payment_method.status) {
         case 'not_paid':
            return (
               <Style>
                  <div className="badge pedding">A vencer</div>
               </Style>
            );
      }

      return (
         <Style>
            <div className="badge badge-succeeded">Pago</div>
         </Style>
      );
   }

   let text = '';
   const currentStatus = transaction?.status || status;

   switch (currentStatus) {
      case 'paid':
         text = 'Pago';
         break;
      case 'succeeded':
         text = 'Aprovada';
         break;
      case 'canceled':
         text = 'Cancelada';
         break;
      case 'blocked':
         text = 'Bloqueada';
         break;
      case 'charged_back':
         text = 'Charge back';
         break;
      case 'dispute':
         text = 'Disputa';
         break;
      case 'failed':
         text = 'Falhada';
         break;
      case 'pending':
         text = 'Pendente';
         break;
      case 'reversed':
         text = 'Pré-autorizado';
         break;
      case 'refunded':
         text = 'Revertida';
         break;
   }

   return (
      <Style>
         <div className={`badge badge-${currentStatus}`}>{text}</div>
      </Style>
   );
}
